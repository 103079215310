import React, { useEffect, useState } from "react";
import { useAdminStore } from '~/components/use-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { When } from 'react-if';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import moment from "moment";
import { axios } from '~/apicall';
import DateTimePicker from '~/components/date-time-picker/date-time-picker';

function Edit({ match }) {

    const adminStore = useAdminStore();

    
    let returnPath = "/admin/exam/" + match.params.id + "/detail";


    const [field, setField] = useState({});

    const [lectures, setLectures] = useState([]);
    const [mataKuliah, setMataKuliah] = useState(-1);
    const [tipeUjian, setTipeUjian] = useState(undefined);
    const [timeStart, setTimeStart] = useState(moment())
    const [timeDuration, setTimeDuration] = useState(undefined);

    const [lecturePeriods, setLecturePeriods] = useState([]);
    const [periodeUjian, setPeriodeUjian] = useState(-1);

    const [isSaving, setIsSaving] = useState(false);
    const [promptDelete, setPromptDelete] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [dataToSave, setDataToSave] = useState({})

    useEffect(() => {
        adminStore.selectedExam = match.params.id;
        adminStore.examFetch(match.params.id).then(() => {
            setField({ ...adminStore.exam });
            setMataKuliah(adminStore.exam.lecture._id);
            setPeriodeUjian(adminStore.exam.lecture_period._id);
            setTipeUjian(adminStore.exam.uts);
            setTimeStart(prev => moment(adminStore.exam.time_start, "YYYY-MM-DD HH:mm:ss"));
            setTimeDuration(adminStore.exam.time_duration);
            // console.log("Exam duration :", adminStore.exam);
        });
        return () => {
        };
    }, [adminStore, match])


    //Get lectures info
    useEffect(() => {
        axios.get("manage/lecture").then(resp => {
            setLectures(resp.data.data);
        })
        return () => { };
    }, [])

    //Get lecture period info
    useEffect(() => {
        axios.get("manage/lectureperiod").then(resp => {
            setLecturePeriods(resp.data.data);
        })
        return () => { };
    }, [])

    function handleOnChange(e) {
        let target = e.target;
        let id = target.id
        let fieldName = id.split("form-input-")[1];

        setDataToSave((prev) => {
            prev[fieldName] = target.value;
            return prev
        })

        if (fieldName === "lecture_period") {
            setPeriodeUjian((prev) => parseInt(target.value))
        }
        if (fieldName === "lecture") {
            setMataKuliah((prev) => parseInt(target.value))
        }
        if (fieldName === "uts") {
            setTipeUjian((prev) => parseInt(target.value))
        }
        if (fieldName === "time_duration") {
            setTimeDuration((prev) => (parseInt(target.value)*60))
        }

        // console.log("Data to Save", dataToSave);

    }

    function handleSaveExam(e) {
        e.preventDefault();
        let endpoint = "manage/exam/" + match.params.id;

        let newData = {};
        if (field.time_start !== timeStart.format("YYYY-MM-DD HH:mm:ss")) {
            newData.time_start = timeStart.format("YYYY-MM-DD HH:mm:ss");
            newData.time_opened = null;
            newData.time_ended = null;
        }else{
            newData.time_start = field.time_start;
        }

        newData.lecture = mataKuliah;
        newData.lecture_period = periodeUjian;
        newData.time_duration = parseInt(timeDuration);
        newData.uts = tipeUjian;
        newData.online = adminStore.exam.online;

        console.log(newData);

        setIsSaving(true)

        axios.put(endpoint, newData).then((resp) => {
            if (resp.status === 200) {
                console.log("Exam updated!");
                setIsSaving(false)
                window.location = returnPath;
                return Promise.resolve(resp);
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function handleDeleteRequest(e) {
        let endpoint = "manage/exam/" + match.params.id;
        let indexPath = "/admin/exam/" + match.params.id + "/detail";
        setIsDeleting(true)
        axios.delete(endpoint).then((resp) => {
            if (resp.status === 200) {
                console.log("Exam deleted!");
                setIsDeleting(false)
                window.location = indexPath;
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <React.Fragment>
            <div className="my-5">
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <h2 className="text-monospace">
                                <Link to={returnPath} title="Back" className="mr-3"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                <span className="text-muted">Manage\</span>ExamOnline::<span className="text-info">edit</span>({match.params.id})
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <Form>
                                <FormGroup key={"form-id"}>
                                    <Label>id</Label>
                                    <Input
                                        id={"form-input-id"}
                                        defaultValue={field ? field._id : ""}
                                        disabled={true} />
                                </FormGroup>
                                <FormGroup key={"form-lecture"}>
                                    <Label>Lecture</Label>
                                    <Input type="select" id={"form-input-lecture"} name="mata_kuliah" disabled={!lectures} value={mataKuliah} onChange={e => setMataKuliah(e.target.value)}>
                                        <option value={-1} disabled>-- {lectures.length > 0 ? "Pilih Mata Kuliah" : "Memuat..."} --</option>
                                        {lectures.map(data => <option value={data._id} key={data._id}>{data.lecture_code} - {data.name}</option>)}
                                    </Input>
                                </FormGroup>
                                <FormGroup key={"form-lecture_period"}>
                                    <Label>Lecture Period</Label>
                                    <Input
                                        id={"form-input-lecture_period"}
                                        type="select" name="lecture_period" disabled={!lecturePeriods.length} value={periodeUjian}
                                        onChange={handleOnChange}
                                    >
                                        <option value={-1} disabled>-- {lectures.length > 0 ? "Pilih Periode Ujian" : "Memuat..."} --</option>
                                        {
                                            lecturePeriods.map((data) => {
                                                return <option value={data._id} key={data._id}> {data.period_code}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>

                                <FormGroup key={"form-time_start"}>
                                    <Label>Exam Start</Label>
                                    <DateTimePicker time={timeStart} onChange={setTimeStart} />
                                </FormGroup>
                                <FormGroup key={"form-time_duration"}>
                                    <Label>Duration (Minutes)</Label>
                                    <Input
                                        id={"form-input-time_duration"}
                                        defaultValue={timeDuration ? (Math.floor(timeDuration / 60)) : ""}
                                        onChange={handleOnChange}
                                    />
                                </FormGroup>
                                {/* <FormGroup key={"form-time_opened"}>
                                    <Label>Timer Open</Label>
                                    <Input
                                        id={"form-input-time_opened"}
                                        defaultValue={field ? field.time_opened : ""}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup key={"form-time_ended"}>
                                    <Label>Timer Ended</Label>
                                    <Input
                                        id={"form-input-time_ended"}
                                        defaultValue={field ? field.time_ended : ""}
                                        disabled={true}
                                    />
                                </FormGroup> */}
                                <FormGroup key={"form-online"}>
                                    <Label>Online</Label>
                                    <Input
                                        id={"form-input-online"}
                                        defaultValue={field ? (field.online ? "True" : "False") : ""}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup key={"form-uts"}>
                                    <Label>UTS/UAS/QUIZ</Label>
                                    <Input
                                        id={"form-input-uts"}
                                        defaultValue={field ? field.uts : ""}
                                        onChange={handleOnChange}
                                        type="select" name="tipe_ujian" disabled={field.uts === undefined} value={tipeUjian}
                                    >
                                        <option value={-1} disabled>-- Pilih Tipe Ujian --</option>
                                        {
                                            [0, 1, 2].map((val) => {
                                                return <option key={"tipe-ujian-" + val} value={val} >{val === 0 ? "UAS" : (val === 1 ? "UTS" : "QUIZ")}</option>
                                            })
                                        }
                                    </Input>

                                </FormGroup>


                                <FormGroup className="pt-4">
                                    <Button type="submit" color="primary" size="lg" onClick={(e) => handleSaveExam(e)}>Save {isSaving ? <Spinner as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""

                                    }</Button>
                                    <When condition={match.params.id !== 0}>
                                        <Button type="button" color="danger" onClick={(e) => setPromptDelete(true)} size="lg" className="ml-4">Delete </Button>
                                    </When>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Modal isOpen={promptDelete && field && field._id !== undefined}>
                        <ModalHeader>
                            Delete Exam {lecturePeriods[field.lecture?.name]}
                        </ModalHeader>
                        <ModalBody>
                            Are you sure you are going to delete Exam <code>{field.lecture?.name}</code> (<code>{field.lecture?.lecture_code}</code>)?
                            {/* <Table>

                                <tbody>
                                    <tr>
                                        <th>time_start</th>
                                        <td>{field.time_start}</td>
                                    </tr>

                                    <tr>
                                        <th>time_duration</th>
                                        <td>{field.time_duration}</td>
                                    </tr>
                                </tbody>
                            </Table> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button type="secondary" onClick={(e) => setPromptDelete(false)}>Cancel</Button>
                            <Button onClick={(e) => handleDeleteRequest(e)} color="danger" >Delete {isDeleting ? <Spinner as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : ""}</Button>
                        </ModalFooter>
                    </Modal>

                </Container>

            </div>
        </React.Fragment>
    );
}

export default Edit;