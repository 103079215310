import { faFileDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { Button } from "reactstrap";
const ResourceItem = ({ resource = {}, onDeleteRequest = () => { }, onDowloadRequest = () => { } }) => {
    return (
        <React.Fragment>
            <tr >
                <td><p className="font-monospace font-weight-bold">{resource.upload_name}</p></td>
                <td style={{ textAlign: "right" }}>
                    <Button id={"resource-" + resource._id}  className="ml-2" color='success' onClick={(e) => onDowloadRequest(e, resource)}> <FontAwesomeIcon icon={faFileDownload} />  </Button>
                    <Button id={"delete-resource-" + resource._id}  className="ml-2" color='danger' onClick={(e) => onDeleteRequest(resource)}> <FontAwesomeIcon icon={faTrash} />  </Button>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default ResourceItem