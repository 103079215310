import React from 'react';
import Alert from "reactstrap/lib/Alert";
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import FormText from "reactstrap/lib/FormText";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";
import { Tooltip, Spinner } from 'reactstrap';

import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Buildinfo from '~/components/buildinfo/Buildinfo';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Login extends React.Component {

  state = {
    username: "",
    password: "",
    isShowPassword: false,
    isHoverShowPassword: false,
    isLoggingIn: false,
    alert: null
  }

  submitHelper(e) {
    e.preventDefault();
    const { adminStore, history } = this.props;
    const { username, password } = this.state;
    this.setState((prev) => {
      return { ...prev, isLoggingIn: true };
    })
    adminStore.tryLogin(username, password).then(e => {
      this.setState((prev) => {
        return { ...prev, isLoggingIn: false };
      });
      history.push("/admin/");

    }).catch(e => {
      this.setState((prev) => {
        return { ...prev, isLoggingIn: false };
      });
      if (e.response) {
        this.setState({ alert: "Error: " + e.response.data.error.title + ": " + e.response.data.error.description });
      } else {
        this.setState({ alert: "Something happended, umm... idk what. Please check your connection and console.logs." });
      }
    })
  }

  handleIPLogin(e) {
    e.preventDefault();
    const { adminStore, history } = this.props;
    adminStore.tryIPLogin().then(e => {
      history.push("/admin/screen");
    }).catch(e => {
      if (e.response) {
        this.setState({ alert: "Error: " + e.response.data.error.title + ": " + e.response.data.error.description });
      } else {
        this.setState({ alert: "Something happended, umm... idk what. Please check your connection and console.logs." });
      }
    })
  }


  handleShowPassword(e) {
    e.preventDefault();
    let input = document.getElementById("password");
    if (e.type === "mousedown") {
      input.setAttribute("type", "text");
      this.setState(prev => { return { ...prev, isShowPassword: true } });
    } else if (e.type === "mouseup") {
      input.setAttribute("type", "password");
      this.setState(prev => { return { ...prev, isShowPassword: false } });
    }
  }


  toggleTooltipShowPassword(e) {
    const { isHoverShowPassword } = this.state;
    this.setState((prev) => {
      return { ...prev, isHoverShowPassword: !isHoverShowPassword };
    })
  }


  render() {
    const { alert, isLoggingIn } = this.state;

    return (
      <>
        <Container>
          <Row className="h-100vh align-items-center justify-content-center">
            <Col xs={12} md={8} lg={6}>
              <Card>
                <CardBody>
                  <h3>Oxam Admin Login</h3>
                  <Alert color="danger" isOpen={alert !== null} toggle={e => this.setState({ alert: null })}>
                    {alert}
                  </Alert>
                  <Form onSubmit={(e) => this.submitHelper(e)}>
                    <FormGroup>
                      <Label>Admin username</Label>
                      <Input type="text" placeholder="admin, maybe." onChange={e => this.setState({ username: e.target.value })} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Row>
                        <Col>
                          <Input id="password" type="password" placeholder="Our secret words, it's only between us." onChange={e => this.setState({ password: e.target.value })} />
                        </Col>
                        <Button id="btn-show-password" className="mr-3" color="none" onClick={(e) => e.preventDefault()} onMouseDown={this.handleShowPassword.bind(this)} onMouseUp={this.handleShowPassword.bind(this)}><FontAwesomeIcon icon={this.state.isShowPassword ? faEyeSlash : faEye} /></Button>
                        <Tooltip
                          target={"btn-show-password"}
                          placement="right-start"
                          isOpen={this.state.isHoverShowPassword}
                          toggle={e => this.toggleTooltipShowPassword(e).bind(this)}
                        >
                          Show Password
                        </Tooltip>
                      </Row>
                      <FormText color="muted">
                        Have some difficulties, try our <Link to="/admin/account/recover">Forgot Password</Link> tool here.
                      </FormText>
                    </FormGroup>
                    <FormGroup className="text-right">
                      <Button color="secondary" type="button" onClick={this.handleIPLogin.bind(this)}>Or Login with IP</Button> &nbsp;
                      <Button color="primary">Login {isLoggingIn ?
                        <Spinner as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true" /> : ""}</Button>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
              <p className="text-muted text-center">
                <small><Buildinfo /></small>
              </p>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default inject("adminStore")(
  withRouter(
    observer(Login))
);