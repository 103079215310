import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { checkPermission } from "~/components/globals/utils/CheckPermission";
import LoadingScreen from "~/components/loadingscreen/LoadingScreen";
import IndexExamOnline from "~/pages/exam-online/student/index/Index.js";

const Student = (props) => {
    const { match, examOnlineStore } = props;
    const { history } = props;

    //Fetch profile info and check permission
    useEffect(() => {
        const getProfileData = async () => {
            examOnlineStore.toggleLoadingScreen(true);
            const redirectURI = "/exam-online/account/login";
            examOnlineStore.fetchProfile().then((resp) => {

                if (resp.status === 200) {
                    let user = examOnlineStore.getUser;
                    checkPermission(user.acl, ["student"], history, redirectURI)
                    examOnlineStore.toggleLoadingScreen();
                } else {
                    examOnlineStore.toggleLoadingScreen();
                    checkPermission("", ["student"], history, redirectURI)
                }
            }).catch((err) => {
                console.log(err)
                examOnlineStore.toggleLoadingScreen();
                checkPermission("", ["student"], history, redirectURI)
            })
        }
        getProfileData();
    }, [examOnlineStore, history])

    if (examOnlineStore.checkIsFetchingData) {
        return (<React.Fragment><LoadingScreen /></React.Fragment>);
    }


    return (
        <>
           
            <Switch>
                <Route exact path={match.path} component={IndexExamOnline} />
            </Switch></>
    )
}

export default inject("examOnlineStore")
    (withRouter(observer(Student)));