import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { Else, If, Then } from "react-if";
import { Badge, Button, Col, Spinner, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import StageComponent from '~/components/location/StageComponent';
import { useLocationStore } from "~/components/use-store";
import { axios } from "~/apicall";

function Step2({ examDetails, onRequestNext, handleError }) {
    const locationStore = useLocationStore();
    const [locationDatas, setLocationDatas] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState(0);
    const [selectedComputerCounts, setSelectedComputerCounts] = useState(0);
    const [isPinging, setIsPinging] = useState(false)
    const stageRefs = useRef([]);
    const layersRef = useRef([]);

    //Fetch locations on mount
    useEffect(() => {
        console.log("Fetching data")
        locationStore.locationFetch().then((resp) => {

            //Handle selected computers data from exam details
            let locations = [...locationStore.locations];
            if (examDetails.computers?.length) {
                locations = locations.map((loc) => {
                    let computers = loc.computers;
                    computers = computers.map((comp) => {
                        if (examDetails.computers.includes(comp._id)) {
                            comp.isSelected = true;
                        }
                        return comp;
                    })

                    loc.computers = computers;
                    return loc
                })
                setSelectedComputerCounts(examDetails.computers.length);
            }

            setLocationDatas([...locations]);

        });

        return () => { };
    }, []);

    useEffect(() => {
        //Updating stageRef
        stageRefs.current = stageRefs.current.slice(0, locationDatas.length);
        //Updating layersRef
        layersRef.current = layersRef.current.slice(0, locationDatas.length);

        // if (stageRefs.current && stageRefs.current[selectedLocations] && layersRef.current && layersRef.current[selectedLocations]) {
        //     stageRefs.current[selectedLocations].clear()
        //     layersRef.current[selectedLocations].clear()
        // }
    }, [locationDatas, selectedLocations])


    //On next button pressed
    function handleRequestNext() {
        if (selectedComputerCounts < (examDetails.peserta || []).length) {
            return;
        }

        // get all those selected computers across the rooms.
        let selectedComputers = [];
        let selectedRoom = []
        let selectedRoomData = []
        locationDatas.map((loc) => loc.computers.map(comp => {
            if (comp.isSelected) {
                selectedComputers.push(comp._id);
                if (!selectedRoom.includes(loc._id)) {
                    selectedRoom.push(loc._id);
                    let roomData = { ...loc };
                    delete roomData.computers;
                    selectedRoomData[loc._id] = roomData;
                }
            }
            return null;
        }));


        onRequestNext(selectedComputers, selectedRoomData);
    }

    function handleAutoSelect(e) {
        //Get current location
        e.preventDefault();
        let currLoc = { ...locationDatas[selectedLocations] };

        let currSelectedCompCount = 0;
        //Get computers in current location, and set it to not selected
        let computers = currLoc.computers.map((comp) => {
            //Count currenlty selected computer count to be substracted from selectedComputerCounts
            if (comp.isSelected) currSelectedCompCount += 1;
            comp.isSelected = false;
            return comp;
        });
        //Filter computer by state
        computers = computers.filter((a) => a.state === 1)
        //Sort computer by priority
        let sortedComputers = computers.sort((a, b) => a.priority - b.priority)
        //Get total participants
        let totalParticipant = examDetails.peserta.length;
        //Array for selected computers
        let selectedComputers = [];
        //Counter for adding selected computer in current room 
        currSelectedCompCount = selectedComputerCounts - currSelectedCompCount;
        //Select computer while count of selected computer lower than number of participant
        sortedComputers.map((computer) => {
            if (currSelectedCompCount < totalParticipant) {
                selectedComputers.push(computer._id)
                currSelectedCompCount += 1;
            }
            return computer;
        })

        let newComputersData = locationDatas[selectedLocations].computers.map((comp) => {
            if (selectedComputers.includes(comp._id)) {
                comp.isSelected = true;
            } else {
                comp.isSelected = false;
            }
            return comp;
        })

        currLoc.computers = newComputersData;

        let newLocationData = [...locationDatas];
        newLocationData[selectedLocations] = currLoc;

        //Reset counter for counting selected computers in all room
        currSelectedCompCount = 0;
        //Calc selected computer on autoselect
        newLocationData.map((loc) => {
            loc.computers.map((comp) => {
                if (comp.isSelected) currSelectedCompCount += 1;
                return comp;
            })
            return loc;
        })

        // After autoselect warn selected count position
        handleError("warning", true, "Posisi Terpilih", <span>Terdapat <strong>{currSelectedCompCount}</strong> posisi terpilih untuk <strong>{totalParticipant}</strong> orang peserta ujian.</span>)
        // }

        setLocationDatas(prev => {
            prev[selectedLocations] = currLoc;
            return prev;
        });
        setSelectedComputerCounts(prev => currSelectedCompCount);

        stageRefs.current[selectedLocations].clear();
        layersRef.current[selectedLocations].clear();
    }

    function onClickComputer(e, computerDetail) {
        //Computer is currently not selected 
        //and computer selected is less than the number of participant
        if (selectedComputerCounts >= 0 && selectedComputerCounts < examDetails.peserta.length && (computerDetail.isSelected === false || computerDetail.isSelected === undefined)) {
            handleSetSelect(computerDetail, true)
        } else if (selectedComputerCounts > 0 && selectedComputerCounts <= examDetails.peserta.length && computerDetail.isSelected === true) {
            handleSetSelect(computerDetail, false);
        }
    }

    function handleSetSelect(computerDetail, isSelected) {
        let hasChange = false
        let newLocData = locationDatas.map((loc) => {
            let newSelectedComputer = loc.computers.map((comp) => {
                if (comp._id === computerDetail._id && comp.state === 1) {
                    // if (comp.isPingable !== undefined && comp.isPingable === true) {
                    //     comp.isSelected = isSelected;
                    //     hasChange = true;
                    // } else if (comp.isPingable === undefined) {
                    //     comp.isSelected = isSelected;
                    //     hasChange = true;
                    // }

                    comp.isSelected = isSelected;
                    hasChange = true;
                }
                return comp;
            })

            loc.computers = newSelectedComputer;
            return loc;
        })
        // console.log(newLocData)
        setLocationDatas(newLocData);

        if (hasChange) {
            if (isSelected === true) {
                setSelectedComputerCounts((prev) => prev + 1);
            } else if (isSelected === false) {
                setSelectedComputerCounts((prev) => prev - 1);
            }
        }
    }

    function handleClearAllSelection(e) {
        let countSelectedComp = 0
        let currLocComp = locationDatas[selectedLocations].computers.map((comp) => {
            if (comp.isSelected) {
                countSelectedComp += 1;
            }

            comp.isSelected = false;
            return comp;
        })

        let newLocationData = [...locationDatas];
        newLocationData[selectedLocations].computers = currLocComp;
        let prevSelectionCount = selectedComputerCounts;
        let newSelectionCount = prevSelectionCount - countSelectedComp;
        setLocationDatas(newLocationData);
        setSelectedComputerCounts((prev) => newSelectionCount);

        if (stageRefs.current && stageRefs.current[selectedLocations]) {
            stageRefs.current[selectedLocations].clear();
        }

        //Show selection count warning
        return handleError("warning", true, "Posisi Terpilih", <span>Terdapat <strong>{newSelectionCount}</strong> posisi terpilih untuk <strong>{examDetails?.peserta?.length}</strong> orang peserta ujian.</span>)
    }


    const handlePingSingle = async (computer) => {
        await axios.get("/manage/location/" + locationDatas[selectedLocations]._id + "/ping",
            {
                params: {
                    "ip": computer.ip
                }
            }
        )
            .then((resp) => {
                let { data } = resp.data;
                let computersData = locationDatas[selectedLocations].computers.map((element, index) => {
                    if (element.ip.localeCompare(data.ip) === 0) {
                        element.isPingable = (data.loss_rate === 0 ? 1 : 0);
                    }
                    return element;
                })

                let newLocationData = [...locationDatas];
                newLocationData[selectedLocations].computers = computersData;

                setLocationDatas(prev => [...newLocationData])

                return Promise.resolve(resp);
            }).catch((err) => {
                return Promise.reject(err)
            });
    }

    const handlePing = (e) => {
        setIsPinging(true);
        let unresolvedPromise = locationDatas[selectedLocations].computers.map((element, index) => {
            return handlePingSingle(element);
        })

        Promise.all(unresolvedPromise).then((resp) => {
            setIsPinging(false);
        });


    }




    return (<>
        <Container className="my-5 h-75vh ">
            <Row className=" mr-5  w-100 position-absolute justify-content-end">
                <div className="mr-5">
                    <Button
                        className="mr-2"
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={locationDatas.length === 0}
                        onClick={handlePing}
                    >
                        Ping{isPinging ? <Spinner as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /> : ""}
                    </Button>
                    <Button
                        className="mr-2"
                        color="primary"
                        size="lg"
                        type="submit"
                        onClick={(e) => handleAutoSelect(e)}
                        disabled={locationDatas.length === 0}
                    >
                        Autoselect
                    </Button>
                    <Button color="primary"
                        size="lg"
                        type="submit"
                        onClick={(e) => handleClearAllSelection(e)}
                        disabled={locationDatas.length === 0}
                    >
                        Clear Selections
                    </Button>
                </div>

            </Row>
            <Row className="mt-5 w-50 mr-0 position-relative p-0">
                <Nav tabs className="w-75">
                    {locationDatas.map((loc, i) => <NavItem key={loc._id}>
                        <NavLink
                            className={classNames({ active: selectedLocations === i })}
                            onClick={() => { setSelectedLocations(i); }}>
                            {loc.room_name} <Badge color={(loc.computers || []).filter(e => !!e.selected).length > 0 ? "primary" : "secondary"}>{loc.name_alias}</Badge>
                        </NavLink>
                    </NavItem>)}
                </Nav>
            </Row>
            <Row className="my-2 h-75vh position-relative mb-5">
                <TabContent activeTab={selectedLocations} className="">
                    <If condition={locationDatas.length > 0}>
                        <Then>
                            {locationDatas.map((loc, i) => {
                                return <TabPane tabId={i} key={i} className="">
                                    <Container xs={4} sm={6} lg={9} className="my-4 h-100 d-inline-block" >
                                        <StageComponent
                                            key={"stage-" + i}
                                            stageId={"stage-" + i}
                                            allowEdit={false}
                                            stageRef={ref => (stageRefs.current[i] = ref)}
                                            layerRef={ref => (layersRef.current[i] = ref)}
                                            field={loc}
                                            handleClickOnComputer={onClickComputer}
                                        />
                                    </Container>
                                </TabPane>
                            })}
                        </Then>
                        <Else>
                            <></>
                        </Else>
                    </If>

                </TabContent>
            </Row>
            <Row className="my-5 position-absolute w-100 mb-5">
                <Col>
                    <p>
                        Terdapat {(examDetails.peserta || []).length} peserta dan terpilih <b>{selectedComputerCounts} komputer</b> ({Math.round(selectedComputerCounts / (examDetails.peserta || []).length * 100)}%).
                    </p>
                </Col>
                <Col>
                    <div className="text-right ">
                        <Button color="primary"
                            size="lg"
                            type="submit"
                            onClick={handleRequestNext}
                            disabled={selectedComputerCounts < (examDetails.peserta || []).length}>
                            Next <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>
                </Col>
            </Row>

        </Container>

    </>
    )
}

export default observer(Step2);
