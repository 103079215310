import { inject, observer } from 'mobx-react';
import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Login from './account/login/ExamOnlineLogin';
import ExamOnline from './exam/ExamOnline';
import LecturerIndex from './lecturer/Index';
import Student from './student/Student';

function IndexExamOnline({ match }) {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={match.path} component={ExamOnline} />
        <Route path={match.path + "/account/login"} component={Login} />
        <Route path={match.path + "/lecturer"} component={LecturerIndex} />
        <Route path={match.path + "/student"} component={Student} />
        <Redirect to="/not-found" />
      </Switch>
    </React.Fragment>
  )
}

export default withRouter(inject("examOnlineStore")(observer(IndexExamOnline)));