import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { If, Then } from "react-if";
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
class LocationCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            field: [],
            message: [],
            isAdding: false,
            redirect: false,
            returnPath: '/admin/location'
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ isAdding: true });
        const { locationStore } = this.props;
        let { field } = this.state;
        let url = "/manage/location";
        if (field) {
            locationStore.createLocation(url, { ...field }).then((resp) => {
                this.setState({ redirect: true, isAdding: false });
                return Promise.resolve(resp);
            }).catch((err) => {
                alert(err)
            })
        }

    }

    handleOnChange(e, fieldname) {
        let target = e.target;
        let { field } = this.state;
        this.setState({
            field: { ...field, [fieldname]: target.value }
        });

    }

    render() {
        const { field, message, isAdding, returnPath, redirect } = this.state;
        const { locationStore } = this.props;
        console.log(locationStore.locations);

        return <>
            <If condition={redirect === true}>
                <Then>
                    <Redirect to={returnPath} />
                </Then>
            </If>
            <div className="my-5">
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <h2 className="text-monospace">
                                <Link to={returnPath} title="Back" className="mr-3"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                <span className="text-muted">Manage\</span>Location::<span className="text-info">new</span>()
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            {message.map((msg, id) => <Alert key={"msg-" + id} color={msg.color || "primary"} children={msg.data} />)}
                            <Form onSubmit={(e) => this.handleSubmit(e)}>

                                <FormGroup key={"form-id"}>
                                    <Label>id</Label>
                                    <Input
                                        disabled={true} />
                                </FormGroup>

                                <FormGroup key={"form-room_name"}>
                                    <Label>room_name</Label>
                                    <Input onChange={e => this.handleOnChange(e, "room_name")} />
                                </FormGroup>

                                <FormGroup key={"form-name_alias"}>
                                    <Label>name_alias</Label>
                                    <Input onChange={e => this.handleOnChange(e, "name_alias")} />
                                </FormGroup>

                                <FormGroup key={"form-subnet_start"}>
                                    <Label>subnet_start</Label>
                                    <Input onChange={e => this.handleOnChange(e, "subnet_start")} />
                                </FormGroup>

                                <FormGroup className="pt-4">
                                    <Button type="submit" color="primary" size="lg">Save {isAdding ? <Spinner as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" /> : ""}</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container></div>
        </>
    }
}

export default withRouter(inject("locationStore")(observer(LocationCreate)));