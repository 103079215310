import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { When } from 'react-if';
import { withRouter } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import { Col, Container, Row } from 'reactstrap';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

let steps = [
    { title: "Exam Details" },
    { title: "Confirmation" },
    { title: "Finish" },
];

const ExamOnlineCreate = (props) => {
    const [examDetails, setExamDetails] = useState({});
    const [currentStep, setCurrentStep] = useState(0);


    function handleStep1Confirmation(data) {
        if (data.peserta.length === 0) {
            return alert("Peserta kosong!");
        }
        setExamDetails({ ...examDetails, ...data });
        setCurrentStep(currentStep + 1);
    }
    function handleStep2Confirmation(data) {
        setExamDetails({ ...examDetails, ...{ exams: data } });
        setCurrentStep(currentStep + 1);
    }

    return (
        <React.Fragment>

            <div>
                <Container>
                    <Row>
                        <Col xs="12" style={{ cursor: "pointer" }}>
                            <h2 className="my-3">New Exam Online</h2>
                            <Stepper
                                steps={steps.map((step, i) => ({ ...step, onClick: (e) => setCurrentStep(i) }))}
                                activeStep={currentStep}
                                activeColor="var(--primary)"
                                completeBarColor="var(--info)"
                                completeColor="var(--info)"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <When condition={currentStep === 0}><Step1 onRequestNext={handleStep1Confirmation} examDetails={examDetails} props={props} /></When>
                            <When condition={currentStep === 1}><Step2 onRequestNext={handleStep2Confirmation} examDetails={examDetails} /></When>
                            <When condition={currentStep === 2}><Step3 examDetails={examDetails} /></When>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment >
    )
}

export default inject("examOnlineStore")(
    withRouter(observer(ExamOnlineCreate)));
