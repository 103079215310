import fileDownload from 'js-file-download';
import React from 'react';
import { When } from 'react-if';
import { Badge, Col, Container, Row, Table } from 'reactstrap';
import ResourceDownloader from '~/pages/exam-online/student/index/ResourceDownloader';


const Upcoming = (props) => {

    const { onlineExamStore } = props;
    const { exam, participant } = onlineExamStore;
    const { setErrorModal } = props;

    function handleResourceDownload(e, resource) {
        if (resource?.exam) {
            const resourceId = resource._id;
            const resourceToken = resource.token.token;
            const url = "/api/v1/examonline/resourceonline/" + resourceId + "/download";

            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.localStorage.getItem('auth-token')
                }, body: JSON.stringify({
                    token: resourceToken
                })
            }).then((resp) => {
                if (resp.status === 200) {
                    return resp.blob();
                } else {
                    return Promise.reject(resp);
                }
            }).then((resp) => {
                fileDownload(resp, resource.upload_name);
            }).catch((err) => {
                console.log(err);
                if (err.status === 400) {
                    setErrorModal(null,
                        (<>
                            <h5 className='bg-danger text-white text-center'>DOWNLOAD FAILED</h5>
                            <p>
                                { err.statusText + " - Invalid token specified for requested resource. Ask Administrator for more information."}
                            </p>
                        </>)
                       );
                } else if (err.status === 401) {
                    setErrorModal(null,
                        (<>
                            <h5 className='bg-danger text-white text-center'>DOWNLOAD FAILED</h5>
                            <p>
                                {err.statusText + " - Either token is no longer valid or not yet valid. Ask Administrator for more information."}
                            </p>
                        </>)
                    );
                } else {
                    setErrorModal(null,
                        (<>
                            <h5 className='bg-danger text-white text-center'>DOWNLOAD FAILED</h5>
                            <p>
                                {err.statusText }
                            </p>
                        </>)
                    );
                }

            })

        }
    }



    return (
        <React.Fragment> <Container className='w-75vw'>
            <Row className="align-items-baseline ">
              
                <Col xs={{ size: 4, order: 1 }} md={{ size: 10, order: 1 }} className="mx-4">
                    <h3>{parseInt(exam.uts) ? (parseInt(exam.uts) === 1 ? "UTS" : "QUIZ") : "UAS"}
                        <When condition={parseInt(exam.online) !== 0}>
                            {' '}<Badge color={parseInt(exam.uts) === 1 ? (parseInt(exam.uts) === 1 ? "success" : "primary") : "info"}>{parseInt(exam.shift) ? ("S-" + exam.shift) : (parseInt(exam.online) === 1 ? "Online" : "")}</Badge>
                        </When>
                    </h3>
                    <p className="lead">Hai <b className="text-info">{participant.username}</b>, berikut detil ujianmu sesi ini:</p>
                    <Table>
                        <tbody>
                            <tr>
                                <th>Mata Kuliah</th>
                                <td>
                                    <strong>{exam.lecture?.lecture_code}</strong>
                                    <span className="text-muted"> - {exam.lecture?.name}</span>
                                </td>
                            </tr>

                            <tr>
                                <th>Durasi</th>
                                <td>
                                    {exam.time_duration / 60} Menit
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className='my-4'>
                <Col className='mx-4'>
                    <h5>Berkas Ujian:</h5>

                    <ul className='w-100 list-group mx-12 d-flex justify-content-center'>
                        {exam.resource ?
                            exam.resource.map((ans, i) => <ResourceDownloader resource={ans} key={i} onDownloadRequest={handleResourceDownload} />)
                            :
                            <ResourceDownloader resource={undefined} />
                        }
                    </ul>

                </Col>
            </Row>

            <Row className='my-5'>
                <Col className='mx-4'>
                    <p>
                    <strong>Ujian dapat dimulai saat dosen pengawas telah menekan tombol mulai pada komputer dosen</strong>.
                        Kontak dosen pengawas jika terdapat masalah atau kesalahan informasi ujian.
                    </p>
                </Col>
            </Row>

        </Container></React.Fragment>
    )
}

export default Upcoming