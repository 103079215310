import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from "mobx";
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Container, Row } from 'reactstrap';
import computerIcon from "~/assets/computer-svg.svg";
import ComputerShape from './ComputerShapeComponent';
import InfoModal from './InfoModal';

import { Layer, Line, Rect, Stage, Transformer } from 'react-konva';
import { useLocationStore } from '../use-store';
import InfoTooltip from './InfoTooltip';

const toolbarStyle = createUseStyles({

    stageContainer: {
        display: "inline-block",
        position: "absolute",
        width: "100%",
        height: "100%",
        padding: "0px",
        // margin: [0, 0, 5, 0],
        right: "0px",
        border: "1px solid #000",
        borderRadius: "5px",
        overflow: "scroll"
    }
});


/*Reference: 
- https://codesandbox.io/s/konva-drag-and-drop-with-preview-forked-qg37y?file=/src/index.js:0-3097
- https://konvajs.org/api/Konva.Rect.html
- https://konvajs.org/docs/react/Images.html
*/
function getItem(item, allowEdit,
    handleClickOnComputer = (() => { }),
    onDragStartComputer = (() => { }),
    onDragEndComputer = (() => { }),
    onMouseOverComputer = (() => { }),
    onMouseLeaveComputer = (() => { }),
    onDropComputer = (() => { })) {
    return (
        <ComputerShape
            draggable={allowEdit}
            key={item.name}
            data={item}
            onClick={handleClickOnComputer}
            onDragStart={onDragStartComputer}
            onDragEnd={onDragEndComputer}
            onDrop={onDropComputer}
            onMouseOver={onMouseOverComputer}
            onMouseLeave={onMouseLeaveComputer}
            src={computerIcon}
        />
    );
}

function getPreview(data, handleClickOnComputer = (() => { })) {
    if (data === null) {
        return null;
    }

    return (
        <ComputerShape
            key={data._id}
            data={data}
            onClick={handleClickOnComputer}
            src={computerIcon}
        />
    );
}

function getModal(infoModalData, func, allowEdit) {
    // console.log(infoModalData);
    if (infoModalData) {
        return <InfoModal data={infoModalData} handleDismiss={func} allowEdit={allowEdit} />;
    }
    return null;
}

const StageComponent = (props) => {
    //CONSTANTS
    const zoomChangeValue = 0.1;
    const defaultItemHeight = 80;
    const defaultItemWidth = 80;
    const gridLayerPadding = 20;
    const stageMarginFromItem = 100;

    //STATES
    const [items, setItems] = useState([]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [gridLines, setGridLines] = useState([]);
    const [stageId, setStageId] = useState(props.stageId)


    //STORES
    const locationStore = useLocationStore();
    // const [locationData, setLocationData] = useState({ ...props.field });
    // const [computers, setComputers] = useState([...(props.field.computers || [])]);

    //HOOKS
    const classes = toolbarStyle();
    const stageRef = props.stageRef;
    const layerRef = props.layerRef;
    const trRef = props.transfomerRef;


    //SETTING CANVAS WIDTH AND HEIGHT
    useEffect(() => {
        if (stageRef && stageRef.current) {
            let canvasContainer = document.getElementById("stage-container-" + stageId);

            let containerWidth = canvasContainer.offsetWidth;
            let containerHeight = canvasContainer.offsetHeight;

            setWidth(containerWidth);
            setHeight(containerHeight);
        }
    }, [stageRef])

    // useEffect(() => {
    //     console.log("Refreshing canvas data change from props" + props.field.name_alias)
    //     let currComps = [...props.field.computers];
    //     setItems([...currComps])


    //     let maxX = currComps.reduce((accu, computer) => accu = parseInt(computer.pos_x) > accu ? parseInt(computer.pos_x) : accu, 0);
    //     let maxY = currComps.reduce((accu, computer) => accu = parseInt(computer.pos_y) > accu ? parseInt(computer.pos_y) : accu, 0);



    //     //Check if container width has bigger value than maxX + stageMarginFromItem * zoom
    //     let canvasContainer = document.getElementById("stage-container-" + stageId);
    //     let containerWidth = canvasContainer.offsetWidth;
    //     let containerHeight = canvasContainer.offsetHeight;
    //     if (containerWidth + stageMarginFromItem * zoom > maxX + stageMarginFromItem * zoom) {
    //         setWidth(containerWidth + stageMarginFromItem * zoom)
    //     } else {
    //         setWidth((prev) => maxX + stageMarginFromItem * zoom);
    //     }

    //     if (containerHeight + stageMarginFromItem * zoom > maxY + stageMarginFromItem * zoom) {
    //         setHeight((prev) => containerHeight + stageMarginFromItem * zoom)
    //     } else {
    //         setHeight((prev) => maxY + stageMarginFromItem * zoom);
    //     }

    //     //Empty gridllines
    //     let arrGridlines = [];
    //     let gridPadding = gridLayerPadding * zoom;
    //     //Adding horizontal gridlines
    //     for (let i = 0; i < containerWidth * 3 / gridPadding; i++) {
    //         arrGridlines.push({
    //             points: [i * gridPadding + 0.5, 0, i * gridPadding + 0.5, containerHeight * 3],
    //             stroke: '#ddd',
    //             strokeWidth: 1,
    //         });
    //     }

    //     //Adding vertical gridlines
    //     for (let i = 0; i < containerHeight * 3 / gridPadding; i++) {
    //         arrGridlines.push({
    //             points: [0, Math.round(i * gridPadding), containerWidth * 3, Math.round(i * gridPadding)],
    //             stroke: '#ddd',
    //             strokeWidth: 0.5,
    //         });
    //     }

    //     setGridLines(arrGridlines);

    //     if (stageRef && stageRef.current) {
    //         stageRef.current.clear();
    //     }
    // }, [])

    //RESIZING THE COMPUTER SIZE BASED ON ZOOM VALUE
    useEffect(() => {
        if (props.field && props.field.computers) {
            let currComps = [...props.field.computers];
            let newComputers = currComps.map((comp) => {
                let { pos_x, pos_y } = comp;
                // console.log(comp.ip + " :" + comp.isSelected)
                comp = {
                    ...comp,
                    pos_x: pos_x * zoom,
                    pos_y: pos_y * zoom,
                    width: defaultItemWidth * zoom,
                    height: defaultItemHeight * zoom
                };
                if (props.allowEdit) {
                    comp["draggable"] = true;
                }
                return comp;
            })
            setItems([...newComputers])

            let maxX = newComputers.reduce((accu, computer) => accu = parseInt(computer.pos_x) > accu ? parseInt(computer.pos_x) : accu, 0);
            let maxY = newComputers.reduce((accu, computer) => accu = parseInt(computer.pos_y) > accu ? parseInt(computer.pos_y) : accu, 0);


            //Check if container width has bigger value than maxX + stageMarginFromItem * zoom
            let canvasContainer = document.getElementById("stage-container-" + stageId);
            let containerWidth = canvasContainer.offsetWidth;
            let containerHeight = canvasContainer.offsetHeight;
            if (containerWidth + stageMarginFromItem * zoom > maxX + stageMarginFromItem * zoom) {
                setWidth(containerWidth + stageMarginFromItem * zoom)
            } else {
                setWidth(maxX + stageMarginFromItem * zoom);
            }

            if (containerHeight + stageMarginFromItem * zoom > maxY + stageMarginFromItem * zoom) {
                setHeight(containerHeight + stageMarginFromItem * zoom)
            } else {
                setHeight(maxY + stageMarginFromItem * zoom);
            }

            //Empty gridllines
            let arrGridlines = [];
            let gridPadding = gridLayerPadding * zoom;
            //Adding horizontal gridlines
            for (let i = 0; i < containerWidth * 3 / gridPadding; i++) {
                arrGridlines.push({
                    points: [i * gridPadding + 0.5, 0, i * gridPadding + 0.5, containerHeight * 3],
                    stroke: '#ddd',
                    strokeWidth: 1,
                });
            }

            //Adding vertical gridlines
            for (let i = 0; i < containerHeight * 3 / gridPadding; i++) {
                arrGridlines.push({
                    points: [0, Math.round(i * gridPadding), containerWidth * 3, Math.round(i * gridPadding)],
                    stroke: '#ddd',
                    strokeWidth: 0.5,
                });
            }

            setGridLines(arrGridlines);
            // setComputers(newComputers)
            // setLocationData({ ...locationData, computers: newComputers })
            if (stageRef && stageRef.current) {
                stageRef.current.clear()
            }
        }
    }, [props.field, props.field.computers, zoom])

    useEffect(() => {
        if (stageRef && stageRef.current) {
            const currStage = stageRef.current;
            currStage.width(width);
            currStage.height(height);
        }
    }, [width, height]);

    //INITIALIZING COMPUTER SHAPE WITH CHANGED X,Y WIDTH, AND HEIGHT VALUE

    const stageItems = items.map((item) => {
        return getItem(item, props.allowEdit,
            (props.handleClickOnComputer ? props.handleClickOnComputer : handleClickOnComputer),
            (props.onDragStartComputer ? props.onDragStartComputer : onDragStartComputer),
            (props.onDragEndComputer ? props.onDragEndComputer : onDragEndComputer),
            (props.onMouseOverComputer ? props.onMouseOverComputer : onMouseOverComputer),
            (props.onMouseLeaveComputer ? props.onMouseLeaveComputer : onMouseLeaveComputer),
            (props.onDropComputer ? props.onDropComputer : onDropComputer)
        )
    });

    //iNITIALIZING ONDRAG COMPUTER SHAPE
    const previewItems = getPreview(locationStore.getCanvasData.dragDropData);

    //INITIALIZING INFO MODAL DATA TO BE SHOWN
    const infoModal = getModal(toJS(locationStore.getInfoModalData),
        props.handleInfoModalDismiss ? props.handleInfoModalDismiss : handleInfoModalDismiss,
        props.allowEdit);


    //EVENTS ON STAGE COMPONENT
    const onClickZoomIn = (e) => {
        setZoom(zoom + zoomChangeValue);

        if (trRef && trRef.current) {
            trRef.current.nodes([])
        }
        // console.log("zoom:" + zoom);
    }

    const onClickZoomOut = (e) => {
        if (zoom - zoomChangeValue < 0) {
            setZoom(0.1);
        } else {
            setZoom(zoom - zoomChangeValue);
        }
        if (trRef && trRef.current) {
            trRef.current.nodes([])
        }
    }

    const onDragStart = (e, mode) => {
        e.evt.preventDefault();
    }

    const onDragEnd = (e) => {
        e.evt.preventDefault();
    }

    function onDragOver(e) {
        e.evt.preventDefault();
    }

    function onDragLeave(e) {
        e.evt.preventDefault();
    }

    function onDrop(e) {
        e.evt.preventDefault();
        console.log("Called from here")
    }

    const onClick = (e) => {
        console.log("Clicking from stage")
        console.log("Stage", e)
    }

    const handleClickOnComputer = (e, data) => {
        console.log("computer click handler from stage")
    }

    const onDragStartComputer = (e, data) => {
        console.log("computer drag start handler from stage")
    }

    function onDragEndComputer(e) {
        // e.evt.preventDefault();
    }

    function onDropComputer(e) {
        e.preventDefault()
    }

    function onMouseOverComputer(e) {
        e.evt.preventDefault();
    }

    function onMouseLeaveComputer(e) {
        e.evt.preventDefault();
    }


    function handleInfoModalDismiss(e, computerDetails) {
        locationStore.setInfoModalData(null);
    }

    return <React.Fragment>
        <Row className="m-1">
            <Button className="m-2" size='sm' onClick={onClickZoomIn}><FontAwesomeIcon icon={faPlus} /></Button>
            <Button className="m-2" size='sm' onClick={onClickZoomOut}><FontAwesomeIcon icon={faMinus} /></Button>
            <strong><p className="m-2" size='sm' >{parseInt(zoom * 100)} %</p></strong>
        </Row>
        <Row>
            <Container
                id={"stage-container-" + stageId}
                className={classes.stageContainer}
                onDragOver={props.onDragOver ? props.onDragOver : onDragOver}
                onDrop={props.onDrop ? props.onDrop : onDrop}
                onDragLeave={props.onDragLeave ? props.onDragLeave : onDragLeave}
            // onDragMove={props.onDragMove}
            >
                <Stage
                    // draggable={props.allowEdit}
                    className="mx-0"
                    container={"stage-container-" + stageId}
                    ref={stageRef}
                    width={width}
                    height={height}

                    // onClick={props.onClick ? props.onClick : onClick}
                    onMouseDown={props.onDragStart ? props.onDragStart : onDragStart}
                    onMouseUp={props.onMouseUp ? props.onMouseUp : onDragEnd}
                    onMouseMove={props.onMouseMove}
                >
                    <Layer key={"test"} id={"main_layer-" + stageId}
                        ref={layerRef}
                    >
                        {gridLines.map((line, index) => {
                            return <Line key={"grid-line-" + index} points={line.points} stroke={line.stroke} strokeWidth={line.strokeWidth} />;
                        })}
                        <Transformer id="transfomer" ref={trRef} />
                        {stageItems}
                        {previewItems}

                        <Rect fill="rgba(80,195,245,0.5)" ref={props.selectionRectRef} />
                        <InfoTooltip
                            id={"tooltip-" + stageId}
                            toolTipRef={props.toolTipRef}
                        />
                    </Layer>
                </Stage>

                {infoModal}


            </Container >
        </Row>
    </React.Fragment>
}

export default StageComponent;