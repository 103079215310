import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Nav from 'reactstrap/lib/Nav';
import Navbar from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import { clearAuth } from "~/apicall";
import { checkPermission } from "~/components/globals/utils/CheckPermission";
class NavbarExam extends Component {

    constructor(props) {
        super();
    }

    handleLogout = (e) => {
        clearAuth();
        const { history } = this.props;
        const redirectURI = "/exam-online/account/login";
        checkPermission("", ["student"], history, redirectURI);
    }

    render() {
        const { type, participant, onNotifShowRequested = () => { } } = this.props;

        const { notifications = [] } = participant;
        return (
            <Navbar color={((type === "inprogress") ? "info" : "warning")} light={type !== "inprogress"} dark={type === "inprogress"} expand="xs">
                <NavbarBrand href="/exam" to="/exam" tag={Link}>Oxam Exam Online</NavbarBrand>
                <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="bg-dark px-2 ml-3 text-light" style={{ borderRadius: "1.5rem" }} >
                            <FontAwesomeIcon icon={['far', 'bell']} /> Notifikasi  
                        </DropdownToggle>
                        <DropdownMenu right>
                            {(notifications || []).map((item) => <DropdownItem key={"notification" + item._id} onClick={() => onNotifShowRequested(item)}>
                                {item.title}
                            </DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="bg-dark px-2 ml-3 text-light" style={{ borderRadius: "2rem" }}>
                            <FontAwesomeIcon  icon={['far', 'user-circle']} /> {participant.username}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={this.handleLogout}>
                                Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Navbar>
        )
    }
}
NavbarExam.propTypes = {
    type: PropTypes.oneOf(["inprogress", "upcoming"]),
    participant: PropTypes.object.isRequired
}

NavbarExam.defaultProps = {
    type: "inprogress"
}
export default (observer(NavbarExam));