import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
const ResourceDownloader = ({ resource = {}, onDownloadRequest = {} }) => {
    let upload_name = "Berkas ujian tidak tersedia!";
    if (resource) {
        return (
            <React.Fragment>
                <li className='w-100 list-group-item d-flex justify-content-between align-items-center border-bottom px-12 '>
                    <strong><div className="ml-12" dangerouslySetInnerHTML={{ __html: (resource || {}).upload_name }} /></strong>
                    <span>    <Button id={"download-button-" + resource._id} color="primary" onClick={(e) => onDownloadRequest(e, resource)}>Download</Button>
                        <UncontrolledTooltip placement="right" target={"download-button-" + resource._id}>Unduh Berkas</UncontrolledTooltip>
                    </span>
                </li>
            </React.Fragment >
        )
    } else {
        return (
            <React.Fragment>
                <li className='w-100 list-group-item d-flex justify-content-between align-items-center border-bottom px-12 bg-light'>
                    <strong><div className="ml-12" dangerouslySetInnerHTML={{ __html: upload_name }} /></strong>
                    <span>    <Button color="secondary" disabled >Unduh Berkas</Button></span>
                </li>
            </React.Fragment >
        )
    }

}

export default ResourceDownloader