import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Alert, Button, ButtonGroup, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, UncontrolledDropdown } from 'reactstrap';
import { axios } from '~/apicall';
import DateTimePicker from '~/components/date-time-picker/date-time-picker';
import { makeId } from '~/components/globals/utils/Utils';

moment.locale('id');
/**
 * 2017730089
2017730088
2017730007
 */

const quickPick = [
    120,
    110,
    105
]

function Step1({ props, examDetails, onRequestNext }) {

    const [lectures, setLectures] = useState([]);
    const [lecturers, setLecturers] = useState([]);
    const [tipeUjian, setTipeUjian] = useState(1)
    const [mataKuliah, setMataKuliah] = useState(-1)
    const [collaborators, setCollaborators] = useState([])
    const [shift, setShift] = useState(0)
    const [online, setOnline] = useState(1)
    const [timeStart, setTimeStart] = useState(moment())
    const [duration, setDuration] = useState(7200)
    const [peserta, setPeserta] = useState("")
    const [berkasUjian, setBerkasUjian] = useState({});
    const [infoBerkasUjian, setInfoBerkasUjian] = useState({ total_size_berkas: 0, total_berkas: 0 });
    const [inputAlerts, setInputAlert] = useState({
        berkas: "",
        mataKuliah: "",
        peserta: ""

    });

    useEffect(() => {
        if (examDetails.uts) {
            setTipeUjian(examDetails.uts);
        }

        if (examDetails.lecture) {
            setMataKuliah(examDetails.lecture)
        }

        if (examDetails.shift) {
            setShift(examDetails.shift)
        }

        if (examDetails.online) {
            setOnline(examDetails.online)
        }

        if (examDetails.timeStart) {
            setTimeStart(moment(examDetails.timeStart))
        }

        if (examDetails.duration) {
            setDuration(examDetails.duration)
        }

        if (examDetails.peserta) {
            setPeserta((examDetails.peserta || []).join("\n"));
        }

        if (examDetails.berkasUjian) {
            setBerkasUjian(examDetails.berkasUjian || {});
        }

        if (examDetails.collaborators) {
            setCollaborators([...(examDetails.collaborators || [])]);
        }


        return () => { };
    }, [examDetails]);

    //Get lectures info
    useEffect(() => {
        axios.get("manage/lecture").then(data => {
            setLectures(data.data.data);
        })
        return () => { };
    }, [])

    //Get lecturers info
    useEffect(() => {
        axios.get("manageonline/lecturer").then(result => {
            let lecturerList = [...result.data.data]
            setLecturers([...lecturerList]);
        })
        return () => { };
    }, [])

    //Load data berkas dari props
    useEffect(() => {
        let totalFile = 0;
        let totalSize = 0;
        Object.entries(berkasUjian).map(([idx, berkas]) => {
            totalFile += 1;
            totalSize += berkas.size;
            return berkas;
        });
        setInfoBerkasUjian({ total_berkas: totalFile, total_size_berkas: totalSize });

    }, [berkasUjian])

    function handleSubmission(e) {
        e.preventDefault();

        //Prevent moving to next step, before filling the necessary values
        let canConfirm = true;

        //Alert for field lecture
        if (parseInt(mataKuliah) <= 0) {
            setInputAlert({ ...inputAlerts, mataKuliah: "Nama matakuliah yang akan diujikan harus dipilih!" });
            setTimeout(() => {
                setInputAlert({ ...inputAlerts, mataKuliah: "" });
            }, 3000)
            canConfirm = false;
        }

        //Alert for field participants
        if (!peserta) {
            setInputAlert({ ...inputAlerts, peserta: "Daftar peserta ujian harus diisi!" });
            setTimeout(() => {
                setInputAlert({ ...inputAlerts, peserta: "" });
            }, 3000);
            canConfirm = false;
        } else {
            //Frontend check input NPM following the required format
            let listPeserta = peserta
                .split(/(\n)/)
                // clean up
                .map((e) => e.replace(/\s+/, ""))
                .filter(e => e.length > 0);

            const regex = /^(61[6|7|8][0-9]{7})|(20[0-9]{8})$/;
            for (let p of listPeserta) {
                if (regex.exec(p) === null) {
                    setInputAlert({ ...inputAlerts, peserta: "NPM yang dimasukkan tidak sesuai format! NPM: " + p });
                    setTimeout(() => {
                        setInputAlert({ ...inputAlerts, peserta: "" });
                    }, 3000);
                    canConfirm = false;
                }
            }
        }

        if (!canConfirm) {
            return;
        }

        let examDetails = {
            uts: tipeUjian,
            lecture: mataKuliah,
            // eslint-disable-next-line
            lecture_obj: lectures.filter(e => e._id == mataKuliah)[0],
            shift: shift,
            timeStart: timeStart.format("YYYY-MM-DD HH:mm:ss"),
            duration: duration,
            online: online,
            berkasUjian: berkasUjian,
            collaborators: collaborators,

            peserta: peserta
                .split(/(\n)/)
                // clean up
                .map(e => e.replace(/\s+/, ""))
                .filter(e => e.length > 0)
        };

        onRequestNext(examDetails);
    }

    function handlePesertaFileUpload(e) {
        let file = (e.target.files || [])[0];
        if (file) {
            let reader = new FileReader();
            reader.onload = (event) => {
                setPeserta(event.target.result);
            }
            reader.readAsText(file);
        }
    }

    function handleSelectCollaborator(e) {
        let target = e.target.value;
        let newCollaborators = []

        let isSelected = collaborators.filter((data) => {
            return data.uuid === target;
        })

        if (isSelected.length) {
            //current target is an already selected lecturer, so we removed it from collaborators list
            newCollaborators = collaborators.filter((data) => {
                return data.uuid !== target;
            });
        } else {
            //Get current target's information
            let objTarget = lecturers.filter(data => data.uuid === target)
            let tempArr = [...collaborators, ...objTarget];
            //Filter it out, so there wont be any duplicate
            newCollaborators = tempArr.filter((data, index) => {
                return tempArr.indexOf(data) === index
            });
        }
        setCollaborators(newCollaborators);
    }

    function handleUnselectCollaborator(e) {
        let target = e.target.id;
        let ids = target.split("selected-");
        let newArr = collaborators.filter(data => data.uuid !== ids[1]);
        setCollaborators([...newArr]);
    }

    function handleBerkasFileUpload(e) {
        const chossenFiles = Array.prototype.slice.call(e.target.files)
        console.log(chossenFiles)
        const newBerkas = { ...berkasUjian };
        let countBerkas = Object.keys(newBerkas).length;
        let totalSize = infoBerkasUjian.total_size_berkas;

        for (let file of chossenFiles) {

            if (totalSize >= (50 * 10 ** 6) || totalSize + file.size > (50 * 10 ** 6)) {
                setInputAlert({ ...inputAlerts, berkas: "Ukuran berkas melebihi ukuran maksimal berkas yang dapat diunggah!" })
                setTimeout(() => {
                    setInputAlert({ berkas: "" });
                }, 3000);
                break;
            }

            let berkasID = makeId(8)
            newBerkas[berkasID] = file;
            totalSize += file.size
            countBerkas += 1
        }

        setInfoBerkasUjian({
            total_size_berkas: totalSize,
            total_berkas: countBerkas
        })
        setBerkasUjian({ ...newBerkas });
        e.target.value = [];
    }

    function handleUnSetBerkas(e) {
        //Get selected element's id
        const elementID = e.target.id;
        //Get file id by spliting it from the ID
        const fileID = elementID.split("btn-berkas-");

        //Convert object to array, for filtering
        const asArray = Object.entries(berkasUjian);
        //Get the soon to be deleted file
        const deletedBerkas = berkasUjian[fileID[1]];

        //filter the current uploaded files and remove the file with id equals to target's ID
        const filtered = asArray.filter(([key, value]) => key !== fileID[1]);

        //Set state using new files
        setBerkasUjian({ ...Object.fromEntries(filtered) });
        //Set the info of files in the new state
        setInfoBerkasUjian({
            total_berkas: infoBerkasUjian.total_berkas - 1,
            total_size_berkas: infoBerkasUjian.total_size_berkas - deletedBerkas.size
        })
        console.log(filtered);
    }

    function handleUnSetAllBerkas(e) {
        setBerkasUjian({});
        setInfoBerkasUjian({ total_berkas: 0, total_size_berkas: 0 })
    }

    function convertToBytesUnit(size) {
        let KB = 10 ** 3;
        let MB = 10 ** 6;
        let GB = 10 ** 9;

        if (size / KB >= 1) {
            if (size / MB >= 1) {
                if (size / GB >= 1) {
                    return Math.ceil(size / GB) + " GB"
                }
                return Math.ceil(size / MB) + " MB"
            }
            return Math.ceil(size / KB) + " KB"
        }
        return size + " KB"
    }

    return (
        <React.Fragment>
            <div className="py-5">
                <Form className="w-100" onSubmit={handleSubmission}>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <Label>Tipe Ujian</Label>
                                <Input type="select" name="tipe_ujian" value={tipeUjian} onChange={e => setTipeUjian(parseInt(e.target.value))}>
                                    <option value={0}>UAS</option>
                                    <option value={1}>UTS</option>
                                    <option value={2}>QUIZ</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Mata Kuliah</Label>
                                <Input type="select" name="mata_kuliah" disabled={!lectures} value={mataKuliah} onChange={e => setMataKuliah(e.target.value)}>
                                    <option value={-1} disabled>-- {lectures.length > 0 ? "Pilih Mata Kuliah" : "Memuat..."} --</option>
                                    {lectures.map(data => <option value={data._id} key={data._id}>{data.lecture_code} - {data.name}</option>)}
                                </Input>
                            </FormGroup>
                            {inputAlerts.mataKuliah && inputAlerts.mataKuliah.length ? <div className='alert alert-danger m-1 slide'>{inputAlerts.mataKuliah}</div> : <></>}
                        </Col>
                    </Row>

                    <Row className='w-100'>
                        <Col md={8} sm={2} lg={12}>

                            <Row className='ml-1'>
                                <Label>Dosen Kolaborator</Label>
                            </Row>
                            <Row md={8} className="ml-1 mb-1">
                                <ButtonGroup vertical >
                                    <UncontrolledDropdown md={8} >
                                        <div className='w-100 bg-white border rounded-lg'>
                                            <DropdownToggle caret className="w-100" color={"none"}> {lecturers.length > 0 ? "Tambahkan dosen pengawas lainnya" : "                     Memuat...                  "}</DropdownToggle>
                                        </div>
                                        <DropdownMenu>
                                            {
                                                lecturers.map((data) =>
                                                    <DropdownItem id={data.uuid + ""} key={data.uuid + ""} value={data.uuid + ""} color={data.is_selected ? "primary" : "none"} onClick={(e) => handleSelectCollaborator(e)} >{data.display_name}</DropdownItem>
                                                )
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                </ButtonGroup>
                                <div className='w-auto'>
                                    {collaborators.map(data => {
                                        if (data && data.uuid) {
                                            return (
                                                <Button id={"selected-" + data.uuid}
                                                    key={"selected-" + data.uuid}
                                                    className="ml-1 mt-1"
                                                    color="primary"
                                                    onClick={(e) => handleUnselectCollaborator(e)}>
                                                    {data.display_name}
                                                </Button>
                                            )
                                        }
                                        return "";
                                    })}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col>
                            <FormGroup>
                                <Label>Shift Ujian</Label>
                                <Input type="select" name="shift" value={shift} onChange={e => setShift(e.target.value)}>
                                    <option value={0}>Tidak ada shift</option>
                                    <option value={1}>Shift 1</option>
                                    <option value={2}>Shift 2</option>
                                    <option value={3}>Shift 3</option>
                                </Input>
                            </FormGroup>

                        </Col> */}
                        <Col>
                            <FormGroup>
                                <Label>Mulai pada</Label>
                                <DateTimePicker time={timeStart} onChange={setTimeStart} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Selama</Label>
                                <InputGroup>
                                    <Input type="number" name="duration" value={duration / 60} onChange={e => setDuration(e.target.value * 60)} />
                                    <InputGroupAddon addonType="append">Mnt</InputGroupAddon>
                                </InputGroup>

                                <p className="m-0">Quick Pick:</p>
                                <ButtonGroup>
                                    {quickPick.map((el, i) => <Button onClick={e => setDuration(el * 60)} key={i}>{el} Mnt</Button>)}
                                </ButtonGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='overflow-auto' >
                        <Col className='m-0 mr-1  '>
                            <FormGroup id="upload-berkas-ujian" className='' >
                                <Label>Berkas Ujian (maks. 20 MB per berkas)</Label>
                                <InputGroup className='w-80'>
                                    <Input id="inputs-berkas-ujian" type="file" multiple onChange={handleBerkasFileUpload} style={{ display: "none" }} />
                                    <Button color='primary' onClick={(e) => { document.getElementById("inputs-berkas-ujian").click() }}>Unggah Berkas Ujian</Button>
                                </InputGroup>

                                <Container className='w-full  m-0 mt-1 p-0 border rounded-lg overflow-auto' style={{ maxHeight: 35 + "vh", minHeight: "50px" }}>

                                    {
                                        Object.keys(berkasUjian).length ?
                                            <>
                                                <Row className='mt-2 mx-1 p-0 py-2 text-center' >
                                                    <Col className='alert-warning text-center' md={10}>
                                                        <p className="col align-self-center">Hapus Semua</p>
                                                    </Col>
                                                    <Col className='w-20' md={2}><Button className="btn-warning" onClick={(e) => { handleUnSetAllBerkas(e) }} >-</Button></Col>
                                                </Row>
                                                {
                                                    Object.entries(berkasUjian).map(([idx, berkas]) => {
                                                        return (
                                                            <Row className='mt-2 mx-1 p-0 py-2 text-center' id={"berkas-" + idx} key={"berkas-" + idx}>
                                                                <Col className='alert-primary text-center' md={10}>
                                                                    <p id={"p-berkas-" + idx} className="col align-self-center">{berkas.name}</p>
                                                                </Col>
                                                                <Col className='w-20' md={2}><Button id={"btn-berkas-" + idx} key={"btn-berkas-" + idx} onClick={(e) => { handleUnSetBerkas(e) }}>-</Button></Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </>

                                            :
                                            (
                                                <Row className='mt-2 mx-1 p-0 py-2 ' >
                                                    <Col className='alert-dark text-center'>
                                                        <p className='m-auto p-auto'>Tidak ada berkas terunggah</p>
                                                    </Col>
                                                </Row>
                                            )
                                    }
                                </Container>
                                <Row className='mx-1'>
                                    <span>Jumlah berkas: <strong>{infoBerkasUjian.total_berkas}</strong>, Total ukuran: <strong>{convertToBytesUnit(infoBerkasUjian.total_size_berkas)}</strong></span>
                                </Row>
                                {inputAlerts.berkas && inputAlerts.berkas.length > 0 ? <div className='alert alert-danger m-1 slide'>{inputAlerts.berkas}</div> : ""}
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Peserta</Label>
                                <Input type="textarea" rows={15} value={peserta} onChange={(e) => setPeserta(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label>atau, Unggah Berkas Daftar Peserta?</Label>
                                <Input type="file" accept="text/plain" onChange={handlePesertaFileUpload} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Alert color="info">
                                <h3>Peserta</h3>
                                <p>
                                    NPM standar 1955 (20xx7α0yyy) dan NPM standar 2018 (6ααxx01yyy) dapat digunakan. Aplikasi akan otomatis melakukan abstraksi terhadap absensi tersebut dan
                                    melakukan transformasi ke username standar lab.
                                </p>
                                <p>
                                    Informasi lebih lanjut, Anda dapat merujuk pada berkas <a href="https://gitlab.com/ftis-admin/oxam/blob/master/backend%2Fapp%2Fcontroller%2Fapi%2Fmanage%2Fexam.php" target="_blank" rel="noopener noreferrer">controller/api/manage/exam.php</a>.
                                </p>
                            </Alert>
                            <p>
                                Total Peserta: {(peserta.split(/\n/) || []).filter(e => e.length > 0).length} jiwa.
                            </p>
                            {inputAlerts.peserta && inputAlerts.peserta.length ? <div className='alert alert-danger m-1 slide'>{inputAlerts.peserta}</div> : <></>}
                        </Col>
                    </Row>
                    <Row>


                    </Row>
                    <Row>
                        <Col>
                            <div className="text-right mt-5">
                                <Button color="primary" size="lg" type="submit">Confirmation <FontAwesomeIcon icon={faChevronRight} /></Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </React.Fragment >
    )
}

export default inject("examOnlineStore")(
    withRouter(observer(Step1)));
