import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { axios } from '~/apicall';
import StageComponent from '~/components/location/StageComponent';
import { useAdminStore, useLocationStore } from '~/components/use-store';

function AdminExamMigrator({ isOpen = false, onUpdated = () => { }, participants = [], onCloseRequested = () => { } }) {
    const TEXT_SPACING = 5;
    const locationStore = useLocationStore();
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [selectedParticipant, setSelectedParticipant] = useState(undefined)
    const [selectedComputer, setSelectedComputer] = useState(undefined)
    const [participantFinder, setParticipantFinder] = useState("")
    const [finalMigrtationScript, setFinalMigrtationScript] = useState(undefined);
    const [scriptDownloaded, setScriptDownloaded] = useState(false)

    const [migrationCanidates, setMigrationCanidates] = useState([]);

    const [locationDatas, setLocationDatas] = useState([]);
    const [computerList, setComputerList] = useState({})
    const [computerByParticipant, setComputerByParticipant] = useState({});

    //Modal trigger
    const [isHoverComputer, setIsHoverComputer] = useState(false);

    const adminStore = useAdminStore();

    //Refs
    const stageRefs = useRef([]);
    const toolTipRefs = useRef([]);

    let participantFiltered = participants.filter((par) =>
        participantFinder.length === 0 ||
        (par.username || "").includes(participantFinder) ||
        (par.name || "").includes(participantFinder) ||
        (par.npm || "").includes(participantFinder) ||
        ((par.computer || {}).name || "").includes(participantFinder)
    );

    const [selectedLocations, setSelectedLocations] = useState(0)

    useEffect(() => {
        locationStore.locationFetch().then(data => {
            let locations = [...locationStore.locations];


            let compByPar = [];
            participants.map((par) => {
                compByPar[par.computer] = par;
            })

            let komp = {};
            locations.forEach(loc => {
                loc.computers.forEach(comp => {

                    if (compByPar[comp._id] && compByPar[comp._id].computer === comp._id) {
                        comp.isOccupied = true;
                    }

                    komp[comp._id] = comp;

                })
            });


            setLocationDatas([...locations]);
            setComputerList(komp);
            setComputerByParticipant(compByPar)
        })
        return () => { };
    }, [participants]);


    useEffect(() => {
        //Updating stageRef
        stageRefs.current = stageRefs.current.slice(0, locationDatas.length);
        toolTipRefs.current = toolTipRefs.current.slice(0, locationDatas.length);

    }, [locationDatas, selectedLocations])


    function handleAddMigrator(comp) {
        setMigrationCanidates([...migrationCanidates, {
            participant: selectedParticipant,
            to: comp._id
        }])

        setSelectedParticipant({})
        setParticipantFinder("")
        setShowAddDialog(false)
    }

    function handleScriptGenerationRequest() {
        axios.post("manage/exam/" + adminStore.exam._id + "/move", {
            lists: migrationCanidates.map((data) => ({ participant: data.participant._id, to: data.to }))
        }, {
            responseType: 'blob'
        }).then((response) => {
            setShowAddDialog(false)
            setFinalMigrtationScript({
                data: response.data,
                filename: response.headers['x-filename']
            })
            setMigrationCanidates([])
            onUpdated()
        })
    }

    function handleScriptDownloadSimulate() {
        setScriptDownloaded(true);
        fileDownload(finalMigrtationScript.data, finalMigrtationScript.filename);
    }

    function onClickComputer(e, computerDetails) {
        // setSelectedComputer(computerDetails)
        // console.log(computerDetails);
        if (selectedParticipant && selectedParticipant.to === undefined) {
            let newData = {};
            let participant = computerByParticipant[computerDetails._id];
            let isAlreadySelected = migrationCanidates.filter((candidate) => (candidate.to === computerDetails._id))

            if (isAlreadySelected.length === 0 && participant === undefined) {
                newData = { ...selectedParticipant };
                newData.to = computerDetails._id;
                migrationCanidates.push(newData);
                setSelectedParticipant(undefined);

                setLocationDatas((prev) => {
                    let newLocData = locationDatas.map((room, i) => {
                        // console.log(room);
                        room.computers.map((comp) => {
                            if (comp._id === computerDetails._id) {
                                comp.isSelected = true;
                            }
                            return comp;

                        })
                        return room;
                    })
                    return newLocData;
                });

            } else {
                console.log(participant)
            }

        } else if (selectedParticipant === undefined) {
            let newData = {};
            let participant = computerByParticipant[computerDetails._id];

            let isIncluded = migrationCanidates.filter((candidate) => candidate.participant.npm === participant?.npm);

            if (isIncluded.length === 0 && participant !== undefined) {
                newData.participant = participant;
                newData.to = undefined;

                setSelectedParticipant(newData);

            }

              //Refresh canvas
              if (stageRefs.current && stageRefs.current[selectedLocations] && toolTipRefs.current && toolTipRefs.current[selectedLocations]) {
                let stage = stageRefs.current[selectedLocations];
                stage.clear();
            }
        }



    }

    function handleDeselectCandidate(data) {
        // console.log(migrationCanidates)
        let newCandidateData = migrationCanidates.filter((candidate) => !(candidate.participant.npm === data.participant.npm));
        setMigrationCanidates([...newCandidateData]);
        setLocationDatas((prev) => {
            let newLocData = locationDatas.map((room, i) => {
                // console.log(room);
                room.computers.map((comp) => {
                    if (comp._id === data.to) {
                        comp.isSelected = false;
                    }
                    return comp;

                })
                return room;
            })
            return newLocData;
        });

        //Refresh canvas
        if (stageRefs.current && stageRefs.current[selectedLocations] && toolTipRefs.current && toolTipRefs.current[selectedLocations]) {
            let stage = stageRefs.current[selectedLocations];
            stage.clear();
        }
    }

    function handleUnselect(e) {
        setSelectedParticipant(undefined);
    }

    function onMouseOverComputer(e, computerDetails) {
        // e.evt.preventDefault();

        if (stageRefs.current && stageRefs.current[selectedLocations] && toolTipRefs.current && toolTipRefs.current[selectedLocations]) {
            let stage = stageRefs.current[selectedLocations];
            let toolTipRef = toolTipRefs.current[selectedLocations];

            if (!toolTipRef.isVisible()) {
                setIsHoverComputer(true);
                setSelectedComputer({ ...computerDetails });
                toolTipRef.visible(true);

                let tooltipTriangle = toolTipRef.getChildren((node) => {
                    return node.getClassName() === "RegularPolygon";
                })

                if (tooltipTriangle.length) {
                    tooltipTriangle = tooltipTriangle[0];
                    tooltipTriangle.x(computerDetails.pos_x + computerDetails.width * 0.5);
                    tooltipTriangle.y(computerDetails.pos_y + computerDetails.height * 0.2);
                    tooltipTriangle.show();
                }

                let rectBound = toolTipRef.getChildren((node) => {
                    return node.getClassName() === "Rect";
                })

                if (rectBound.length) {
                    rectBound = rectBound[0];
                    rectBound.x(computerDetails.pos_x + computerDetails.width * 0.5);
                    rectBound.y(computerDetails.pos_y);
                    rectBound.show();
                }

                let textParticipant = toolTipRef.getChildren((node) => {
                    return node.getClassName() === "Text";
                })

                if (textParticipant.length) {
                    let textOccupiedBy = textParticipant[0];
                    textOccupiedBy.x(rectBound.x() + TEXT_SPACING * 3);
                    textOccupiedBy.y(rectBound.y() + TEXT_SPACING * 2);

                    textOccupiedBy.show()


                    let textNPM = textParticipant[1];
                    textNPM.x(textOccupiedBy.x());
                    textNPM.y(textOccupiedBy.y() + textOccupiedBy.height() + TEXT_SPACING);
                    let participant = computerByParticipant[computerDetails._id]

                    if (participant !== undefined) {
                        textNPM.fill("black");
                        textNPM.text(participant.npm);
                    } else {
                        textNPM.fill("red");
                        textNPM.text("None");
                    }

                    textNPM.show();

                    let textName = textParticipant[2];
                    textName.x(textNPM.x());
                    textName.y(textNPM.y() + textNPM.height() + TEXT_SPACING);


                    if (participant !== undefined) {
                        textName.fill("black");
                        textName.text(participant.display_name);
                    } else {
                        textName.fill("red");
                        textName.text("No participant");
                    }

                    textName.show();
                }
                stage.clear();
            }

        }
    }

    function onMouseLeaveComputer(e) {
        if (stageRefs.current && stageRefs.current[selectedLocations] && toolTipRefs.current && toolTipRefs.current[selectedLocations]) {
            let stage = stageRefs.current[selectedLocations];
            let toolTipRef = toolTipRefs.current[selectedLocations];

            if (toolTipRef.isVisible()) {
                let selectedComp = { ...selectedComputer };

                setIsHoverComputer(false);
                setSelectedComputer(undefined);

                toolTipRef.hide();

                let tooltipChild = toolTipRef.children;

                tooltipChild.map((el) => {
                    el.hide();
                    return el;
                })
            }
            stage.clear();

        }
    }



    return (
        <>
            {/* <Modal isOpen={isOpen} size="lg" backdrop>
                <ModalHeader>Migrator</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Button onClick={() => setShowAddDialog(true)}><FontAwesomeIcon icon={faPlus} /> Tambah</Button>
                        </Col>
                    </Row>
                    <Table bordered striped>
                        <thead>
                            <tr>
                                <th>Peserta</th>
                                <th>Komputer Asal</th>
                                <th>Komputer Tujuan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {migrationCanidates.map((data, i) => <tr key={"migration-" + i}>
                                <td>{data.participant.name} - {data.participant.npm}</td>
                                <td>{(computerList[data.participant.computer] || {}).name}</td>
                                <td>{(computerList[data.to] || {}).name}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        setMigrationCanidates([]);
                        onCloseRequested()
                    }}>Batal</Button>
                    <Button color="primary" onClick={handleScriptGenerationRequest}>Pindah dan Buat Script</Button>
                </ModalFooter>
            </Modal> */}
            <Modal isOpen={isOpen} size={'xl'} backdrop>
                <ModalHeader>Tambahkan Peserta</ModalHeader>
                <ModalBody className="">
                    <Col className="" >
                        <Row className="mt-5 w-100 mr-0  p-0 " xs={2} lg={2}>
                            <Nav tabs>
                                {locationDatas.map((loc, i) => <NavItem key={loc._id}>
                                    <NavLink
                                        className={classNames({ active: selectedLocations === i })}
                                        onClick={() => { setSelectedLocations(i); }}
                                    >
                                        {loc.room_name} <Badge color={(loc.computers || []).filter(e => !!e.selected).length > 0 ? "primary" : "secondary"}>{loc.name_alias}</Badge>
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                        </Row>
                        <Row className="my-2 h-50vh w-100  mb-5 " xs={2} lg={2}>
                            <Col className="w-60 h-50vh">
                                <TabContent activeTab={selectedLocations} className="" >
                                    <If condition={locationDatas.length > 0}>
                                        <Then>


                                            {locationDatas.map((loc, i) => {
                                                return <TabPane tabId={i} key={i} className="">
                                                    {/* <Container className="my-4 " > */}
                                                    <StageComponent
                                                        key={"stage-" + i}
                                                        stageId={"stage-" + i}
                                                        allowEdit={false}
                                                        stageRef={ref => (stageRefs.current[i] = ref)}
                                                        toolTipRef={ref => (toolTipRefs.current[i] = ref)}
                                                        field={loc}
                                                        handleClickOnComputer={onClickComputer}
                                                        onMouseOverComputer={onMouseOverComputer}
                                                        onMouseLeaveComputer={onMouseLeaveComputer}
                                                    />
                                                    {/* </Container> */}
                                                </TabPane>
                                            })}



                                        </Then>
                                        <Else>
                                            <></>
                                        </Else>
                                    </If>
                                </TabContent>
                            </Col>
                            <Col className="w-40" style={{
                                maxHeight: "50vh",
                                overflow: "scroll",
                            }} >
                                <Table bordered striped >
                                    <thead className='position-sticky'>
                                        <tr>
                                            <th>Peserta</th>
                                            <th>Asal</th>
                                            <th>Tujuan</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {migrationCanidates.length ? migrationCanidates.map((data, i) => <tr key={"migration-" + i}>
                                            <td>{data.participant.name} - {data.participant.npm}</td>
                                            <td>{(computerList[data.participant.computer] || {}).name}</td>
                                            <td>{(computerList[data.to] || {}).name}</td>
                                            <td><Button color="danger" onClick={(e) => handleDeselectCandidate(data)}><FontAwesomeIcon icon={faTrash} color="white" /></Button></td>
                                        </tr>) : <></>}

                                        {selectedParticipant ? (<tr key={"migration-selecte-"}>
                                            <td>{selectedParticipant.participant.name} - {selectedParticipant.participant.npm}</td>
                                            <td>{(computerList[selectedParticipant.participant.computer] || {}).name}</td>
                                            <td>{(computerList[selectedParticipant.to] || {}).name}</td>
                                            <td><Button color="danger" onClick={(e) => handleUnselect()}><FontAwesomeIcon icon={faTrash} color="white" /></Button></td>
                                        </tr>) : <></>}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        setShowAddDialog(false)
                        setSelectedParticipant(undefined)
                        setParticipantFinder("")
                        setMigrationCanidates([]);
                        onCloseRequested()
                    }}>Batalkan</Button>
                    <Button color="primary" onClick={handleScriptGenerationRequest}>Pindah dan Buat Script</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={!!finalMigrtationScript}>
                <ModalHeader>Unduh Script</ModalHeader>
                <ModalBody>
                    <p>Mohon pastikan anda mengunduh script ini karna perpindahan telah dilakukan pada database dan perubahan dibuat permanen.</p>
                    <div className="text-center"><Button color="info" onClick={handleScriptDownloadSimulate}>Unduh Script</Button></div>
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" disabled={!scriptDownloaded} onClick={() => {
                        setScriptDownloaded(false)
                        setFinalMigrtationScript(undefined)
                        onCloseRequested()
                    }}>Tutup</Button>
                </ModalFooter>
            </Modal>

            {/* <Modal isOpen={!!isHoverComputer}
                style={{ left: selectedComputer.pos_x, top: selectedComputer.pos_y }}
                backdrop={false}>
                <ModalBody>
                    Hover over computer : {selectedComputer.name}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={}>Close</Button>
                </ModalFooter>
            </Modal> */}


        </>
    )
}

export default observer(AdminExamMigrator)
