import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from "react";
import { When } from 'react-if';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import SunEditor, { buttonList } from 'suneditor-react';
import { axios } from '~/apicall';
import { useExamOnlineStore } from '~/components/use-store';
import NotificationAudiencePicker from './AudiencePicker';
const NotificationListerChild = ({ notif = {}, onDeleted = () => { }, onEdited = () => { } }) => {
    const [editOpen, setEditOpen] = useState(false);
    const [deleteRequested, setDeleteRequested] = useState(false);
    const [notification, setNotification] = useState(notif); // used for editors
    const [audiencePickerShow, setAudiencePickerShow] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const examOnlineStore = useExamOnlineStore();
    const exam = examOnlineStore.exam;

    function handleEditRequest(e) {
        e.preventDefault();
        let toBeDeleted = { ...notification };
        let url = "manageonline/notificationonline/" + notif._id;

        //Removing unecessary attribute
        delete toBeDeleted._id;
        delete toBeDeleted.created_on;
        delete toBeDeleted.updated_on;
        delete toBeDeleted.deleted_on;

        setIsUpdating(true);

        axios.put(url, toBeDeleted).then((resp) => {
            setIsUpdating(false);
            setEditOpen(false);
            onEdited();
        }).catch((err) => {
            console.log(err);
        });
    }

    function handleDeleteRequest(e) {
        e.preventDefault()
        let url = "manageonline/notificationonline/" + notif._id;
        setIsDeleting(true);

        axios.delete(url).then((resp) => {
            setIsDeleting(true);
            setDeleteRequested(false);
            onDeleted();
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <React.Fragment>
            <tr>
                <td>
                    {notif.title}
                </td>
                <td style={{ textAlign: "right" }}>
                    <Button className="ml-2" onClick={() => setEditOpen(true)}><FontAwesomeIcon icon={faPen} /></Button>
                    <Button className="ml-2" color="danger" onClick={() => setDeleteRequested(true)}><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
            </tr>
            <Modal isOpen={deleteRequested} backdrop>
                <ModalHeader>Konfirmasi</ModalHeader>
                <ModalBody>
                    <p>Apakah anda yakin untuk menghapus notifikasi ini?</p>
                    <div className="p-4 bg-dark text-light">
                        <div dangerouslySetInnerHTML={{ __html: notif.description }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={handleDeleteRequest}>
                        Hapus {isDeleting ? <Spinner as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /> : ""}
                    </Button>
                    <Button color="primary" onClick={() => setDeleteRequested(false)}>Batal</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={editOpen} size="lg" backdrop>
                <ModalHeader>Ubah Notifikasi</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Judul</Label>
                            <Input type="text" defaultValue={notification.title} onChange={(e) => setNotification({ ...notification, title: e.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Penerima Notifikasi</Label>
                            <Input plaintext value={notification.participants.length + " orang, (klik untuk mengubah)"} onClick={() => setAudiencePickerShow(true)} onChange={() => { }} />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Deskripsi
                            </Label>
                            <SunEditor setOptions={{ buttonList: buttonList.formatting, height: 200 }} setContents={notification.description} onChange={(val) => setNotification({ ...notification, description: val })} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        setNotification(notif); // reset
                        setEditOpen(false);
                    }}>Batal</Button>
                    <Button color="primary" onClick={handleEditRequest}> Simpan {isUpdating ? <Spinner as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" /> : ""}</Button>
                </ModalFooter>
            </Modal>

            {/* Modal pendukung */}
            <When condition={audiencePickerShow}>
                <NotificationAudiencePicker participants={exam.online_participants} selectedAudiences={notification.participants} onSelected={(val) => {
                    setNotification({ ...notification, participants: val })
                    setAudiencePickerShow(false)
                }} onCanceled={() => setAudiencePickerShow(false)} />
            </When>
        </React.Fragment>
    )
}

export default NotificationListerChild