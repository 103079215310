import { library } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AdminController from '~/pages/admin/Admin';
import ExamOnlineController from '~/pages/exam-online/Index';
import ExamController from '~/pages/exam/Exam';
import MainIndex from '~/pages/index/Index';

import { faBell, faDizzy, faGrinTears, faLemon, faMeh, faMehRollingEyes, faSurprise, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { Provider } from "mobx-react";
import AutonomusIndex from '~/pages/autonomus';
import { Forbidden, NotFound } from "~/pages/public";
import AdminStore from '~/store/adminStore';
import EntityStore from '~/store/entityStore';
import ExamOnlineStore from '~/store/examOnlineStore';
import ExamStore from '~/store/examStore';
import LocationStore from "~/store/locationStore";
import OnlineExamStore from "~/store/studentExamStore";
library.add(faMehRollingEyes, faSurprise, faMeh, faGrinTears, faDizzy, faLemon, faUserCircle, faBell, faCloudUploadAlt);

class App extends React.Component {
  render() {
    return (
      <Provider examStore={new ExamStore()} adminStore={new AdminStore()} entityStore={new EntityStore()} examOnlineStore={new ExamOnlineStore()} onlineExamStore={new OnlineExamStore()} locationStore={new LocationStore()}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={MainIndex} />
            <Route path="/exam-online" component={ExamOnlineController} />
            <Route path="/exam" component={ExamController} />
            <Route path="/admin" component={AdminController} />
            <Route path="/autonomus" component={AutonomusIndex} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/forbidden" component={Forbidden} />
            <Redirect to="/not-found" />
          </Switch>
        </BrowserRouter>
      </Provider >
    );
  }
}

export default App;
