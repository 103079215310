import { MobXProviderContext } from 'mobx-react';
import React from 'react';

// eslint-disable-next-line
import AdminStore from '~/store/adminStore';
// eslint-disable-next-line
import ExamStore from '~/store/examStore';
// eslint-disable-next-line
import EntityStore from '~/store/entityStore';

// eslint-disable-next-line
import ExamOnlineStore from '~/store/examOnlineStore';

// eslint-disable-next-line
import OnlineExamStore from "~/store/studentExamStore";
// eslint-disable-next-line
import LocationStore from '~/store/locationStore';


function useStores() {
  return React.useContext(MobXProviderContext)
}

export default useStores;

/**
 * Use Exam Store
 * @return {ExamStore} exam store
 */
export function useExamStore() {
  const { examStore } = useStores();
  return examStore;
}

/**
 * Use AdminStore
 * @return {AdminStore} admin store
 */
export function useAdminStore() {
  const { adminStore } = useStores();
  return adminStore;
}

/**
 * Use EntityStore
 * @return {EntityStore} entity store
 */
export function useEntityStore() {
  const { entityStore } = useStores();
  return entityStore;
}


/**
 * Use EntityStore
 * @return {ExamOnlineStore} entity store
 */
export function useExamOnlineStore() {
  const { examOnlineStore } = useStores();
  return examOnlineStore;
}

/**
 * Use EntityStore
 * @return {OnlineExamStore} entity store
 */
export function useOnlineExamStore() {
  const { onlineExamStore } = useStores();
  return onlineExamStore;
}


/**
 * Use LocationStore
 * @return {LocationStore} location store
 */
export function useLocationStore() {
  const { locationStore } = useStores();
  return locationStore;
}
