import { createUseStyles } from "react-jss";
import { Button, Container } from "reactstrap";

//STORE

//BUTTONS ICON
import { observer } from "mobx-react";
import alignHorizontalIcon from "~/assets/align-hor.svg";
import alignVerticalIcon from "~/assets/align-ver.svg";
import computerHorizontal from "~/assets/computer-hor-svg.svg";
import computerIcon from "~/assets/computer-svg.svg";
import computerVertical from "~/assets/computer-ver-svg.svg";
import infoIcon from "~/assets/info.png";
import markIcon from "~/assets/marked-unavailable.png";
import moveIcon from "~/assets/move-icon.png";
import selectIcon from "~/assets/select-icon.png";
import trashIcon from "~/assets/trash-can.svg";
import { useLocationStore } from "../use-store";

const toolbarStyle = createUseStyles({
    toolbarContainer: {
        display: "block",
        flexDirection: "row",
        top: "2rem",
        padding: "2rem",
        flexWrap: "wrap",
    },

    cadButton: {
        width: "4rem",
        height: "4rem",
        padding: "0.25rem",
        margin: "0.5rem",
        borderRadius: "20%",
        textAlign: "center",
    },

});

function ToolbarComponent(props) {

    const classes = toolbarStyle();
    const locationStore = useLocationStore();
    const { handleUpdateCanvasData } = props

    //EVENTS
    const onDragStart = (e, mode) => {
        updateButtonMode(e, mode);
    }

    const onDragEnd = (e) => {

    }

    const updateButtonMode = (e, buttonMode) => {
        locationStore.setButtonMode(e, buttonMode);
        handleUpdateCanvasData(locationStore.getCanvasData);

    }

    const handleClickBtn = (e, mode) => {
        updateButtonMode(e, mode);
    }

    const handleUndraggableBtn = (e) => {
        e.preventDefault();
    }


    return <Container className={classes.toolbarContainer}>
        <Button
            color="secondary"
            outline
            id="add_computer"
            draggable="true"
            onDragStart={(e) => onDragStart(e, "add_computer")}
            onDragEnd={onDragEnd}
            className={classes.cadButton}
        >
            <img src={computerIcon} alt="add computer icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="add_computer_horizontal"
            draggable="true"
            onDragEnd={onDragEnd}
            onDragStart={(e) => onDragStart(e, "add_computer_horizontal")}
            className={classes.cadButton}
        >
            <img
                src={computerHorizontal}
                alt="add computer horizontally icon"
            />
        </Button>

        <Button
            color="secondary"
            outline
            id="add_computer_vertical"
            draggable="true"
            onDragStart={(e) => onDragStart(e, "add_computer_vertical")}
            onDragEnd={onDragEnd}
            className={classes.cadButton}
        >
            <img src={computerVertical} alt="add computer vertically icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="delete_computer"
            onClick={(e) => updateButtonMode(e, "delete_computer")}
            onDragStart={(e) => {
                handleUndraggableBtn(e);
            }}
            className={classes.cadButton}
        >
            <img src={trashIcon} alt="trash can icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="align_horizontal"
            onClick={(e) => handleClickBtn(e, "align_horizontal")}
            onDragStart={(e) => {
                handleUndraggableBtn(e);
            }}
            className={classes.cadButton}
        >
            <img src={alignVerticalIcon} alt="align horizontally icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="align_vertical"
            onClick={(e) => handleClickBtn(e, "align_vertical")}
            onDragStart={(e) => {
                handleUndraggableBtn(e);
            }}
            className={classes.cadButton}
        >
            <img src={alignHorizontalIcon} alt="align vertically icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="move_computer"
            onClick={(e) => handleClickBtn(e, "move_computer")}
            onDragStart={(e) => {
                handleUndraggableBtn(e);
            }}
            className={classes.cadButton}
        >
            <img src={moveIcon} alt="move icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="select_computer"
            onClick={(e) => handleClickBtn(e, "select_computer")}
            onDragStart={(e) => {
                handleUndraggableBtn(e);
            }}
            className={classes.cadButton}
        >
            <img src={selectIcon} alt="select icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="mark_computer"
            onClick={(e) => handleClickBtn(e, "mark_computer")}
            onDragStart={(e) => {
                handleUndraggableBtn(e);
            }}
            className={classes.cadButton}
        >
            <img src={markIcon} alt="offline cloud icon" />
        </Button>

        <Button
            color="secondary"
            outline
            id="computer_info"
            onClick={(e) => handleClickBtn(e, "computer_info")}
            onDragStart={(e) => {
                handleUndraggableBtn(e);
            }}
            className={classes.cadButton}
        >
            <img src={infoIcon} alt="info icon" />
        </Button>
    </Container>;
}


export default observer(ToolbarComponent);