import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import { Else, If, Then, When } from 'react-if';
import { Link, withRouter } from "react-router-dom";
import { Badge, Col, Container, Row } from 'reactstrap';
import Button from "reactstrap/lib/Button";
import Table from "reactstrap/lib/Table";
import LoadingScreen from '~/components/loadingscreen/LoadingScreen';
import AnswerSlot from '~/pages/exam-online/lecturer/exam/detail/answerslot/AnswerSlot';
import ExamNotificationLister from './notification/ExamNotificationLister';
import Resource from './resource/Resource';
import ExamDetailAutoreport from './autoreport/autreport';

const Detail = (props) => {
    const { examOnlineStore } = props;
    const { match } = props;

    //Call computed in examOnline store as data
    const [examDetail, setExamDetail] = useState({})


    //Fetching exam data by id in url paramater
    useEffect(() => {
        async function getExamDetail() {
            await examOnlineStore.examFetch(match.params.id).then((resp) => {
                examOnlineStore.toggleLoadingScreen();
            });
        }

        getExamDetail();

    }, [examOnlineStore, match.params.id]);

    //Refresh the page if data already fetched
    useEffect(() => {
        setExamDetail({ ...examOnlineStore.exam });
    }, [examOnlineStore.exam, setExamDetail])

    return (
        <React.Fragment>

            {examDetail._id !== undefined && !examOnlineStore.isFetchingData ?
                <>
                    <div className="bg-success text-white py-5 mb-3">
                        <Container>
                            <Row className="my-3 align-items-center">
                                <Col>
                                    <h5>{examDetail.lecture.lecture_code}
                                        <When condition={examDetail.online !== 0}><Badge className="ml-1" color="light">ONLINE</Badge></When>
                                        <When condition={examDetail.shift !== 0}><Badge className='ml-1' color="light">SHIFT-{examDetail.shift}</Badge></When>
                                    </h5>
                                    <h2 className="font-weight-bold">{examDetail.lecture.name}</h2>
                                </Col>
                                <Col>
                                    <div className="text-right">
                                        <p className="h3">
                                            <If condition={examDetail?.time_opened && examDetail?.time_left > 0}>
                                                <Then>
                                                    <b><Countdown date={Date.now() + (examDetail.time_left * 1000)} className="d-flex mx-3" autoStart={!!examDetail.time_opened} /></b><br />
                                                    <Badge color="danger">ON-GOING</Badge>
                                                </Then>
                                                <Else>
                                                    <If condition={examDetail?.time_left === 0}>
                                                        <Then>
                                                            <Badge color="light">FINISHED</Badge>
                                                        </Then>
                                                        <Else>
                                                            <Badge color="warning">STANDBY</Badge>
                                                        </Else>
                                                    </If>
                                                </Else>
                                            </If>
                                        </p>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <h6>Tools</h6>
                                {/* <Button className="mr-3" tag={Link} to={"/admin/exam/" + match.params.id + "/participant/signature"}><FontAwesomeIcon icon={faTable} /> Daftar Hadir</Button> */}
                                <Button className="mr-3" tag={Link} to={"/exam-online/lecturer/exam/" + match.params.id + "/screen"}><FontAwesomeIcon icon={faDesktop} /> Layar Proyektor</Button>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="my-4">
                                    <h3>Informasi Ujian <Button color="warning" tag={Link} to={"/exam-online/lecturer/exam/" + match.params.id + "/edit"}>Ubah</Button></h3>
                                    <Row>
                                        <Col>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th>Mata Kuliah</th>
                                                        <td>{examDetail.lecture.name} ({examDetail.lecture.lecture_code})</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Tahun Ajaran</th>
                                                        <td>{examDetail.lecture_period.period_code}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mulai - Berakhir</th>
                                                        <td>{examDetail.time_opened} - {examDetail.time_ended}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Waktu Mulai Pengumpulan</th>
                                                        <td>{examDetail.time_start}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Durasi</th>
                                                        <td>{examDetail.time_duration / 60} Menit</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Uts/Uas/Quiz</th>
                                                        <td>{examDetail.uts ? (examDetail.uts === "1" ? "UTS" : "QUIZ") : "UAS"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th>Collaborator</th>
                                                        <td>
                                                            <ul>
                                                                {examDetail.lecturers ? examDetail.lecturers.map((lecturer, i) => {
                                                                    return <li key={"lecturer-" + i}>{lecturer.display_name}</li>
                                                                }) : <></>}
                                                            </ul>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Jumlah Peserta</th>
                                                        <td>{(examDetail.online_participants || []).length} Orang</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Dibuat pada</th>
                                                        <td>{examDetail.created_on}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Terakhir diperbaharui</th>
                                                        <td>{examDetail.updated_on}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Resource exam={examDetail} />
                            </Col>
                            <Col xs={6}>
                                <AnswerSlot exam={examDetail} />
                            </Col>
                            <Col xs={6}>
                                <ExamNotificationLister exam={examDetail} />
                            </Col>

                            <Col xs={6}>
                                <ExamDetailAutoreport exam={examDetail} />
                            </Col>
                        </Row>
                    </Container>
                </>
                :
                <LoadingScreen />
            }
        </React.Fragment>
    )
}

export default inject("examOnlineStore")(
    withRouter(observer(Detail))
);