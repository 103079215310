import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { withRouter } from "react-router";

import { toJS } from "mobx";
import Nologin from "~/pages/admin/index/Nologin";

const ExamOnline = (props) => {

    const { examOnlineStore, history } = props;
    useEffect(() => {
        examOnlineStore.fetchProfile().then(() => {
            console.log(toJS(examOnlineStore.getUser));
            history.push("/exam-online/" + examOnlineStore.user?.acl?.name);
        }).catch(e => {
            history.push("/exam-online/account/login");
        });
    }, [examOnlineStore, history]);

    return (
        <React.Fragment>
            <Nologin />
        </React.Fragment>
    )
}

export default inject("examOnlineStore")(withRouter(observer(ExamOnline)));