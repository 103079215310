import { faHistory, faStop, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Else, If, Then, When } from 'react-if';
import { useRouteMatch } from 'react-router-dom';
import { Badge, Button, Col, Container, Row } from 'reactstrap';
import { axios } from "~/apicall";
import useStores from '~/components/use-store';

const Screen = (props) => {
  const match = useRouteMatch("/admin/exam-online/exam/:id/screen");
  const { examOnlineStore } = useStores();
  const [exam, setExam] = useState({});

  // eslint-disable-next-line
  const [waitRefreshTime, setWaitRefreshTime] = useState(120);


  function handleStart() {
    axios.post("manageonline/examonline/" + match.params.id + "/start").then((resp) => {

      if (resp.status === 200) {
        setExam({ ...resp.data.data });
      } else {
        return Promise.reject(resp);
      }
    }).catch((err) => {
      alert(err);
    });
  }

  function handleStop() {
    axios.post("manageonline/examonline/" + match.params.id + "/stop").then((resp) => {

      if (resp.status === 200) {
        setExam({ ...resp.data.data });
      } else {
        return Promise.reject(resp);
      }
    }).catch((err) => {
      alert(err);
    });
  }

  function handleReset() {
    axios.post("manageonline/examonline/" + match.params.id + "/reset").then((resp) => {

      if (resp.status === 200) {
        setExam({ ...resp.data.data });
      } else {
        return Promise.reject(resp);
      }
    }).catch((err) => {
      alert(err);
    });
  }


  useEffect(() => {
    let examId = match.params.id;
      examOnlineStore.examFetch(examId).then((resp) => {
        examOnlineStore.toggleLoadingScreen();
        setExam({ ...examOnlineStore.exam });
      });
    

    let timer = setInterval(() => {
      examOnlineStore.examFetch(examId).then((resp) => {
        examOnlineStore.toggleLoadingScreen();
        setExam({ ...examOnlineStore.exam });
      }).catch((err) => {
        alert(err);
      });
    }, waitRefreshTime * 1000);

    return () => {
      clearInterval(timer);
    };

  }, [match]);

  

  

  return (
    <React.Fragment>
      <Container>
        {exam?._id ? (
          <Row style={{ minHeight: "calc(100vh - 64px)" }} className="align-items-center">
            <Col>
              <div>
                <div className="d-flex align-items-center">
                  <h4 className="display-4 flex-grow-1">{parseInt(exam.uts) ? (parseInt(exam.uts) == 1 ? "UTS" : "QUIZ") : "UAS"} {exam.lecture?.lecture_code}
                    <When condition={parseInt(exam.online) !== 0}>
                      {' '}<Badge color={parseInt(exam.uts) === 1 ? (parseInt(exam.uts) === 1 ? "success" : "primary") : "info"}>{parseInt(exam.shift) ? ("S-" + exam.shift) : (parseInt(exam.online) === 1 ? "Online" : "")}</Badge>
                    </When>
                  </h4>
                  <h4 className="font-weight-bold">
                    {exam.time_duration / 60} Menit
                  </h4>
                </div>
                <h1 className="display-3">{exam.lecture.name}</h1>
              </div>
              <div>
                <If condition={!!exam.time_opened && exam.time_left >= 1}>
                  <Then>
                    <p className="display-1 text-center font-weight-bold my-2 py-3" style={{ fontSize: "10rem" }}>
                      <Countdown date={Date.now() + exam.time_left * 1000} onComplete={() => examOnlineStore.examFetch(match.params.id)} autoStart={!!exam.time_opened} />
                    </p>
                  </Then>
                  <Else>
                    <If condition={exam.time_left >= 1}>
                      <Then>
                        <p className="display-2 font-weight-bold text-center bg-primary text-light my-2 py-3">READY</p>
                      </Then>
                      <Else>
                        <p className="display-2 font-weight-bold text-center bg-dark text-light my-2 py-3">FINISH</p>
                      </Else>
                    </If>
                  </Else>
                </If>
              </div>
              <div className="mt-5">
                <p>Control</p>
                <div>
                  <If condition={!exam.time_opened}>
                    <Then>
                      <Button className="mr-2" color="primary" onClick={handleStart}><FontAwesomeIcon icon={faStopwatch} /> Start Timer</Button>
                      <p className="mt-3">
                        Untuk memulai dan membuka tempat pengumpulan, anda dapat menekan <b>Start Timer</b>.
                      </p>
                    </Then>
                    <Else>
                      <If condition={exam.time_left === 0}>
                        <Then>
                          <Button className="mr-2" color="danger" onClick={handleReset}><FontAwesomeIcon icon={faHistory} /> Reset Timer</Button>
                        </Then>
                        <Else>
                          <Button className="mr-2" color="warning" onClick={handleStop}><FontAwesomeIcon icon={faStop} /> Stop Timer</Button>
                          <Button className="mr-2" color="danger" onClick={handleReset}><FontAwesomeIcon icon={faHistory} /> Reset Timer</Button>
                          <p className="mt-3">
                            <b>Penting:</b> Dengan menutup/mereset timer, anda akan menutup tempat pengumpulan peserta ujian.
                          </p>
                        </Else>
                      </If>
                    </Else>
                  </If>
                </div>
              </div>
            </Col>
          </Row>
        ) : ""}
      </Container>
    </React.Fragment>
  )
}

export default Screen