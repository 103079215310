import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { Link, withRouter } from "react-router-dom";
import { Badge, Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Table from "reactstrap/lib/Table";
import { axios } from '~/apicall';
import LoadingScreen from '~/components/loadingscreen/LoadingScreen';

const Exam = (props) => {
    const { examOnlineStore } = props;
    const me = examOnlineStore.getUser;
    const [promptDelete, setPromptDelete] = useState(false);
    const [dataToDelete, setDataToDelete] = useState(undefined)
    const [isDeleting, setIsDeleting] = useState(false)

    useEffect(() => {
        const getExamData = async () => {
            console.log("Fetching examination...");
            examOnlineStore.toggleLoadingScreen(true);
            await examOnlineStore.examFetch().then((resp) => {
                if (resp.status === 200) {
                    examOnlineStore.toggleLoadingScreen(false);
                }
            }).catch((e) => {
                examOnlineStore.toggleLoadingScreen(false);
                console.log(e)
            });

        }
        getExamData();
    }, [examOnlineStore]);


    function handleDeleteRequest(e) {
        let id = dataToDelete._id;
        let endpoint = "manageonline/examonline/" + id;
        let indexPath = "/exam-online/lecturer";
        setIsDeleting(true)

        axios.delete(endpoint).then((resp) => {
            console.log(resp);
            if (resp.status === 200) {
                console.log("Exam online deleted!");
                setIsDeleting(false);
                setPromptDelete(false);
                setDataToDelete(undefined);
                window.location = indexPath;
            }else{
                setIsDeleting(false);
                Promise.reject(resp)
            }
        }).catch((err) => {
            setIsDeleting(false);
            console.log(err)
        })

    }

    function handleClickDelete(exam) {
        setPromptDelete(true);
        setDataToDelete({ ...exam });
    }


    return (
        <React.Fragment>
            <If condition={!examOnlineStore.checkIsFetchingData}>
                <Then>
                    <Container>
                        <Row className="mt-4">
                            <Col>
                                <h1>Ujian</h1>
                            </Col>
                            <Col>
                                <div className="text-right">
                                    {/* <Link to="/exam-online/lecturer/exam/new" className='btn btn-primary' /> */}
                                    <Button color="primary" size="lg" tag={Link} to="/exam-online/lecturer/exam/new">Create New</Button>
                                    {/* </Link> */}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Lecture Info</th>
                                            <th colSpan={2}>Duration (Start-End)</th>
                                            <th>Collaborator</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {examOnlineStore.getExams.length ? examOnlineStore.getExams.map((exam, i) =>
                                            <tr key={i}>
                                                <td>
                                                    <b>{(exam.lecture || {}).name} ({(exam.lecture || {}).lecture_code})</b> <br />
                                                    {(exam.lecture_period || {}).period_code} <Badge color={parseInt(exam.uts) ? (parseInt(exam.uts) === 1 ? "success" : "primary") : "info"}>{parseInt(exam.uts) ? (parseInt(exam.uts) === 1 ? "UTS" : "QUIZ") : "UAS"}</Badge>
                                                    <Badge className="mx-1" color={parseInt(exam.online) ? "warning" : "danger"}> {parseInt(exam.online) ? "ONLINE" : "OFFLINE"} </Badge>
                                                </td>

                                                <td>
                                                    {exam.time_start} - {exam.time_ended}
                                                </td>
                                                <td>
                                                    Durasi: {exam.time_duration / 60} Menit<br />
                                                    Dimulai pada: {exam.time_opened || "(belum di mulai)"}
                                                </td>
                                                <td>
                                                    <ul>
                                                        {exam.lecturers.length ? exam.lecturers.map((data) => {
                                                            if (data.uuid !== me.uuid) {
                                                                return <li key={"exam-" + exam._id + "-" + data.uuid} >{data.display_name}</li>
                                                            }
                                                            return ""
                                                        }) : " "}
                                                    </ul>
                                                </td>
                                                <td className="text-right">
                                                    <Button className="mx-2 d-none d-md-block" color="warning" tag={Link} to={`/exam-online/lecturer/exam/${exam._id}/detail`}>Lihat</Button>

                                                    <Button className="mx-2" color="danger" onClick={(e) => handleClickDelete(exam)}>Hapus</Button>
                                                </td>
                                            </tr>
                                        ) : <tr></tr>}

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Modal isOpen={promptDelete && dataToDelete && dataToDelete?._id !== undefined}>
                            <ModalHeader>
                                Delete Exam {dataToDelete?.lecture?.name}
                            </ModalHeader>
                            <ModalBody>
                                Are you sure you are going to delete Exam <code>{dataToDelete?.lecture?.name}</code> (<code>{dataToDelete?.lecture?.lecture_code}</code>)?
                                <Table>

                                    <tbody>
                                        <tr>
                                            <th>id</th>
                                            <td>{dataToDelete?._id}</td>
                                        </tr>
                                        <tr>
                                            <th>time_start</th>
                                            <td>{dataToDelete?.time_start}</td>
                                        </tr>

                                        <tr>
                                            <th>time_duration</th>
                                            <td>{dataToDelete?.time_duration}</td>
                                        </tr>

                                        <tr>
                                            <th>participants</th>
                                            <td>{dataToDelete?.online_participants?.length || 0}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="secondary" onClick={(e) => {
                                    setPromptDelete(false);
                                    setDataToDelete(undefined);
                                }}>Cancel</Button>
                                <Button onClick={(e) => handleDeleteRequest(e)} color="danger" >Delete {isDeleting ? <Spinner as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : ""}</Button>
                            </ModalFooter>
                        </Modal>
                    </Container >
                </Then>
                <Else>
                    <LoadingScreen />
                </Else>
            </If>

        </React.Fragment>
    )
}

export default inject("examOnlineStore")(
    withRouter(observer(Exam))
);