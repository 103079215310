import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Else, If, Then } from 'react-if';
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Buildinfo from "~/components/buildinfo/Buildinfo";
import ExamNav from '~/components/exam-online/navbar/NavBar';
import NotifModal from "~/pages/exam/index/NotifModal";
import NoExam from "./NoExam";
import Submitter from "./Submitter";
import Upcoming from "./Upcoming";
const ExamOnlineIndex = (props) => {
    const { onlineExamStore } = props;
    const { exam, participant = {} } = onlineExamStore;
    const [notification, setNotification] = useState(undefined);

    const [message, setMessage] = useState(undefined);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const waitRefreshTime = 30;
    
    //If exam exist, check if exam has started
    //If exam has not started, choose Upcoming component
    //If exam has started, choose Submitter component
    let ExamHandler = (!exam?.time_opened) ? Upcoming : Submitter;

    // update document title
    if (!exam) {
        document.title = "Tidak ada ujian berlangsung."
    } else {
        document.title = [participant.username, "-", exam.lecture?.lecture_code, "Ujian"].join(" ");
    }

    useEffect(() => {
        let isMounted = true;

        if (!onlineExamStore.isFetchingData) {
            onlineExamStore.fetchExamInfo(false).catch((err) => {
                console.log("Catched:", err);
                handleErrSetMessage(null, err);
            });
    
        }
      
        let timer = setInterval(() => {
            if (!onlineExamStore.isFetchingData) {
                onlineExamStore.fetchExamInfo(false).catch((err) => {
                    console.log("Catched:", err);
                    handleErrSetMessage(null, err);
                });
            }
        }, waitRefreshTime * 1000);
  
        return () => {
            isMounted = false;
            clearInterval(timer);
        };
    }, [onlineExamStore, exam]);


    function handleErrSetMessage(e, message) {
        setMessage(message);
        setMessageModalOpen(true);

        setTimeout(() => {
            setMessage(undefined);
            setMessageModalOpen(false);
        }, 10000);
    }

    return (
        <React.Fragment>
            <NotifModal notification={notification} onCloseRequested={() => setNotification(undefined)} />
            <div className="bg-light h-100 ">
                <Container>
                    <Row className="h-100vh align-items-center justify-content-center">
                        <Col>
                            <Card className="overflow-hidden">
                                <If condition={!exam}>
                                    <Then>
                                        <NoExam />
                                    </Then>
                                    <Else>
                                        <ExamNav type={!exam?.time_opened ? "upcoming" : "inprogress"} participant={participant} onNotifShowRequested={(e) => setNotification(e)} history={props.history} />
                                        <CardBody>
                                            <ExamHandler
                                                examInfo={exam} {...props} setErrorModal={handleErrSetMessage} />
                                        </CardBody>
                                    </Else>
                                </If>
                            </Card>
                            <p className="text-center text-secondary">
                                <small><Buildinfo /></small>
                            </p>
                        </Col>
                    </Row>
                    <Modal isOpen={messageModalOpen}>
                        <ModalHeader className="bg-info text-white" >Pesan</ModalHeader>
                        <ModalBody>
                            <p>{message}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={(e) => { setMessage(undefined); setMessageModalOpen(false); }}>
                                Tutup
                            </Button>{' '}
                        </ModalFooter>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default inject('onlineExamStore')(observer(ExamOnlineIndex));

