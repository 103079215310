import React, { useState } from 'react';
import { When } from 'react-if';
import Stepper from "react-stepper-horizontal";
import { Col, Container, Row } from 'reactstrap';
import ErrorModal from '~/components/globals/ErrorModal';
import Step1 from './step-1';
import Step2 from './step-2';
import Step3 from './step-3';
import Step4 from './step-4';


let steps = [
    { title: "Exam Details" },
    { title: "Seat Plotting" },
    { title: "Confirmation" },
    { title: "Finish" },
];
function ExamCreate() {
    const [examDetails, setExamDetails] = useState({});
    const [currentStep, setCurrentStep] = useState(0);


    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [errorTitle, setErrorTitle] = useState("Terjadi Kesalahan");
    const [errorType, setErrorType] = useState("danger")


    function handleStep1Confirmation(data) {
        setExamDetails({ ...examDetails, ...data });
        setCurrentStep(currentStep + 1);
    }
    function handleStep2Confirmation(computer, location) {
        setExamDetails({ ...examDetails, ...{ computers: computer }, ...{ location: location } });
        setCurrentStep(currentStep + 1);
    }

    function handleStep3Confirmation(data) {
        setExamDetails({ ...examDetails, ...{ exam: data } });
        setCurrentStep(currentStep + 1);
    }

    function handleError(type, status, title = "Terjadi Kesalahan", message) {
        setErrorTitle(title)
        setErrorType(type);
        setErrorMessage(message);
        setHasError(status);
    }


    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <Col xs="12">
                            <h2 className="my-3">Examination Plotter</h2>

                            <Stepper
                                steps={steps.map((step, i) => ({ ...step, onClick: (e) => setCurrentStep(i) }))}
                                activeStep={currentStep}
                                activeColor="var(--primary)"
                                completeBarColor="var(--info)"
                                completeColor="var(--info)"
                            />
                        </Col>
                    </Row>
                    <ErrorModal key={1} errorType={errorType} hasError={hasError} errorTitle={errorTitle} errorMessage={errorMessage} handleCloseButton={() => {
                        setErrorType("danger");
                        setErrorMessage("");
                        setHasError(false);
                    }} />
                    <Row>
                        <Col>
                            <When condition={currentStep === 0}><Step1 onRequestNext={handleStep1Confirmation} examDetails={examDetails} handleError={handleError} /></When>
                            <When condition={currentStep === 1}><Step2 onRequestNext={handleStep2Confirmation} examDetails={examDetails} handleError={handleError} /></When>
                            <When condition={currentStep === 2}><Step3 onRequestNext={handleStep3Confirmation} examDetails={examDetails} handleError={handleError} /></When>
                            <When condition={currentStep === 3}><Step4 examDetails={examDetails} /></When>
                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    )
}

export default ExamCreate
