import { faMeh } from "@fortawesome/free-regular-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Badge, Col, Container, Row } from "reactstrap";

const Forbidden = () => {
    return (
        <React.Fragment>
            <Container className="vh-100 text-center">
                <Row className="my-4">
                    <Link to="/exam-online" className="row" style={{ textDecoration: "none" }} title="Pulang yok, pulang!">
                        <FontAwesomeIcon icon={faChevronLeft} size="1x" className=" mt-1 mx-1 text-muted" />
                        <span className="text-info"> Mulak ma hita (Back home)</span>
                    </Link>
                </Row>
                <Row className="w-100 h-100 align-items-center">
                    <Col className="w-100 ">
                        <Row className="w-100 ">
                            <Col>
                                <Badge color="warning" className=""><h1 className="display-1">403</h1></Badge>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faMeh} size="4x" className="mt-5 p-1 text-muted" />
                            </Col>
                        </Row>
                        <Row className="w-100 text-center mt-5 p-0">
                            <Col>
                                <h2>
                                    <Badge color="warning"> FORBIDDEN! </Badge>
                                    This is not beauty privilege. You just don't have the privilege to access this page!
                                </h2>
                                <h5><strong>(actually, previous page)</strong></h5>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Forbidden