
import { Component } from "react";
import { Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

class InfoModal extends Component {
  constructor(props) {
    super(props);
    let { data } = this.props;
    let isEmpty = false;
    if (!data) {
      data = {
        id: "",
        number: 1,
        prevNumber: 1,
        ip_address: "10.100.7x.2xx",
        reverse_dns: "Lab0x-xx",
        status: 1,
        priority: 1,
        x: 100,
        y: 100,
        width: 40,
        height: 40,
      };
      isEmpty = true;
    }

    this.state = {
      ...data,
      prevNumber: data.number,
      isEmpty: isEmpty,
    };
  }

  handleOkClick = (e) => {
    let state = this.state;
    this.props.handleDismiss(e, { ...state });
  };

  handleOnChange = (e) => {

    let target = e.target;
    let id = target.id.split("input-")[1];
    let value = target.value;
    let newState = {};
    newState[id] = value;

    if (id === "pos_x" || id === "pos_y" || id === "priority") {
      newState[id] = Math.floor(parseInt(value));
    }

    let prevNumber = this.state.prevNumber;

    this.setState({
      ...newState,
      prevNumber,
    });
  };

  render() {
    let {
      _id,
      ip,
      name,
      reverse_dns,
      state,
      priority,
      pos_x,
      pos_y,
      isEmpty
    } = this.state;

    return (
      // <Group id="group-id-1" key={"info-modal-" + _id}>
      <Modal
        isOpen={!isEmpty}
      >
        <ModalHeader>
          Computer data: {name}
        </ModalHeader>
        <ModalBody className="container p-2 border container-justify-center">
          <Col>
            <Container className="row align-self-center ">
              <Label htmlFor="input-number">_id</Label>
              <Input
                id="input-number"
                value={_id}
                disabled
                onChange={this.handleOnChange}
              />
            </Container>
            <Container className="row">
              <Label htmlFor="input-name">name</Label>
              <Input
                id="input-name"
                placeholder="Generated Computer Name"
                disabled
                value={reverse_dns}
                onChange={this.handleOnChange}
              />
            </Container>
            <Container className="row">
              <Label htmlFor="input-ip">IP Address</Label>
              <Input
                id="input-ip"
                placeholder="Generated IP Address"
                disabled
                value={ip}
                onChange={this.handleOnChange}
              />
            </Container>
            <Container className="row">
              <Label htmlFor="input-priority">priority</Label>
              <Input
                id="input-priority"
                placeholder="Priority value"
                value={priority}
                onChange={this.handleOnChange}
                disabled={!this.props.allowEdit}
                type="number"
              />
            </Container>
            <Container className="row">
              <Label htmlFor="input-state">state</Label>
              <Input type="select" id="input-state" onChange={this.handleOnChange} value={state} disabled={!this.props.allowEdit}>
                <option value={1}>
                  Available
                </option>
                <option value={0}>
                  Marked Not Available
                </option>
              </Input>
            </Container>

            <Container className="row">
              <Label htmlFor="input-pos_x">pos_x</Label>
              <Input
                id="input-pos_x"
                placeholder="Position x value"
                value={pos_x}
                type="number"
                onChange={this.handleOnChange}
                disabled={!this.props.allowEdit}
              />
              <Label htmlFor="input-pos_y">pos_y</Label>
              <Input
                id="input-pos_y"
                placeholder="Position y value"
                value={pos_y}
                type="number"
                onChange={this.handleOnChange}
                disabled={!this.props.allowEdit}
              />
            </Container>

          </Col>
        </ModalBody>
        <ModalFooter>
          <Container className=" text-center mt-2">
            <Button className="btn btn-md mt-1 " onClick={this.handleOkClick}>
              Ok
            </Button>
          </Container>
        </ModalFooter>
      </Modal >

    );
  }
}

export default InfoModal;
