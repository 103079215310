import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileDownload from 'js-file-download';
import React, { useState } from "react";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { axios } from '~/apicall';
import { useExamOnlineStore } from '~/components/use-store';
import ResourceItem from './ResourceItem';

const Resource = ({ exam }) => {
    const examOnlineStore = useExamOnlineStore();
    const [showAddResourceModal, setShowAddResourceModal] = useState(false);
    const [dataToDelete, setDataToDelete] = useState(undefined);
    const [dataToAdd, setDataToAdd] = useState(undefined);

    function handleAddResource(e) {
        let files = Array.prototype.slice.call(e.target.files);
        if (files && files[0]) {
            console.log(files)
            if (files[0].size > 20 * 1024 * 1024) {
                alert("Ukuran berkas melebihi batas unggah! Ukuran maksimal berkas yang dapat diunggah adalah 20MB.")
            } else {
                setDataToAdd(files[0]);
            }
        } else {
            console.log("No files selected!");
        }
    }

    function handleUploadAddedResource(e) {
        if (dataToAdd && exam?._id) {
            let file = dataToAdd;
            let examID = exam._id;
            let fileName = file.name.replace(/[-\s/()\\]+/g, "");
            const formData = new FormData();
            formData.append("file", file, fileName);

            return axios.post("manageonline/examonline/" + examID + "/populate_resource",
                formData, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
                timeout: 30000,
            }).then((resp) => {
                setDataToAdd(undefined);
                setShowAddResourceModal(false);

                refreshExamReport();

                return Promise.resolve(resp);
            }).catch((resp) => {
                return Promise.reject(resp);
            });
        }
    }

    function handleUnsetAddResource(e) {
        setDataToAdd(undefined);
        setShowAddResourceModal(false);
    }

    /**
     * Handler for resource download on download button click
     * @param {*} e event
     */
    function handleResourceDownload(e, resource) {
        if (resource) {

            //Axios is so shitty that it cannot handle binary stream.
            //It will encode response to UTF-8, even if you specify the responseType : blob, or arrayBuffer
            //Shitty library.
            //That is way I have to revert back into using fetch
            fetch("/api/v1/manageonline/resourceonline/" + resource._id + "/download", {
                method: "POST",
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('auth-token')
                }
            }).then(resp => resp.blob()).then((resp) => {
                fileDownload(resp, resource.upload_name);
            })
        }
    }

    /**
     * Handling delete request
     */
    function handleDeleteResource(e) {
        e.preventDefault();
        axios.delete("manageonline/resourceonline/" + dataToDelete._id).then((d) => {
            setDataToDelete(undefined);
            refreshExamReport();
        })
    }


    function refreshExamReport() {
        examOnlineStore.examFetch(exam._id).then((resp) => {
            examOnlineStore.toggleLoadingScreen(false);
        }).catch((resp) => {
            alert(resp.data.data.error)
        });

    }


    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col>
                        <h3>Berkas Ujian</h3>
                    </Col>
                    <Col>
                        <div className="text-right">
                            <Button className="mr-2" color="primary" onClick={() => setShowAddResourceModal(true)} > <FontAwesomeIcon icon={faPlus} /></Button>

                        </div>
                    </Col>
                </Row>
                <Table>
                    <tbody>
                        {exam.resource?.length ? (exam.resource.map((res) => {
                            return <ResourceItem key={res._id + "-" + res.upload_name} resource={res} onDeleteRequest={setDataToDelete} onDowloadRequest={handleResourceDownload} />
                        })) : <></>}
                    </tbody>
                </Table>

                <Modal isOpen={!!showAddResourceModal} backdrop>
                    <ModalHeader>Unggah Berkas Ujian Tambahan</ModalHeader>
                    <ModalBody>
                        <Input type="file" onChange={handleAddResource} />
                        {
                            dataToAdd ?
                                <p>Anda akan menambahkan berkas: <code>{(dataToAdd || {}).name}</code></p>
                                :
                                <></>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleUnsetAddResource}>Batalkan</Button>
                        <Button color="primary" onClick={handleUploadAddedResource}>Tambahkan</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={!!dataToDelete} backdrop>
                    <ModalHeader>Konfirmasi penghapusan</ModalHeader>
                    <ModalBody>
                        Apakah anda yakin ingin menghapus berkas untuk <code>{(dataToDelete || {}).upload_name}</code>?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setDataToDelete(undefined)}>Batalkan</Button>
                        <Button color="danger" onClick={handleDeleteResource}>Hapus</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Resource