import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { Button } from "reactstrap";

const AnswerSlotItem = ({ answer = {}, onDeleteRequest = {}, onEditRequest = {} }) => {
    return (
        <React.Fragment>
            <tr>
                <td>
                    <p className="font-monospace font-weight-bold">
                        {answer.format}
                    </p>
                </td>
                <td style={{ textAlign: "right" }}>
                    <Button id={"answer-" + answer._id}  className="ml-2" color='success' onClick={(e) => onEditRequest( answer)}> <FontAwesomeIcon icon={faPen} />  </Button>
                    <Button id={"delete-answer-" + answer._id}  className="ml-2" color='danger' onClick={(e) => onDeleteRequest(answer)}> <FontAwesomeIcon icon={faTrash} />  </Button>
                </td>

            </tr>
        </React.Fragment>
    )
}

export default AnswerSlotItem