import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Container } from "reactstrap"

const ErrorModal = ({ errorType="danger", hasError = false, errorTitle="Terdapat Kesalahan!", errorMessage = "", handleCloseButton = () => { } }) => {
    return (
        <React.Fragment>
            <Container >
                <Modal isOpen={hasError} backdrop>
                    <ModalHeader className={`bg-${errorType} text-${errorType === "warning" ? "black" : "white"}`}>{errorTitle}</ModalHeader>
                    <ModalBody>{errorMessage}</ModalBody>
                    <ModalFooter>
                        <Button onClick={(e) => handleCloseButton()}>Tutup</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </React.Fragment>
    )
}

export default ErrorModal