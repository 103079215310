import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Konva from "konva";
import { inject, observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { axios } from "~/apicall";
import computerIcon from "~/assets/computer-svg.svg";
import StageComponent from "~/components/location/StageComponent";
import Toolbar from "~/components/location/ToolbarComponent";
import { useLocationStore } from '~/components/use-store';

function Edit({ match }) {


    //Store
    const locationStore = useLocationStore();

    //States
    const [items, setItems] = useState([]);
    const [dragDropData, setDragDropData] = useState(null);
    const [canvasData, setCanvasData] = useState({});
    const [infoModal, setInfoModal] = useState(null)
    const [init, setInit] = useState(false);
    const [locationData, setLocationData] = useState({});
    const [deletedComputer, setDeletedComputer] = useState([])
    const [isSaving, setIsSaving] = useState(false);

    const [modalAddVertical, setModalAddVertical] = useState(false);
    const [computerToAdd, setComputerToAdd] = useState({ total: 0, startX: 0, startY: 0 });

    //Refs
    const stageRef = useRef(null);
    const layerRef = useRef(null);
    const transfomerRef = useRef(null);

    //data for selection rect ref
    const defaultSelectRectData = { visible: false, x1: 0, x2: 0, y1: 0, y2: 0 };
    const selectionRectDataRef = useRef({ ...defaultSelectRectData });
    const selectionRectRef = useRef(null);

    //Contants
    const TRANSFORMER_PADDING = 10;
    const COMPUTER_SPACING = 20;
    const GRID_PADDING = 20;
    const COMPUTER_WIDTH = 40;
    const COMPUTER_HEIGHT = 40;


    useEffect(() => {
        if (!init) {
            locationStore.locationFetch(match.params.id).then(() => {
                let finalizedFields = { ...locationStore.getSelectedLocation[match.params.id] };

                let computers = []

                if (finalizedFields.computers && finalizedFields.computers.length) {
                    computers = finalizedFields.computers.map((item) => {
                        let ip = item.ip;
                        let subnet = finalizedFields.subnet_start;

                        let number = ip.split(".")[3];
                        let subnetNumber = subnet.split(".")[3];

                        item.number = (number - subnetNumber);
                        return item;
                    })
                }

                setLocationData((prev) => { return { ...prev, ...finalizedFields, computers: computers } });
                // if (stageRef && stageRef.current) {
                //     stageRef.current.clear();
                // }
            });

            setInit(true);
        }

    }, [locationStore, match.params.id, init]);

    useEffect(() => {
        setCanvasData(locationStore.getCanvasData);

    }, [locationStore]);

    /**
     * This use effect will update stage based on selected computer
     * If selection made, then transformer will have nodes of groups
     * Clicking at button In the Toolbar component will applying changes for that button
     * to the groups
     */
    useEffect(() => {
        if (canvasData && canvasData.buttonMode === "delete_computer") {
            if (transfomerRef.current.nodes().length) {

                //Get all group nodes in transformer
                const nodes = transfomerRef.current.nodes();

                //Filter locationData and get data of computers that going to be deleted
                let toBeDeleted = locationData.computers.filter(a => nodes.some((b) => {
                    //Get id of the group: group-computer-{:number}
                    let id = parseInt(b.id().split("group-computer-")[1]);
                    //check if a ip is equal to ip generated from number in group id
                    return a.ip.localeCompare(convertNumberToIP(locationData.subnet_start, id)) === 0;
                }))

                //Update new locationData by filtering out data that's going to be  deleted
                let newLocationData = locationData.computers.filter(a => !toBeDeleted.some((b) => a.ip.localeCompare(b.ip) === 0))
                setLocationData({ ...locationData, computers: newLocationData });

                //Update index of computerDelete
                let deletedComputerNumber = toBeDeleted.map(a => a.number)
                //Append numbers from state with the just deleted computer
                let updatedDeletedComputerNumber = [...deletedComputer, ...deletedComputerNumber];

                //Sorting the number largest to smallest
                updatedDeletedComputerNumber.sort((a, b) => b - a);

                console.log(updatedDeletedComputerNumber)

                setDeletedComputer([...updatedDeletedComputerNumber])

                //update transformer nodes
                attachToTransformer(null, []);
            }
        } else if (canvasData && canvasData.buttonMode === "mark_computer") {
            if (transfomerRef.current.nodes().length) {

                //Get all group nodes in transformer
                const nodes = transfomerRef.current.nodes();

                //Filter locationData and get data of computers that going to be deleted
                let toBeMarked = locationData.computers.filter(a => nodes.some((b) => {
                    //Get id of the group: group-computer-{:number}
                    let id = parseInt(b.id().split("group-computer-")[1]);
                    //check if a ip is equal to ip generated from number in group id
                    return a.ip.localeCompare(convertNumberToIP(locationData.subnet_start, id)) === 0;
                }))

                let computers = [...locationData.computers];
                computers.forEach((computer) => {
                    let arrIsIncluded = toBeMarked.filter((item) => computer.ip.localeCompare(item.ip) === 0)
                    if (arrIsIncluded.length) {
                        computer.state = parseInt(computer.state) ? 0 : 1;
                    }
                })

                setLocationData({ ...locationData, computers: computers });

                transfomerRef.current.nodes([]);
            }
        } else if (canvasData && canvasData.buttonMode === "align_horizontal") {
            if (transfomerRef.current.nodes().length) {

                //Get all group nodes in transformer
                const nodes = transfomerRef.current.nodes();

                //Filter locationData and get data of computers that going to be deleted
                let toBeAligned = locationData.computers.filter(a => nodes.some((b) => {
                    //Get id of the group: group-computer-{:number}
                    let id = parseInt(b.id().split("group-computer-")[1]);
                    //check if a ip is equal to ip generated from number in group id
                    return a.ip.localeCompare(convertNumberToIP(locationData.subnet_start, id)) === 0;
                }))

                const sumY = toBeAligned.reduce((total, obj) => { return total + obj.pos_y }, 0)
                const meanSumY = Math.floor(sumY / toBeAligned.length)

                let computers = [...locationData.computers];
                computers.forEach((computer) => {
                    let arrIsIncluded = toBeAligned.filter((item) => computer.ip.localeCompare(item.ip) === 0)
                    if (arrIsIncluded.length) {
                        computer.pos_y = meanSumY;
                    }
                })

                setLocationData({ ...locationData, computers: computers });
            }
        } else if (canvasData && canvasData.buttonMode === "align_vertical") {
            if (transfomerRef.current.nodes().length) {
                //Get all group nodes in transformer
                const nodes = transfomerRef.current.nodes();

                //Filter locationData and get data of computers that going to be deleted
                let toBeAligned = locationData.computers.filter(a => nodes.some((b) => {
                    //Get id of the group: group-computer-{:number}
                    let id = parseInt(b.id().split("group-computer-")[1]);
                    //check if a ip is equal to ip generated from number in group id
                    return a.ip.localeCompare(convertNumberToIP(locationData.subnet_start, id)) === 0;
                }))

                const sumX = toBeAligned.reduce((total, obj) => { return total + obj.pos_x }, 0)
                const meanSumX = Math.floor(sumX / toBeAligned.length)

                let computers = [...locationData.computers];
                computers.forEach((computer) => {
                    let arrIsIncluded = toBeAligned.filter((item) => computer.ip.localeCompare(item.ip) === 0)
                    if (arrIsIncluded.length) {
                        computer.pos_x = meanSumX;
                    }
                })

                setLocationData((prevLocData) => { return { ...prevLocData, computers: computers } });
            }
        }
    }, [canvasData, locationData, deletedComputer]);

    useEffect(() => {
        //update InfoModal in canvasData change
        setInfoModal(canvasData.infoModal);
    }, [canvasData])



    /*
     * STATE DATA MUTATION SECTION
     *
     */

    const generateComputerData = (id = "",
        priority = -1, state = 1,
        pos_x = 0, pos_y = 0) => {

        let number = undefined;

        if (dragDropData) {
            number = dragDropData.number
        } else {
            number = locationData.computers?.length ? locationData.computers.length + 1 : 1;
            if (deletedComputer.length) {
                number = deletedComputer.pop();
                console.log("Counter:", number)
                console.log("Curr deleted comp:", deletedComputer)
                setDeletedComputer([...deletedComputer]);
            }
        }

        let subnet_start = locationData.subnet_start;
        let ip_addr = convertNumberToIP(subnet_start, number) + "";
        let reverse_dns = convertNumberTODNS(locationData.name_alias, number);
        let name = convertToProperName(locationData.name_alias, number);
        const item = {
            id: id,
            number: number,
            reverse_dns: reverse_dns,
            name: name,
            ip: ip_addr,
            priority: priority,
            state: state,

            pos_x: pos_x,
            pos_y: pos_y,
            width: COMPUTER_WIDTH,
            height: COMPUTER_HEIGHT,
            src: computerIcon,
        };
        return item;
    }

    const addComputer = (prevLocationState, computerDetails) => {
        if (prevLocationState && computerDetails) {
            if (computerDetails.number) {
                let newComputer = {
                    id: "",
                    number: computerDetails.number,
                    ip: computerDetails.ip,
                    reverse_dns: computerDetails.reverse_dns,
                    name: computerDetails.name,
                    state: computerDetails.state,
                    priority: computerDetails.priority,
                    pos_x: computerDetails.pos_x,
                    pos_y: computerDetails.pos_y,
                    width: computerDetails.width,
                    height: computerDetails.height,
                    created_at: null,
                    updated_at: null,
                    deleted_at: null,
                };

                prevLocationState.computers.push(newComputer);

                prevLocationState.computer_count = prevLocationState.computer_count + 1;

                return prevLocationState;
            }
        }
    };

    function addMultipleComputer(e) {
        if (modalAddVertical && computerToAdd) {
            if (canvasData && canvasData.buttonMode === "add_computer_horizontal") {
                let newLocationData = { ...locationData };
                for (let i = 0; i < computerToAdd.total; i++) {
                    let pos_x = computerToAdd.startX + (i * (COMPUTER_WIDTH * 2)) + (COMPUTER_SPACING * i);
                    let pos_y = computerToAdd.startY;

                    let computer = generateComputerData("", 1, 1, pos_x, pos_y);

                    newLocationData = addComputer(newLocationData, computer);


                    // setDragDropData(computer)
                }
                console.log(newLocationData)
                setLocationData(newLocationData)
                setItems((prev) => [...newLocationData.computers])
                console.log(items)
                //Close add computer modal
                setModalAddVertical(false);

                // //Cheap way to refresh the canvas (shouldn't be done this way)
                // setDragDropData(null);

                //Redraw items in canvas
                stageRef.current.clear();

            } else if (canvasData && canvasData.buttonMode === "add_computer_vertical") {
                console.log("Adding vertically: ", computerToAdd)
                let newLocationData = { ...locationData };
                for (let i = 0; i < computerToAdd.total; i++) {
                    let pos_y = computerToAdd.startY + (i * (COMPUTER_WIDTH * 2)) + (COMPUTER_SPACING * i);
                    let pos_x = computerToAdd.startX;

                    let computer = generateComputerData("", 1, 1, pos_x, pos_y);

                    newLocationData = addComputer(newLocationData, computer);

                }

                setLocationData(newLocationData)
                setItems([...newLocationData.computers])

                //Close add computer modal
                setModalAddVertical(false);

                //Redraw items in canvas
                stageRef.current.clear();
            }
            setComputerToAdd({ total: 0, startX: 0, startY: 0 })
        }
    }

    const convertNumberToIP = (subnetStart, number) => {
        if (subnetStart && number) {
            let octets = subnetStart.split(".");
            if (octets.length === 4) {
                let newNumber = Number(octets[3]) + Number(number);
                octets[3] = newNumber;
                let result = octets.join(".");
                // console.log(result);
                return result;
            }
        }
    };

    const convertNumberTODNS = (alias_name, number,) => {
        let dns_reverse = convertToProperName(alias_name, number) + ".ftis.unpar";
        return dns_reverse;
    };

    const convertToProperName = (alias_name, number) => {
        let name = alias_name + "-" + (number >= 10 ? number : "0" + number);
        return name;
    }

    /*
     *EVENT HANDLER SECTION
     *
     */

    const onClick = (e) => {
        handleInfoModalDismiss(e, null);
        if (transfomerRef.current.nodes().length > 0) {
            attachToTransformer(e, [])
        }
    }

    const onDragStart = (e) => {
        if (canvasData && canvasData.buttonMode === "select_computer") {
            if (e.target === stageRef.current) {
                e.evt.preventDefault();

                //empty nodes from transformer
                attachToTransformer(e, []);

                let currStage = stageRef.current;
                let pos = currStage.getStage().getPointerPosition();

                selectionRectDataRef.current.visible = true;
                selectionRectDataRef.current.x1 = pos.x;
                selectionRectDataRef.current.y1 = pos.y;
                selectionRectDataRef.current.x2 = pos.x;
                selectionRectDataRef.current.y2 = pos.y;

                updateSelectionRect();
            }
        }
    };

    const onMouseMove = (e) => {
        if (canvasData && canvasData.buttonMode === "select_computer") {
            // if (e.target === stageRef.current) {
            if (!selectionRectDataRef.current.visible) {
                return
            }

            const position = e.target.getStage().getPointerPosition();

            selectionRectDataRef.current.x2 = position.x;
            selectionRectDataRef.current.y2 = position.y

            updateSelectionRect();
            // }
        }
    }

    function onMouseUp(e) {
        if (canvasData && canvasData.buttonMode === "select_computer") {
            // if (e.target === stageRef.current) {
            if (!selectionRectDataRef.current.visible) {
                return
            }

            const selectionBox = selectionRectRef.current.getClientRect();

            const elements = [];

            // console.log(layerRef.current.find("Group"))

            layerRef.current.find("Group").forEach((elementNode) => {

                const elBox = elementNode.getClientRect();
                if (Konva.Util.haveIntersection(selectionBox, elBox)) {
                    elements.push(elementNode)
                }
            })


            selectionRectDataRef.current.visible = false;

            // Konva.listenClickTap = false;
            updateSelectionRect();
            attachToTransformer(e, elements);
            // }
        }
    }

    function updateSelectionRect() {
        const node = selectionRectRef.current;
        node.setAttrs({
            visible: selectionRectDataRef.current.visible,
            x: Math.min(selectionRectDataRef.current.x1, selectionRectDataRef.current.x2),
            y: Math.min(selectionRectDataRef.current.y1, selectionRectDataRef.current.y2),
            width: Math.abs(selectionRectDataRef.current.x1 - selectionRectDataRef.current.x2),
            height: Math.abs(selectionRectDataRef.current.y1 - selectionRectDataRef.current.y2),
        });
        node.getLayer().batchDraw();
    }


    /**
     * Handle drag over canvas
     * @param {*} e 
     */
    function onDragOver(e) {
        e.preventDefault();

        if (canvasData && canvasData.buttonMode === "add_computer") {

            const stage = stageRef.current;
            stage.setPointersPositions(e);
            const scale = stage.scaleX();
            const position = stage.getPointerPosition();


            const item = generateComputerData("", 1, 1,
                Math.round((position.x - stage.x()) / scale),
                Math.round((position.y - stage.y()) / scale)
            );

            setDragDropData(item);

        } else {
            console.log("Dragging event from stage")
        }
    }

    function onDragLeave(e) {
        e.preventDefault();
        setDragDropData(null);
    }

    function onDrop(e) {
        e.preventDefault();
        console.log("Dragged data", dragDropData)
        if (canvasData && canvasData.buttonMode === "add_computer") {

            let newState = addComputer({ ...locationData }, dragDropData);
            setLocationData(newState);
            setItems([...items, dragDropData]);
            setDragDropData(null);
        } else if (canvasData && (canvasData.buttonMode === "add_computer_horizontal" || canvasData.buttonMode === "add_computer_vertical")) {
            let stage = stageRef.current;
            stage.setPointersPositions(e);
            const scale = stage.scaleX();
            const position = stage.getPointerPosition();
            setComputerToAdd({
                ...computerToAdd,
                startX: Math.round((position.x - stage.x()) / scale),
                startY: Math.round((position.y - stage.y()) / scale)
            });
            setModalAddVertical(true);
            // stage.clear();
        }
    }

    function handleClickOnComputer(e, computerDetails) {
        // e.evt.stopPropagation();
        if (canvasData && canvasData.buttonMode === "computer_info") {
            // e.preventDefault();
            console.log("Clicked:", computerDetails);
            let infoModalData = { ...computerDetails };
            locationStore.setInfoModalData(infoModalData)
            attachToTransformer(e, [e.target.parent])
            setInfoModal(infoModalData)
        } else if (canvasData && canvasData.buttonMode === "delete_computer") {
            handleDeleteComputer(e, computerDetails);
        } else if (canvasData && canvasData.buttonMode === "mark_computer") {
            handleMarkComputer(e, computerDetails);
        } else {
            //Attach group (parent) to transformer
            console.log(e.target.parent)
            attachToTransformer(e, [e.target.parent])
        }
    }

    function handleInfoModalDismiss(e, computerDetails) {

        // console.log(computerDetails)
        setCanvasData({ ...canvasData, infoModal: null });

        let newComputerData = locationData.computers.map((obj) => {
            if (obj && obj.number && obj.number === computerDetails.prevNumber) {
                let subnet_start = locationData.subnet_start;
                let ip_address =
                    convertNumberToIP(subnet_start, computerDetails.number) + "";

                // console.log("Locaton Data:", locationData)
                let reverse_dns = convertNumberTODNS(
                    locationData.name_alias,
                    computerDetails.number
                );

                return {
                    id: "",
                    number: parseInt(computerDetails.number),
                    ip: ip_address,
                    name: convertToProperName(locationData.name_alias,
                        computerDetails.number),
                    reverse_dns: reverse_dns,
                    state: parseInt(computerDetails.state),
                    priority: parseInt(computerDetails.priority),
                    pos_x: computerDetails.pos_x,
                    pos_y: computerDetails.pos_y,
                    width: computerDetails.width,
                    height: computerDetails.height,
                    created_at: computerDetails.created_at,
                    updated_at: computerDetails.updated_at,
                    deleted_at: computerDetails.deleted_at,
                };
            }

            return { ...obj };
        });

        // console.log("New computer data")
        // console.log(newComputerData);

        let newLocationData = { ...locationData };
        newLocationData.computers = [...newComputerData];
        // console.log(newLocationData);
        setLocationData(newLocationData);

        let newItemsData = newComputerData.map(computer => {
            return { ...computer, src: computerIcon }
        })

        setItems(newItemsData)
        locationStore.setInfoModalData(null)
        stageRef.current.clear();
    }

    function handleDeleteComputer(e, computerDetails) {

        let newComputerData = locationData.computers.filter((computer) => { return !(computer.ip.localeCompare(computerDetails.ip) === 0) });

        setLocationData({ ...locationData, computers: newComputerData, computer_count: newComputerData.length });


        let newItemsData = newComputerData.map(computer => {
            return { ...computer, src: computerIcon }
        })

        //Update delete counter id
        let newDeletedComputer = [...deletedComputer, computerDetails.number];

        setDeletedComputer(newDeletedComputer);

        setItems(newItemsData)
        stageRef.current.clear();
    }

    function handleMarkComputer(e, computerDetails) {
        let newComputerData = locationData.computers.map((computer) => {
            if (computer.number === computerDetails.number) {
                computer.state = (computer.state === 1 ? 0 : 1);
            }
            return computer
        });

        setLocationData({ ...locationData, computers: newComputerData, computer_count: newComputerData.length });

        let newItemsData = newComputerData.map(computer => {
            return { ...computer, src: computerIcon }
        })

        setItems(newItemsData)
        stageRef.current.clear();

    }

    function onDragStartComputer(e, computerDetails) {
        if (transfomerRef.current.nodes().length > 1) {
            console.log(e)
        } else {
            attachToTransformer(e, [e.target])
        }


    }


    function onDragEndComputer(e, computerDetails, groupRef) {
        // if (canvasData && canvasData.buttonMode == "select_computer") {
        // if (transfomerRef.current.nodes().length > 1) {

        // } else if (transfomerRef.current.nodes().length === 1) {
        let newComputerData = locationData.computers.map((computer, index) => {
            if (computer.number === computerDetails.number) {
                let groupAbsPos = e.target.absolutePosition();
                computer.pos_x = Math.floor(computerDetails.pos_x + groupAbsPos.x) - (Math.floor(computerDetails.pos_x + groupAbsPos.x) % GRID_PADDING);
                computer.pos_y = Math.floor(computerDetails.pos_y + groupAbsPos.y) - (Math.floor(computerDetails.pos_y + groupAbsPos.y) % GRID_PADDING);

            }
            return computer
        });

        //Update group position to zero to prevent jumping
        groupRef.current.x(0)
        groupRef.current.y(0)

        //Updating locationData state
        setLocationData({ ...locationData, computers: newComputerData, computer_count: newComputerData.length });

        // //Updating items according to locationData
        setItems(newComputerData)

        //Attach transformer to the moved position
        attachToTransformer(e, [])

        //Update stage
        stageRef.current.clear();

    }

    function attachToTransformer(e, elements = []) {
        const currentRef = transfomerRef.current;
        //Disable rotation
        currentRef.rotateEnabled(false);
        //Disable resizing
        currentRef.resizeEnabled(false);
        //set padding from object
        currentRef.padding(TRANSFORMER_PADDING);

        //change border to dash
        currentRef.borderDash([5, 5]);

        //Clear nodes from elements
        currentRef.nodes([]);

        //Update nodes to attach to transfomer
        currentRef.nodes([...elements]);

        //Clearing stage
        stageRef.current.clear();

        //Batch draw nodes into stage
        currentRef.getLayer().batchDraw();
    }

    function handleSave(e) {
        setIsSaving(true);
        //Computers to be updated
        let computers = [];

        //Cleaning up unecessary  field
        locationData.computers.forEach((element, index) => {
            delete element.id;
            delete element._id;
            delete element.location;
            delete element.number;
            delete element.created_on;
            delete element.updated_on;
            delete element.deleted_on;
            computers.push(element)

        });

        axios.put("manage/location/" + match.params.id + "/computer", { computers: computers }).then((resp) => {
            setIsSaving(false);
            window.location = "/admin/location/" + match.params.id + "/computers";
        }).catch((err) => {
            alert(err);
        })

        console.log(computers)

    }


    const returnPath = "/admin/location/" + match.params.id + "/computers";

    return <>
        <div className="my-5 h-75vh">
            <Container >
                <Row>
                    <Col xs={12} md={8}>
                        <h2 className="text-monospace">
                            <Link to={returnPath} title="Back" className="mr-3"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                            <span className="text-muted">Manage\</span>Computers::<span className="text-info">Edit</span>({match.params.id})
                        </h2>
                    </Col>
                    <Col>
                        <div className="text-right">
                            <Button color="primary" size="lg" onClick={handleSave}>Save {isSaving ? <Spinner as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true" /> : ""}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="h-100 justify-content-center align-items-center">
                <Row className="my-5 h-100">
                    <Col xs={6} sm={6} lg={3} className=" pt-3">
                        <Toolbar
                            handleUpdateCanvasData={setCanvasData}
                        />
                    </Col>
                    <Col xs={4} sm={6} lg={9} className="text-center mb-4" >
                        <StageComponent
                            stageId={"default"}
                            stageRef={stageRef}
                            layerRef={layerRef}
                            transfomerRef={transfomerRef}
                            selectionRectRef={selectionRectRef}
                            allowEdit={true}
                            match={match}
                            field={(locationData)}
                            onDragStart={onDragStart}
                            onMouseMove={onMouseMove}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            onDragLeave={onDragLeave}
                            onClick={onClick}
                            onMouseUp={onMouseUp}
                            infoModal={infoModal}

                            handleInfoModalDismiss={handleInfoModalDismiss}
                            handleClickOnComputer={handleClickOnComputer}
                            onDragStartComputer={onDragStartComputer}
                            onDragEndComputer={onDragEndComputer}
                        />

                    </Col>
                </Row>
            </Container>
            <Modal isOpen={modalAddVertical}>
                <ModalHeader>
                    Tambah komputer (multiple)
                </ModalHeader>
                <ModalBody>
                    <Label>
                        Jumlah Komputer yang ingin ditambahkan?
                    </Label>
                    <Input type="number" min={1} max={10} onChange={(e) => setComputerToAdd((prev) => { return { ...prev, total: Math.floor(e.target.value) } })} value={computerToAdd.total} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={(e) => {
                        setModalAddVertical(false);
                        setComputerToAdd((prev) => { return { ...prev, total: 0 } });
                    }}>Batalkan</Button>
                    <Button onClick={(e) => addMultipleComputer(e)} color="primary">Tambahkan</Button>
                </ModalFooter>
            </Modal>
        </div>
    </>

};

export default withRouter(inject("locationStore")(observer(Edit)));