import { action, computed, makeObservable, observable, toJS } from "mobx";

import { axios } from '~/apicall';

class LocationStore {


    defaultLocation = {
        id: "",
        room_name: "",
        alias_name: "",
        subnet_start: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        computers: [],
        computer_count: 0,
    };

    defaultComputer = {
        id: "",
        number: "",
        ip: "",
        reverse_dns: "",
        status: "",
        priority: "",
        pos_x: 0,
        pos_y: 0,
        created_at: "",
        updated_at: "",
        deleted_at: "",
    };

    _locations = {};
    selectedLocation = null;
    toBeMoved = [];
    canvasData = {
        buttonMode: "add_computer",
        infoModal: null,
        dragDropData: null,
        anchorLines: [],
        transformerNodes: [],
        moveTo: null,
        moveFrom: null,
    };


    isLoading = true

    constructor() {
        makeObservable(this, {
            //observables
            _locations: observable,
            toBeMoved: observable,
            canvasData: observable,
            selectedLocation: observable,
            isLoading: observable,

            //actions
            setDragDropData: action,
            setInfoModalData: action,
            setButtonMode: action,
            addComputerToLocation: action,
            updateComputer: action,

            //computed
            getCanvasData: computed,
            getComputerCount: computed,
            getSelectedLocation: computed,
            getComputerFromSelectedLocation: computed,
            getInfoModalData: computed,
            locations: computed,

        })
    }

    setDragDropData(computerData) {
        if (computerData) {
            this.canvasData.dragDropData = { ...computerData };
        } else {
            this.canvasData.infoModal = null;
        }
    }

    setInfoModalData(computerData) {
        if (computerData) {
            if (this.canvasData.infoModal == null) {
                this.canvasData.infoModal = {};
            }

            for (const [key, value] of Object.entries(computerData)) {
                this.canvasData.infoModal[key] = value;
            }
        } else {
            this.canvasData.infoModal = null;
        }
    }

    setButtonMode(e, buttonMode) {
        if (buttonMode) {
            this.canvasData.buttonMode = buttonMode;
        }
    }

    addComputerToLocation(location, newComputer) {
        this._locations[location].computers.push(newComputer);
    }

    updateComputer(location, computerNumber, computerDetails) {

    }


    /*
    DATA FECTHING and UPDATE
     */

    createLocation(url, values) {
        this.isLoading = true;
        return axios.post(url, values).then(response => {
            if (response.data.status) {

                this._locations[response.data.data._id] = response.data.data;
            } else {
                throw new Error(response.data.error);
            }
            this.isLoading = false;
            return Promise.resolve(response.data.data)
        }).catch((err) => {
            this.isLoading = false;
            return Promise.reject(err);
        })
    }

    locationDelete(id) {
        this.isLoading = true;
        return axios.delete("manage/location/" + id).then(resp => {
            delete this._locations[id];
            this.isLoading = false;
            return Promise.resolve(resp);
        })
    }

    locationFetch(id) {
        this.isLoading = true;
        if (id) {
            return axios.get("manage/location/" + id).then(resp => {
                this._locations[id] = resp.data.data;
                this.selectedLocation = resp.data.data._id;
                this.isLoading = false;
                return Promise.resolve(resp);
            })
        }
        return axios.get("manage/location").then(resp => {
            let locations = {};
            resp.data.data.forEach(element => {
                locations[element._id] = element;
            });
            this._locations = locations;
            this.isLoading = false;
            return Promise.resolve(resp);
        })
    }

    locationUpdate(url, id, newValue) {
        this.isLoading = true;
        return axios.put(url + "/" + id, newValue).then(response => {
            if (response.data.status) {
                this._locations[response.data.data._id] = response.data.data;
            } else {
                throw new Error(response.data.error);
            }
            this.isLoading = false;
            return Promise.resolve(response.data.data)
        }).catch((err) => {
            this.isLoading = false;
            return Promise.reject(err);
        })
    }

    fetchLocationInfo(showLoading) {
        this.isFetchingExam = (showLoading && true);
        return axios.get('exam/info').then((response) => {
            this.isFetchingExam = false;
            if (!response.data.status) {
                return Promise.reject({ err: "Not Ok, something not ok.", ref: response.data });
            }
            this.participant = response.data.data;
            this.exam = this.participant?.exam;
            return Promise.resolve();
        });
    };

    get getComputerCount() {
        return this._locations[this.selectedLocation].computer_count;
    }

    get getSelectedLocation() {
        let tempLocation = {};
        tempLocation[this.selectedLocation] = { ...this._locations[this.selectedLocation] }
        return tempLocation;
    }

    get getComputerFromSelectedLocation() {
        let tempLocation = {};
        if (this.selectedLocation && this._locations[this.selectedLocation]) {
            tempLocation[this.selectedLocation] = { ...this._locations[this.selectedLocation] }
            console.log(toJS(tempLocation.computers))
            if (tempLocation.computers) {
                console.log(tempLocation["computers"])
            }
        }

        return tempLocation;
    }

    get getCanvasData() {
        return { ...this.canvasData }
    }
    get getInfoModalData() {
        return toJS(this.canvasData.infoModal);
    }

    get locations() {
        return Object.values(this._locations);
    }

}


export default LocationStore;