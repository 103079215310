import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

const NotificationAudiencePicker = ({ participants = [], onSelected = () => { }, selectedAudiences = [], onCanceled = () => { } }) => {
    const [_selectedAudiences, set_SelectedAudiences] = useState(selectedAudiences)
    useEffect(() => {
        set_SelectedAudiences(selectedAudiences)
        return () => { }
    }, [selectedAudiences])

    function handleSelectAll() {
        set_SelectedAudiences(participants.map((data) => data.uuid));
    }

    function handleCheckChange(e, participant) {
        if (e.target.checked) {
            set_SelectedAudiences([..._selectedAudiences, participant.uuid])
        } else {
            set_SelectedAudiences([..._selectedAudiences.filter((data) => data !== participant.uuid)]);
        }
    }

    return (
        <React.Fragment >
            <Modal isOpen backdrop>
                <ModalHeader>Pilih Penerima Notifikasi</ModalHeader>
                <ModalBody>
                    <div>
                        <Button onClick={handleSelectAll}>Pilih semua</Button>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Nama</th>
                                <th>Username</th>
                            </tr>
                        </thead>
                        <tbody>
                            {participants.map((participant) => <tr key={"participant-" + participant._id}>
                                <td><Input type="checkbox" checked={_selectedAudiences.includes(participant.uuid)} onChange={(e) => handleCheckChange(e, participant)} /></td>
                                <td>{participant.display_name}</td>
                                <td><code>{participant.username}</code></td>
                            </tr>)}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onCanceled}>Batal</Button>
                    <Button onClick={() => onSelected(_selectedAudiences)}>Selesai</Button>
                </ModalFooter>
            </Modal>

        </React.Fragment>
    )
}

export default NotificationAudiencePicker