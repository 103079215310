import { observer } from 'mobx-react';
import React from 'react';
import Exam from './exam/Exam';

const Lecturer = (props) => {
    return (
        <React.Fragment>
            <Exam />
        </React.Fragment >
    )
}

export default observer(Lecturer);