import { action, computed, makeObservable, observable, toJS } from "mobx";
import { axios, clearAuth, setAuth } from "~/apicall";

class ExamOnlineStore {
    user = {};
    isFetchingData = false;
    exams = {};
    lectures = {};
    selectedExam = null;

    constructor() {
        makeObservable(this, {
            user: observable,
            exams: observable,
            isFetchingData: observable,
            selectedExam: observable,
            getUser: computed,
            getExams: computed,
            checkIsFetchingData: computed,
            getLectures: computed,
            exam: computed,
            tryLogin: action,
            toggleLoadingScreen: action,
            examFetch: action,
            setSelectedExamID: action,
        });
    }

    /**
     * Calling API endpoint for login.
     * On success will set the auth-token in the storage using token from response
     * @param {string} username username for login 
     * @param {string} password password for login
     * @returns resolved promise
     */
    tryLogin(username, password) {
        return axios.post("/system/auth/ldaplogin", {
            username: username,
            password: password
        }).then(e => {
            if (e.status === 200) {
                this.loginType = "basic"
                this.boundedLocations = [];
                setAuth(e.data.data.id_token);
            }else{
                return Promise.reject(e)
            }
            return Promise.resolve(e);
        });
    }

    /**
     * Fetching profile using auth-token stored in storage
     * @returns resolved promise ofthe request
     */
    fetchProfile() {
        return axios.get("/system/onlineuser/me").then(e => {
            this.user = e.data.data.profile;
            return Promise.resolve(e);
        });

    }


    /**
     * Toggle loading status in store
     * @param {boolean} isLoading boolean value for the loading status. Default value is `false` when called without parameters
     */
    toggleLoadingScreen(isLoading = false) {
        this.isFetchingData = isLoading;
    }

    examFetch(id) {
        this.toggleLoadingScreen(true);
        if (id) {
            this.setSelectedExamID(id);
            return axios.get("/manageonline/examonline/" + id + "/detail").then(resp => {
                const respObj = toJS(resp)
                this.exams[id] = respObj.data.data;
                return Promise.resolve(resp);
            })
        }
        return axios.get("/manageonline/examonline").then(resp => {
            let exam = {};
            resp.data.data.forEach(element => {
                exam[element._id] = element;
            });
            this.exams = exam;
            this.toggleLoadingScreen();
            return Promise.resolve(resp);
        })
    }

    fetchLectureData() {
        this.toggleLoadingScreen(true);
        return axios.get("manage/lecture").then(data => {
            this.lectures = data.data.data;
            this.toggleLoadingScreen(false);
            return Promise.resolve(data.data.data);
        })

    }

    setSelectedExamID(id) {
        if (id) {
            this.selectedExam = parseInt(id);
        }
    }

    get getLectures() {
        return Object.values(this.lectures);

    }

    get getExams() {
        return Object.values(this.exams)
            .sort((a, b) => b?.time_start.localeCompare(a?.time_start));
    }

    get exam() {
        if (this.selectedExam == null) {
            return {};
        } else {
            return { ...this.exams[this.selectedExam] }
        }
    }

    get getUser() {
        return { ...this.user };
    }

    get checkIsFetchingData() {
        return this.isFetchingData;
    }

    userLogout() {
        clearAuth();
        return Promise.resolve();
    }
}


export default ExamOnlineStore;