import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
    Collapse, Container, DropdownItem, DropdownMenu,
    DropdownToggle, Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from 'reactstrap';
import { checkPermission } from '~/components/globals/utils/CheckPermission';



const LecturerNavbar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { examOnlineStore, history } = props;

    useEffect(() => {
        const getProfileData = async () => {
            const redirectURI = "/exam-online/account/login";
            await examOnlineStore.fetchProfile().then((resp) => {
                if (resp.status === 200) {
                    let user = examOnlineStore.getUser;
                    //check permission, only allow role of admin and lecturer
                    checkPermission(user.acl, ["lecturer", "admin"], history, redirectURI)
                } else {
                    checkPermission(null, ["lecturer", "admin"], history, redirectURI)
                }
            }).catch(e => {
                examOnlineStore.toggleLoadingScreen(false);
                history.push(redirectURI);
                return () => { };
            });
        }
        getProfileData();
    }, [examOnlineStore, history]);

    const toggleOpen = () => {
        const currOpenState = isOpen;
        setIsOpen(!currOpenState);
    }


    const handleLogout = () => {
        const { examOnlineStore, history } = props;
        examOnlineStore.userLogout().then(() => {
            history.push("/exam-online/account/login");
        });
    }

    return (
        <React.Fragment>
            <div>
                < Navbar color="dark" dark expand="md">
                    <Container>
                        <NavbarBrand href="/oxam-online" to="/exam-online" tag={Link}>Oxam</NavbarBrand>
                        <NavbarToggler onClick={() => toggleOpen()} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink to="/exam-online/lecturer" tag={Link}>Ujian Online</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        @{examOnlineStore?.user?.username || "Reg"}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem tag={Link} to={"/exam-online/lecturer/profile/"}>Account Setting</DropdownItem>
                                        <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        </React.Fragment>
    )
}

export default inject("examOnlineStore")(
    withRouter(observer(LecturerNavbar))
);