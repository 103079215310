import { observer } from "mobx-react";
import React, { Component } from "react";
import { Circle, Group, Image, Rect, Text } from "react-konva";
import faCheckCircle from "~/assets/circle-check-solid.svg";
// import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import faDesktop from "~/assets/display-solid.svg";

class ComputerShape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      selectImage: null,
    };

    this.groupRef = React.createRef(null)
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener("load", this.handleLoad);
    this.selectImage.removeEventListener("load", this.handleLoad);
  }

  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = faDesktop;

    this.selectImage = new window.Image();
    this.selectImage.src = faCheckCircle;

    this.image.addEventListener("load", this.handleLoad);
    this.selectImage.addEventListener("load", this.handleLoad);
  }

  onDragStart(e, mode) {
    // e.preventDefault();
  }
  onDragEnd(e) {
    // e.preventDefault();
    this.props.onDragEnd(e, this.props.data, this.groupRef)
  }
  onDragOver(e) {
    // e.preventDefault();
  }

  onDragLeave(e) {
    // e.preventDefault();
  }

  onDrop(e) {
    // e.preventDefault();
    console.log("Dropping from computer shape")
  }

  onClick(e) {
    // e.preventDefault();
    console.log("From computer shape comp")
  }

  onMouseOver(e) {
    e.evt.preventDefault();
  }
  onMouseLeave(e) {
    e.evt.preventDefault()
  }


  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
      selectImage: this.selectImage,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };



  render() {
    let {
      _id,
      name,
      number,
      ip,
      state,
      pos_x,
      pos_y,
      width,
      height,
      priority,
      draggable,
      isPingable,
      isSelected,
      isOccupied
    } = this.props.data;

    pos_x = parseInt(pos_x);
    pos_y = parseInt(pos_y);

    const circleStatRad = width * 0.1;
    const circleStatX = pos_x + width / 2 + width / 4;
    const circleStatY = pos_y - height / 4;

    const circleSelectedRad = width * 0.2;
    const circleSelectedX = pos_x + width / 2 + width / 4 - circleSelectedRad / 2;
    const circleSelectedY = pos_y + height / 2 + width / 4 - circleSelectedRad / 2;

    const priorityTxtX = pos_x + width / 20;
    const priorityTxtY = pos_y + height / 8;
    const priorityTxtFont = 0.2 * height;
    const priorityTxtWidth = width / 2 - width / 4 + width / 8;

    return (
      <Group
        id={"group-computer-" + number}
        draggable={draggable ? true : false}
        x={0}
        y={0}
        ref={this.groupRef}
        onClick={(e) => { this.props.onClick ? this.props.onClick(e, this.props.data) : this.onClick(e); }}
        onDragStart={(e) => { this.props.onDragStart ? this.props.onDragStart(e, this.props.data) : this.onDragStart(e) }}
        onDragEnd={(e) => { this.onDragEnd(e) }}
      >
        <Rect
          x={pos_x - width / 4}
          y={pos_y - height / 4}
          width={width}
          height={height}
          stroke="black"
          // zIndex={100}
          cornerRadius={[5, 5, 5, 5]}
          fill={state ? "lightgreen" : "gray"}
          opacity={0}
          onMouseOver={(e) => { this.props.onMouseOver ? this.props.onMouseOver(e, this.props.data) : this.onMouseOver(e) }}
          onMouseOut={(e) => { this.props.onMouseLeave ? this.props.onMouseLeave(e) : this.onMouseLeave(e) }}
        />

        <Rect
          x={pos_x - width / 4}
          y={pos_y - height / 4}
          width={width}
          height={height}
          stroke="black"
          cornerRadius={[5, 5, 5, 5]}
          fill={state ? (isOccupied ? "#66B2FF" : "lightgreen") : "gray"}
          listening={false}
        />



        <Text x={pos_x - (0.05 * width)}
          y={pos_y - (0.2 * height)}
          text={name}
          fontSize={0.12 * height}
          fontStyle="bold"
          listening={false} />

        {
          priority >= 1 ? <Text x={priorityTxtX}
            y={priorityTxtY}
            text={priority}
            fontSize={priorityTxtFont}
            width={priorityTxtWidth}
            align={"center"}
            fontStyle="bold"
            listening={false} /> : <></>
        }

        <Image
          x={pos_x}
          y={pos_y}
          image={this.state.image}
          width={width / 2}
          height={height / 2}
          listening={false}
        />
        <Text x={pos_x - (0.15 * width)}
          y={pos_y + (0.55 * height)}
          text={ip}
          fontSize={0.12 * height}
          fontStyle="bold"
          listening={false}
        />

        {
          //Only show this in non edit mode
          this.props.draggable ? <></> :
            <Circle
              x={circleStatX}
              y={circleStatY}
              radius={circleStatRad}
              //Default value of isPingable is undefined (ping status not defined)
              //If ping result failed isPingable = 0, else if success isPingable = 1
              fill={(isPingable === undefined) ? "white" : (isPingable == 0 ? "red" : "#b2ff73")}
              stroke={"black"}
              strokeWidth={2}
              listening={false}
            />}

        {
          !isSelected ? <></> :
            <Image
              x={circleSelectedX}
              y={circleSelectedY}
              image={this.state.selectImage}
              width={circleSelectedRad}
              height={circleSelectedRad}
              listening={false}
            />
        }


      </Group >
    );
  }
}

export default observer(ComputerShape);
