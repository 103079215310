import { useEffect, useState } from 'react';
import { Col, Container, Input, InputGroup, Modal, ModalBody, Row } from 'reactstrap';
// import { DayPickerSingleDateController } from "react-dates";
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import { DayPicker } from "react-day-picker";
import 'react-day-picker/dist/style.css';
import { When } from 'react-if';
import TimeKeeper from 'react-timekeeper';
// import './datepicker-override.scss';
import id from 'date-fns/locale/id';
import './day-picker.css';


function DateTimePicker({ time = new Date(), onChange = () => null, disabled = false }) {
    console.log("defaultValue dtp", time)
    const [selectedDate, setSelectedDate] = useState(moment())
    const [selectedTime, setSelectedTime] = useState(undefined)
    const [dpOpen, setDpOpen] = useState(false);
    const [tpOpen, setTpOpen] = useState(false);

    function handleDateChange(day) {
        console.log(day)
        let m = moment(day);
        if (selectedTime !== undefined) {
            m.hour(selectedTime.hour);
            m.minutes(selectedTime.minute);
            m.seconds(0);
        } else {
            m.hour(selectedDate.format("HH"));
            m.minutes(selectedDate.format("mm"));
            m.seconds(0);
        }
        setSelectedDate(m);

        //Update time state of parent
        onChange(m)
        setDpOpen(false);
    }

    function handleTimeChange(time) {
        let m = moment(selectedDate);
        m.hour(time.hour);
        m.minutes(time.minute);
        m.seconds(0);
        setSelectedDate(m);
        setSelectedTime(time)
        
        //Update time state of parent
        onChange(m)

        setTpOpen(false);
    }

    // useEffect(() => {
    //     onChange(selectedDate);
    //     return () => { };
    // }, [selectedDate, onChange])

    useEffect(()=>{
        setSelectedDate(moment(time))
    }, [time]);

    return (
        <Row>
            <Col>
                <InputGroup>
                    <Input className="bg-white" type="text" disabled={disabled} value={selectedDate.format("YYYY-MM-DD")} onClick={() => { setDpOpen(true); setTpOpen(false) }} onChange={() => null} />
                    <Input className="bg-white" type="text" disabled={disabled} readOnly value={selectedDate.format("HH:mm")} onClick={() => { setTpOpen(true); setDpOpen(false) }} onChange={() => null} />
                </InputGroup>
                <When condition={dpOpen}>
                    <Modal isOpen={dpOpen}
                        size="md"
                        backdrop={true}
                        toggle={(e) => setDpOpen(false)}
                        centered
                        className=""
                    >
                        <Container className='row justify-content-center m-0 p-0'>
                            <DayPicker
                                pagedNavigation
                                mode="single"
                                selected={selectedDate}
                                onSelect={handleDateChange}
                                locale={id}
                            />
                        </Container>
                    </Modal>
                </When>
                <When condition={tpOpen}>

                    <Modal isOpen={tpOpen}
                        // backdrop={true}
                        toggle={(e) => setTpOpen(false)}
                        size={"sm"}
                        centered
                        className="bg-transparent"
                    >
                        <ModalBody className='row justify-content-center p-0 m-0'>
                            <Container className='row justify-content-center p-0 m-0'>
                                <TimeKeeper
                                    className='p-0 m-0'
                                    hour24Mode
                                    time={selectedDate.format("HH:mm")}
                                    onDoneClick={handleTimeChange}
                                    switchToMinuteOnHourSelect
                                    closeOnMinuteSelect />
                            </Container>
                        </ModalBody>
                    </Modal>

                </When>
            </Col>
        </Row >
    )
}

export default DateTimePicker
