import { faDesktop, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Table from "reactstrap/lib/Table";
// import { If, Then, Else } from 'react-if';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject } from 'mobx-react';
import { Link, withRouter } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useLocationStore } from '~/components/use-store';

function Location() {
    const locationStore = useLocationStore();

    useEffect(() => {
        locationStore.locationFetch();
        console.log("Fetching locations...");
        return () => { };
    }, [locationStore])

    const [prepareDelete, setPrepareDelete] = useState({})

    function handleDelete() {
        locationStore.locationDelete(prepareDelete._id).then(() => {
            setPrepareDelete({});
        })
    }
    return (
        <React.Fragment>

            <Container>
                <Row className="mt-4">
                    <Col>
                        <h2 className="text-monospace"><span className="text-muted">Manage\</span>Location::<span className="text-info">index</span>()</h2>
                    </Col>
                    <Col>
                        <div className="text-right">
                            <Button color="primary" size="lg" tag={Link} to="/admin/location/new">Create New</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="my-4">
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>room_name</th>
                                        <th>name_alias</th>
                                        <th>subnet_start</th>
                                        <th>updated_on</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        locationStore?.locations ?
                                            locationStore.locations.map((location, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {(location || {})._id}
                                                    </td>
                                                    <td>{(location || {}).room_name}</td>
                                                    <td>{(location || {}).name_alias}</td>
                                                    <td>{(location || {}).subnet_start}</td>
                                                    <td>{(location || {}).updated_on}</td>
                                                    <td>
                                                        <Button color="secondary" className="mx-2" tag={Link} to={`/admin/location/${location._id}/edit`}>
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </Button>
                                                        <Button color="secondary" className="mx-2" tag={Link} to={`/admin/location/${location._id}/computers`}>
                                                            <FontAwesomeIcon icon={faDesktop} />
                                                        </Button>
                                                        <Button color="danger" className="mx-2" onClick={() => setPrepareDelete({ ...location })}><FontAwesomeIcon icon={faTrash} /></Button>
                                                    </td>
                                                </tr>
                                            ) :
                                            <></>
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>

                <Modal isOpen={!!(prepareDelete._id)} toggle={() => setPrepareDelete({})} backdrop>
                    <ModalHeader toggle={() => setPrepareDelete({})}>Delete Confirmation</ModalHeader>
                    <ModalBody>

                        <Table bordered style={{ columns: 2 }}>
                            <tbody>
                                {Object.keys(prepareDelete).map((el) => {
                                    let content = prepareDelete?.[el] || "";
                                    if (el === "deleted_on" ||
                                        el === "computers" ||
                                        el === "iplogins" ||
                                        el === "created_on"
                                    ) {
                                        return;
                                    }
                                    return <tr key={el}>
                                        <th>{el}</th>
                                        <td>{(content)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>

                        <hr />
                        <h4>Are you sure you want to delete this location and computers related to this location?</h4>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setPrepareDelete({})}>Cancel</Button>{' '}
                        <Button color="danger" onClick={handleDelete}><FontAwesomeIcon icon={faTrash} /> Delete </Button>
                    </ModalFooter>
                </Modal>
            </Container >
        </React.Fragment>
    )

}


export default inject("locationStore")
    (withRouter(
        observer(Location)
    )
    );