import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

function Step3({ examDetails }) {
    console.log(examDetails);
    return (
        <React.Fragment>
            <div className="my-5">
                <h3>Finish.</h3>
                <p>Examination has been created and participants has been plotted to designated seat.</p>

                <div className="mt-4">
                    <Button tag={Link} to={`/admin/exam-online/exam/${examDetails.exams._id}/detail`}>See Exam</Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step3;