import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, CardBody, Col, Container, Row } from "reactstrap";
import { clearAuth } from "~/apicall";

const displayFaces = [
    'meh-rolling-eyes',
    'surprise',
    'meh',
    'grin-tears',
    'dizzy',
    'lemon'
]

let emotnya = Math.floor(Math.random() * displayFaces.length);
const NoExam = () => {
    let history = useHistory();

    function handleLogout(e) {
        //Remove Auth/Credential
        clearAuth();

        //Redirect to login page
        history.push("/exam-online");
    }

    return (
        <React.Fragment>
            <Container className="text-center">
                <Row className="ml-4 my-4">
                    <Button className="row"
                        style={{ textDecoration: "none" }}
                        title="Pulang yok, pulang!"
                        color="secondary"
                        onClick={handleLogout}
                    >
                        <FontAwesomeIcon icon={faSignOutAlt} size="1x" className=" mt-1 mx-1 text-white" />
                        <span className="text-white"> Logout</span>
                    </Button>
                </Row>
                <Row className=" h-100vh  align-items-center">

                    <Col className="">
                        <CardBody>
                            <div className="p-4">
                                <FontAwesomeIcon icon={['far', displayFaces[emotnya]]} size="3x" className="mb-5 text-muted" />
                                <h3>Tidak ada ujian yang sedang berjalan.</h3>
                                <p>Mohon kontak administrator, jika anda yakin ini sebuah kesalahan.</p>
                            </div>
                        </CardBody>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    )
}
NoExam.propTypes = {
}

export default NoExam;