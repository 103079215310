import { faWalking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Alert, Badge, Button, Col, Row } from 'reactstrap';

import moment from "moment";
import "moment/locale/id";
import { Else, If, Then, When } from 'react-if';
import { axios } from '~/apicall';

const Step2 = ({ examDetails, onRequestNext }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);


    /**
     * Method to upload resource and connect it to an exam by id
     * @param {File} file File object to be upload
     * @param {int} examID id of exam that is going to be linked with resource
     * @return a resolved promise or a rejected promise
     */
    function populateResource(file, examID) {
        if (file && examID) {
            //Create FOrmData object
            let formData = new FormData();
            let fileName = file.name.replace(/[-\s/()\\]+/g, "");
            //Append file to FormData
            formData.append('file', file, fileName);
            //call to api populate_resource
            return axios.post("manageonline/examonline/" + examID + "/populate_resource", formData,  {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
                timeout: 30000
            }).then((resp) => {
                return Promise.resolve(resp);
            }).catch((resp) => {
                return Promise.reject(resp);
            });
        }
    }


    function handleCreation(e) {
        setIsLoading(true);
        setStatus("Spinning up new online exam");

        //Create new exam entry
        axios.post("manageonline/examonline", {
            lecture: examDetails.lecture,
            uts: examDetails.uts,
            time_duration: examDetails.duration,
            shift: examDetails.shift,
            online: examDetails.online,
            time_start: examDetails.timeStart
        }).then((response) => {
            //Connecting lecturer with the newly created exam
            if (response.status === 200) {
                setStatus("Connect lecturers to exam");
                let examId = response.data.data._id;
                let urlPopulateLecturer = "manageonline/examonline/" + examId + "/populate_lecturer";

                if (examDetails.collaborators.length) {
                    let lecturersUUID = examDetails.collaborators.map((data) => {
                        if (data.uuid) return data.uuid;
                        return null;
                    });

                    return axios.post(urlPopulateLecturer, {
                        lecturers: lecturersUUID
                    }).catch((resp) => {
                        setStatus("Error happened, reverting things...");
                        Promise.reject(resp);
                        return axios.delete("manageonline/examonline/" + response.data.data._id).then(() => {
                            Promise.reject(resp);
                        })
                    });
                } else {
                    Promise.resolve(response);
                }
            } else {
                Promise.reject(response);
            }
        }).then((response) => {
            if (response.status === 200) {
                //Connecting students with the newly created exam
                setStatus("Connect participants to exam");
                let examId = response.data.data._id;
                let urlPopulateStudent = "manageonline/examonline/" + examId + "/populate_student";
                return axios.post(urlPopulateStudent, {
                    participants: [...examDetails.peserta]
                }).catch((resp) => {
                    setStatus("Error happened, reverting things...");
                    return axios.delete("manageonline/examonline/" + resp.data.data._id).then(() => {
                        Promise.reject(resp);
                    })
                });
            } else {
                Promise.reject(response);
            }
        }).then((response) => {
            console.log(response);
            if (response.status === 200) {
                //Connecting resources with the newly created exam
                let berkasUjian = Object.entries(examDetails.berkasUjian);


                /**
                 * Test
                 */
                function populateResources() {
                    setStatus("Connect resources to exam");
                    console.log(berkasUjian)
                    let examId = response.data.data._id;
                    let unresolvedPromise = berkasUjian.map(([k, v]) => {
                        console.log(k, v)
                        return populateResource(v, examId);
                    });
                    return Promise.all(unresolvedPromise);

                }

                if (berkasUjian.length > 0) {
                    return populateResources();
                } else {
                    console.log("Here")
                    return Promise.resolve(response);

                }
            } else {
                setStatus("Error happened, reverting things...");
                return axios.delete("manageonline/examonline/" + response.data.data._id).then(() => {
                    Promise.reject(response);
                })
            }
        }).then((response) => {
            let responseLength = response.length;

            //No Resource created
            if (response.status === 200) {
                setIsLoading(false);
                onRequestNext(response.data.data);
            } else if (responseLength > 0) {
                setIsLoading(false);
                onRequestNext(response[responseLength - 1].data.data);
            }
        }).catch((resp) => {
            setIsLoading(false);
            console.log("Catch", resp);
            if (resp.data.data && resp.data.data.error) {
                setErrorMsg(<>
                    <h5>{resp.data.data.error.title} [{resp.data.data.error.error_code}]</h5>
                    <p>{resp.data.data.error.description}</p>
                </>)
            } else {
                console.error(resp);
                console.log(JSON.stringify(resp));
                setErrorMsg(<>
                    <h5>Error happened</h5>
                    <p>{resp.message}</p>
                    <p><small>Because it's a browser(/network) related error, the error has been emitted to the console.</small></p>
                </>)
            }
        });
    }

    function convertSecondsToReadableTime(time) {
        let hours = Math.floor(time / 3600);
        let leftSeconds = time - (hours * 3600);
        let minutes = Math.floor(leftSeconds / 60);
        let second = time - (hours * 3600) - (minutes * 60);
        return hours + " jam " + (minutes ? minutes + " menit " : "") + (second ? (second).toFixed(2) + " detik" : "");
    }

    return (
        <React.Fragment>
            <div className="my-4">
                <h3>Konfirmasi</h3>
                <Row>
                    <Col>
                        <p className="h2">
                            <Badge color={examDetails.uts ? (examDetails.uts === 1 ? "success" : "primary") : "info"}>{examDetails.uts ? (examDetails.uts === 1 ? "UTS" : "QUIZ") : "UAS"}</Badge>
                            <span className="ml-3">{(examDetails.lecture_obj || {}).name || "Nama Matakuliah di sini"}</span>
                        </p>
                        <p className="lead">{examDetails.shift ? "Shift " + examDetails.shift : "Tanpa Shift"} - {(examDetails.peserta || []).length} Peserta</p>
                        <p>Dilaksanakan secara daring. Akan dimulai pada <b>{moment(examDetails.timeStart).format('LLLL')}</b> selama <b>{convertSecondsToReadableTime(examDetails.duration)}</b>.
                            {examDetails.collaborators ? examDetails.collaborators.map((data, index) => {
                                if (index === 0) {
                                    return (<span key={"name-bold-" + data.uuid}> Akan diawasi bersama dengan <b >{data.display_name}</b></span>)
                                } else if (index === examDetails.collaborators.length - 1) {
                                    return (<span key={"name-bold-" + data.uuid}> dan <b >{data.display_name}.</b></span>)
                                } else {
                                    return (<b key={"name-bold-" + data.uuid}>, {data.display_name}</b>)
                                }
                            }) : ""}
                        </p>
                        <When condition={!!errorMsg}>
                            <Alert color="danger">{errorMsg}</Alert>
                        </When>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-center my-5 pt-5">
                            <p>Untuk konfirmasi pembuatan ujian, tekan tombol di bawah ini.</p>
                            <Button color="warning" size="lg" onClick={handleCreation} disabled={isLoading}>
                                <If condition={isLoading}>
                                    <Then>{status}</Then>
                                    <Else>Create Exam <FontAwesomeIcon icon={faWalking} /></Else>
                                </If>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default Step2