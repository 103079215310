import { faCheckCircle, faCircle as faCirclRglr } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faCircle, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Container, FormGroup, Input, Label, Row, Spinner, Table } from 'reactstrap';
import { axios } from '~/apicall';

import StageComponent from "~/components/location/StageComponent";
import { useLocationStore } from '~/components/use-store';

function Detail({ match }) {
  const locationStore = useLocationStore();
  const [field, setField] = useState({});
  const [isPinging, setIsPinging] = useState(false);
  const [infoModal, setInfoModal] = useState(null);
  const stageRef = useRef(null);

  //MEMOIZED FUNCTION
  // const handleClickOnComputer = useCallback((event, data) => {
  //   console.log("From computers/detail/Detail");  
  //   locationStore.setInfoModalData(data)
  // })

  const handleClickOnComputer = (event, data) => {
    locationStore.setInfoModalData(data)
    setInfoModal(data);
  }

  const handleInfoModalDismiss = (e) => {
    locationStore.setInfoModalData(null)
    setInfoModal(null);

  }

  const handlePingSingle = async (computer) => {
    await axios.get("/manage/location/" + match.params.id + "/ping",
      {
        params: {
          "ip": computer.ip
        }
      }
    )
      .then((resp) => {
        let { data } = resp.data;
        let computersData = field.computers.map((element, index) => {
          if (element.ip.localeCompare(data.ip) === 0) {
            element.isPingable = (data.loss_rate === 0 ? 1 : 0);
          }
          return element;
        })

        setField({ ...field, computers: computersData });

        return Promise.resolve(resp);
      }).catch((err) => {
        return Promise.reject(err)
      });
  }

  const handlePing = (e) => {
    setIsPinging(true);
    let unresolvedPromise = field.computers.map((element, index) => {
      return handlePingSingle(element);
    })

    Promise.all(unresolvedPromise).then((resp) => {
      setIsPinging(false);
    });


  }

  useEffect(() => {
    locationStore.locationFetch(match.params.id).then(() => {
      let finalizedFields = { ...locationStore.getSelectedLocation[match.params.id] };
      setField((prevField) => { return { ...prevField, ...finalizedFields } });
    });
    return () => { };
  }, [locationStore]);


  let returnPath = "/admin/location";

  return (<>
    <div className="h-100vh my-5">
      <Container >
        <Row>
          <Col xs={12} md={8}>
            <h2 className="text-monospace">
              <Link to={returnPath} title="Back" className="mr-3"><FontAwesomeIcon icon={faChevronLeft} /></Link>
              <span className="text-muted">Manage\</span>Computers::<span className="text-info">index</span>({match.params.id})
            </h2>
          </Col>
          <Col>
            <div className="text-right">
              <Button color="secondary" size="lg" tag={Link} to={returnPath + "/" + match.params.id + "/edit-computers"}>Edit</Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="my-5 justify-content-center align-items-center">
        <Row >
          <Col xs={6} sm={6} lg={3} className=" pt-3 ">
            <Row>
              <FormGroup key={"form-id"}>
                <Label>id</Label>
                <Input
                  defaultValue={field ? field._id : ""}
                  disabled={true} />
              </FormGroup>
              <FormGroup key={"form-room_name"}>
                <Label>room_name</Label>
                <Input
                  defaultValue={(field || {}).room_name}
                  onChange={e => this.handleOnChange(e, "room_name")}
                  disabled={true} />
              </FormGroup>

              <FormGroup key={"form-name_alias"}>
                <Label>name_alias</Label>
                <Input
                  defaultValue={(field || {}).name_alias}
                  onChange={e => this.handleOnChange(e, "name_alias")}
                  disabled={true} />
              </FormGroup>

              <FormGroup key={"form-subnet_start"}>
                <Label>subnet_start</Label>
                <Input
                  defaultValue={(field || {}).subnet_start}
                  onChange={e => this.handleOnChange(e, "subnet_start")}
                  disabled={true} />
              </FormGroup>
            </Row>
            <Row className="my-5 text-center">
              <Button type="button" color="primary" onClick={(e) => handlePing(e)}>Ping {isPinging ? <Spinner as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true" /> : ""}</Button>
            </Row>
            <Row className="mr-5">
              <Table bordered>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Meaning</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><FontAwesomeIcon icon={faCircle} color={"#b2ff73"} /></td>
                    <td>Pinged</td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon icon={faCircle} color={"red"} /></td>
                    <td>Can't be Pinged</td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon icon={faCirclRglr} /></td>
                    <td>Not yet Pinged</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faDesktop} color="green" />
                    </td>
                    <td>
                      Available
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faDesktop} color="gray" />
                    </td>
                    <td>
                      Not Available
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faCheckCircle} color="blue" />
                    </td>
                    <td>Selected</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Col>
          <Col xs={4} sm={6} lg={9} className="text-center mb-4" >
            <StageComponent
              stageId={field._id}
              allowEdit={false}
              stageRef={stageRef}
              match={match}
              field={field}
              infoModal={infoModal}
              handleInfoModalDismiss={handleInfoModalDismiss}
              handleClickOnComputer={handleClickOnComputer}

            />
          </Col>
        </Row>
      </Container>
    </div>

  </>
  )
}

export default withRouter(inject("locationStore")(observer(Detail)));