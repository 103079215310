export function checkPermission(acl, shoudlHaveRoles, history, redirectURI) {
    const redirectForbidden = "/forbidden";
    const redirectLogin = redirectURI ? redirectURI : "/exam-online/account/login";
    if (acl && acl.name) {
        let matchRole = shoudlHaveRoles.filter((role) => {
            return role === acl.name;
        })

        if (matchRole.length) {
            return true;
        } else {
            //Logged in but don't have permission
            history.push(redirectForbidden);
        }
    } else {
        //acl is empty, failed to fetch profile 
        history.push(redirectLogin)
    }
}