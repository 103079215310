import React from "react"
import { Col, Container, Row, Spinner } from "reactstrap"

const LoadingScreen = () => {
    return (
        <React.Fragment>
            <Container>
                <Row className="h-100vh align-items-center">
                    <Col className="text-center">
                        <Spinner />
                        <p className="lead">Sedang menghubungi server...</p>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default LoadingScreen