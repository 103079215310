import { faPlus, faFileDownload} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from "react";
import { Button, Col, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import { axios } from '~/apicall';
import { useExamOnlineStore } from '~/components/use-store';
import AnswerSlotItem from './AnswerSlotItem';

import fileDownload from 'js-file-download'
const AnswerSlot = ({ exam }) => {
    const  examOnlineStore  = useExamOnlineStore();
    const [showAddAnswerModal, setShowAddAnswerModal] = useState(false);
    const [dataToDelete, setDataToDelete] = useState(undefined);

    const [slotAdding, setSlotAdding] = useState(false)
    const [inputLeft, setInputLeft] = useState(undefined)
    const [inputRight, setInputRight] = useState(undefined)

    const [showEditAnswerModal, setShowEditAnswerModal] = useState(false);
    const [inputLeftEdit, setInputLeftEdit] = useState(undefined);
    const [inputRightEdit, setInputRightEdit] = useState(undefined);
    const [slotToEdit, setSlotToEdit] = useState(undefined);
    const [isUpdating, setIsUpdating] = useState(false);


    function handleUnsetAddAnswer() {
        setShowAddAnswerModal(false);
        setInputLeft(undefined);
        setInputRight(undefined);
    }

    function handleAddAnswer() {
        setSlotAdding(true);
        if (inputLeft && inputRight) {

            axios.post("manageonline/answerslot", {
                exam: exam._id,
                format: inputLeft + "%xxyyy%" + inputRight
            }).then((resp) => {
                if (resp.status === 200) {
                    Promise.resolve(resp);
                    refreshExamDetail();
                } else {
                    Promise.reject(resp);
                }

            }).catch((err) => {
                alert(err.response.data.error.reason);
            }).finally(() => {
                setSlotAdding(false)
                setShowAddAnswerModal(false);
                setInputLeft(undefined);
                setInputRight(undefined);
            })
        } else {
            setSlotAdding(false)
            alert("Format slot jawaban tidak boleh kosong!");
        }
    }

    function handleOnEditRequest(slot) {
        setSlotToEdit(slot);
        console.log(slot);
        let splitted = slot.format.split("%xxyyy%");
        setInputLeftEdit(splitted[0])
        setInputRightEdit(splitted[1]);
        setShowEditAnswerModal(true);
      
    }

    function handleEditAnswer(){
        if (slotToEdit) {
            //Spin her around
            setIsUpdating(true);

            //Update format by inputs
            slotToEdit.format = inputLeftEdit + "%xxyyy%" + inputRightEdit;
    
            //Answer Slot edit endpoint
            let url = "manageonline/answerslot/" + slotToEdit._id;

            //Removing uneccessary datas
            delete slotToEdit.created_on;
            delete slotToEdit.updated_on;
            delete slotToEdit.deleted_on;
            delete slotToEdit._id;
            delete slotToEdit.submissions;

            //Axios Patronum
            axios.put(url, slotToEdit).catch((err) => {
                alert(err);
            }).finally(() => {
                setIsUpdating(false);
                handleUnsetEditAnswer();
                refreshExamDetail();
            });
        }
    }
    
    function handleUnsetEditAnswer(){
        setSlotToEdit(undefined);
        setInputLeftEdit(undefined);
        setInputRightEdit(undefined);
        setShowEditAnswerModal(false);
    }

    function handleDeleteAnswer() {
        if (dataToDelete) {
            axios.delete("manageonline/answerslot/" + dataToDelete._id)
                .then((resp) => {
                    if (resp.status === 200) {
                        Promise.resolve(resp);
                    } else {
                        Promise.reject(resp);
                    }

                }).catch((err) => {
                    alert(err.response.data.error.reason);
                    // console.log(err.response.data.error.reason);
                }).finally(() => {
                    setDataToDelete(undefined);
                    refreshExamDetail();
                })
        }
    }

    
    function handleAnswerBundleDownload() {
        axios.get("manageonline/examonline/" + exam._id + "/answers", {
            responseType: "blob"
        }).then((resp) => {
            fileDownload(resp.data, resp.headers['x-filename']);
        })
    }

    function refreshExamDetail() {
        examOnlineStore.examFetch(exam._id).then((resp) => {
            examOnlineStore.toggleLoadingScreen(false);
        }).catch((resp) => {
            alert(resp.data.data.error)
        });

    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col>
                        <h3>Slot Jawaban</h3>
                    </Col>
                    <Col>
                        <div className="text-right">
                        <Button className="mr-2" color="success" onClick={handleAnswerBundleDownload}><FontAwesomeIcon icon={faFileDownload} /> Zip Answers</Button>
                            <Button className="mr-2" color="primary" onClick={() => setShowAddAnswerModal(true)} > <FontAwesomeIcon icon={faPlus} /></Button>

                        </div>
                    </Col>
                </Row>
                <Table>
                    <tbody>
                        {exam.online_answer_slot?.length ? (exam.online_answer_slot.map((ans) => {
                            return <AnswerSlotItem key={ans._id + "-" + ans.format} answer={ans} onDeleteRequest={setDataToDelete} onEditRequest={handleOnEditRequest} />
                        })) : <></>}
                    </tbody>
                </Table>

                <Modal isOpen={!!showAddAnswerModal} backdrop>
                    <ModalHeader>Tambah Slot Jawaban Ujian</ModalHeader>
                    <ModalBody>
                        <p>Masukan format jawaban:</p>
                        <InputGroup>
                            <Input type="text" value={inputLeft} onChange={(e) => setInputLeft(e.target.value)} disabled={slotAdding} />
                            <InputGroupAddon addonType="append">xxyyy</InputGroupAddon>
                            <Input type="text" value={inputRight} onChange={(e) => setInputRight(e.target.value)} disabled={slotAdding} />
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleUnsetAddAnswer}>Batalkan</Button>
                        <Button color="primary" onClick={handleAddAnswer}>Tambahkan {slotAdding ? <Spinner as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /> : ""}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={!!showEditAnswerModal} backdrop>
                    <ModalHeader>Ubah Format Jawaban Ujian</ModalHeader>
                    <ModalBody>
                        <p>Masukan format jawaban:</p>
                        <InputGroup>
                            <Input type="text" value={inputLeftEdit} onChange={(e) => setInputLeftEdit(e.target.value)} disabled={isUpdating} />
                            <InputGroupAddon addonType="append">xxyyy</InputGroupAddon>
                            <Input type="text" value={inputRightEdit} onChange={(e) => setInputRightEdit(e.target.value)} disabled={isUpdating} />
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleUnsetEditAnswer}>Batalkan</Button>
                        <Button color="primary" onClick={handleEditAnswer}>Ubah {isUpdating ? <Spinner as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /> : ""}</Button>
                    </ModalFooter>
                </Modal>
           
                <Modal isOpen={!!dataToDelete} backdrop>
                    <ModalHeader>Konfirmasi penghapusan</ModalHeader>
                    <ModalBody>
                        Apakah anda yakin ingin menghapus slot jawaban <code>{(dataToDelete || {}).format}</code>?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setDataToDelete(undefined)}>Batalkan</Button>
                        <Button color="danger" onClick={handleDeleteAnswer}>Hapus</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default AnswerSlot