import { faSadTear } from "@fortawesome/free-regular-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Badge, Col, Container, Row } from "reactstrap";

const Forbidden = () => {
    return (
        <React.Fragment>
            <Container className="vh-100 text-center">
                <Row className="my-4">
                    <Link to="/exam-online" className="row" style={{ textDecoration: "none" }} title="Pulang yok, pulang!">
                        <FontAwesomeIcon icon={faChevronLeft} size="1x" className=" mt-1 mx-1 text-muted" />
                        <span className="text-info"> Mulak ma hita (Back home)</span>
                    </Link>
                </Row>
                <Row className="w-100 h-100 align-items-center">
                    <Col className="w-100 ">
                        <Row className="w-100 ">
                            <Col>
                                <Badge color="danger" className=""><h1 className="display-1">404</h1></Badge>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faSadTear} size="4x" className="mt-5 p-1 text-muted" />
                            </Col>
                        </Row>
                        <Row className="w-100 text-center mt-5 p-0">
                            <Col>
                                <h2>
                                    <Badge color="danger"> NOT FOUND! </Badge>
                                    The page you are searching for can not be found!
                                </h2>
                                <Link
                                    to={{ pathname: "https://www.youtube.com/watch?v=-23UKjwu_10" }}
                                    title={"going 'round and 'round my head"}
                                    style={{ textDecoration: "none" }}
                                    target="_blank">
                                    <h5><strong>("where are you now?")</strong></h5>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Forbidden