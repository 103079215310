
import React from "react";
import { Group, Rect, RegularPolygon, Text } from "react-konva";

const InfoTooltip = (props) => {
    const [data, setData] = React.useState({ ...(props.data || {}) });

    const DEFAULT_RECT_TOOLTIP_WIDTH = 140;
    const DEFAULT_RECT_TOOLTIP_HEIGHT = 80;
    return (
        <Group
            ref={props.toolTipRef}
            x={0}
            y={0}
            listening={false}>
            <RegularPolygon
                id="tooltip-triangle"
                sides={3}
                radius={10}
                fill={"#31a2f7"}
                stroke={"#31a2f7"}
                strokeWidth={1}
                rotation={30}
                width={30}
                x={-1 * DEFAULT_RECT_TOOLTIP_WIDTH}
                y={-1 * DEFAULT_RECT_TOOLTIP_HEIGHT}
                listening={false}
            />

            <Rect id="tooltip-rect"
                fill={"white"}
                stroke={"#31a2f7"}
                strokeWidth={4}
                cornerRadius={10}
                width={DEFAULT_RECT_TOOLTIP_WIDTH}
                height={DEFAULT_RECT_TOOLTIP_HEIGHT}
                x={-1 * DEFAULT_RECT_TOOLTIP_WIDTH}
                y={-1 * DEFAULT_RECT_TOOLTIP_HEIGHT}
                listening={false}
            />
            <Text id="tooltip-text-occ"
                text={"Ocuppied by:"}
                fill={"blue"}
                fontStyle={"bold"}
                x={-1 * DEFAULT_RECT_TOOLTIP_WIDTH}
                y={-1 * DEFAULT_RECT_TOOLTIP_HEIGHT}
                listening={false}
            />
            <Text
                id="tooltip-text-npm"
                fontStyle={"bold"}
                fontSize={14}
                x={-1 * DEFAULT_RECT_TOOLTIP_WIDTH}
                y={-1 * DEFAULT_RECT_TOOLTIP_HEIGHT}
                listening={false} />

            <Text
                id="tooltip-text-name"
                wrap={"word"}
                width={120}
                ontStyle={"bold"}
                x={-1 * DEFAULT_RECT_TOOLTIP_WIDTH}
                y={-1 * DEFAULT_RECT_TOOLTIP_HEIGHT}
                listening={false} />
        </Group>
    )
}

export default InfoTooltip;