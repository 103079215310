import React from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, Redirect } from "react-router-dom";
import Location from './Location';
import LocationDetail from './computers/detail/Detail';
import LocationCreate from "./create/create";
import LocationEdit from "./detail/Detail";
import ComputersEdit from "./computers/edit/Edit";
import AdminNavbar from '~/components/admin/navbar/AdminNavbar';

function Index({ match }) {
  return (
    <>
      <AdminNavbar />
      <Switch>
        <Route exact path={match.path} component={Location} />
        <Route path={match.path + "/new"} component={LocationCreate} />
        <Route path={match.path + "/:id/edit"} component={LocationEdit} />
        <Route path={match.path + "/:id/computers"} component={LocationDetail} />
        <Route path={match.path + "/:id/edit-computers"} component={ComputersEdit} />
        <Redirect push to={match.path + "/"} />
      </Switch>
    </>
  )
}

export default observer(Index);