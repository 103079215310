import { faCloudDownloadAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileDownload from 'js-file-download';
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { Else, If, Then } from 'react-if';
import { createUseStyles } from 'react-jss';
import { Button, Modal, ModalBody, ModalFooter, Progress, UncontrolledTooltip } from "reactstrap";
import { axios } from '~/apicall';

const useStyles = createUseStyles(({
    root: {
        cursor: "pointer"
    },
    submissionArea: {
        border: "1px",
        borderStyle: "dashed",
        paddingRight: "10px",
        margin: "0",
        "&:hover": {
            background: "rgba(23,162,184,0.5)"
        }
    }
}))

const reaction = [
    "(~‾▿‾)~",
    "(ง •̀_•́)ง",
    "ฅ^•ﻌ•^ฅ #cats",
    "ʕ•ᴥ•ʔ #bears",
    "༼ つ ◕_◕ ༽つ gib",
    "(๑ↀᆺↀ๑)✧ #cats",
    "OwO",
    "(づ｡◕‿‿◕｡)づ"
]

let emotnya = reaction[Math.floor(Math.random() * reaction.length)];


const AnswerSlot = (props) => {
    const [uploading, setUploading] = useState(0);
    const [progress, setProgress] = useState(false);
    const [submission, setSubmission] = useState(undefined);
    const [fetchingSubmission, setFetchingSubmission] = useState(true)

    const [capturedMismatchFile, setCapturedMismatchFile] = useState(null)

    const { setErrorModal, answer_slot } = props;


    function handleDownload(answer) {
        const answerID = answer._id;
        const url = "/api/v1/examonline/submission/submit";

        if (answerID) {
            let urlParam = new URLSearchParams({
                answer_slot: answerID,
                force_download: true
            });

            fetch(url + "?" + urlParam, {
                method: "GET",
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('auth-token')
                }
            }).then(resp => resp.blob()).then((resp) => {
                fileDownload(resp, answer.format);
            }).catch((err) => {
                console.log(err);
                setErrorModal(null, err);
            })
        }

    }
    // Real upload callback.
    const triggerUpload = useCallback((acceptedFile) => {
        setProgress(true);
        let data = new FormData()
        data.append('file', acceptedFile, answer_slot.format)
        data.append('answer_slot', answer_slot._id)
        let size = 0;
        for (var pair of data.entries()) {
            if (pair[1] instanceof Blob) {
                size += pair[1].size;
            }
            else {
                size += pair[1].length;
            }

        }
        if (size > (8 * 1024 * 1024)) {
            setErrorModal(null, <>
                <h5 className='bg-danger text-white'>UKURAN BERKAS MELEBIHI BATAS</h5>
                <span>Ukuran berkas yang diunggah melebih batas. Ukuran berkas {size} bytes, sedangkan batas unggah adalah {8 * 1024 * 1024} bytes! </span>
            </>);
            return;
        }

        axios.post("examonline/submission/submit", data, {
            onUploadProgress: (progressEvent) => {
                setUploading(progressEvent.loaded / progressEvent.total);
            }
        }).then((resp) => {
            setProgress(false);
            setSubmission(resp.data.data);
            // TODO: Pesan sukses
            setErrorModal(null, <>
                <h5 className='bg-success text-white'>UPLOAD SUCCESS</h5>
                <p>Berhasil mengunggah jawaban dengan format: {answer_slot.format}</p>
            </>)
        }).catch((resp) => {
            setProgress(false);
            if (resp.response.data && resp.response.data.error) {
                setErrorModal(<>
                    <h5 className='bg-success'>{resp.response.data.error.title} [{resp.response.data.error.error_code}]</h5>
                    <span>{resp.response.data}</span>
                </>)
            } else {
                setErrorModal(null, <>
                    <h5 className='bg-danger text-white'>UPLOAD FAILED - ERROR</h5>
                    <span>{resp.response.data}</span>
                    <p><small>Because it's a browser(/network) related error, the error has been emitted to the console.</small></p>
                </>)
            }
        })
    }, [answer_slot, setErrorModal]);

    // drop handler
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles[0].name !== answer_slot.format) {
            setCapturedMismatchFile(acceptedFiles[0]);
        } else {
            triggerUpload(acceptedFiles[0]);
        }
    }, [answer_slot, triggerUpload])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    const styles = useStyles();

    // hooks for fetching submission
    useEffect(() => {
        setFetchingSubmission(true);
        axios.get("examonline/submission/submit", { params: { answer_slot: answer_slot._id } }).then(data => {
            setFetchingSubmission(false);
            setSubmission(data.data.data);
        }).catch((err) => {
            setFetchingSubmission(false);
            setSubmission(null);
            console.error("ERROR HAPPENED:", err);

        })
        return () => { };
    }, [answer_slot])


    return (
        <React.Fragment>
            <tr>
                <td className={"align-middle " + styles.root} sm={2} md={3} lg={3}>
                    <Button
                        color={!submission ? "secondary" : "success"}
                        id={"download-button-" + answer_slot._id}
                        onClick={(e) => handleDownload(answer_slot)}
                        disabled={fetchingSubmission || !submission}
                    >
                        <FontAwesomeIcon icon={fetchingSubmission ? faSync : faCloudDownloadAlt} />
                    </Button>
                    <UncontrolledTooltip placement="bottom" target={"download-button-" + answer_slot._id}>Unduh Berkas</UncontrolledTooltip>

                </td>
                <td className="align-middle" sm={2} md={3} lg={3}>
                    <p className={"font-monospace font-weight-bold m-0" + ((!fetchingSubmission && !submission) ? " text-danger" : "")}>{answer_slot.format}</p>
                </td>
                <If condition={progress}>
                    <Then>
                        <td className="align-middle">
                            <Progress animated={progress} value={uploading} max={1}>{(uploading * 100 + " %")}</Progress>
                        </td>
                    </Then>
                    <Else>
                        <td {...getRootProps({ className: (styles.root + " text-right align-middle") })}>
                            <input {...getInputProps({ multiple: false })} />
                            <If condition={isDragActive}>
                                <Then>
                                    <span className="text-primary font-weight-bold"><FontAwesomeIcon icon="cloud-upload-alt" /> {emotnya}</span>
                                </Then>
                                <Else>
                                    <div className={styles.submissionArea}>
                                        <FontAwesomeIcon icon="cloud-upload-alt" /> Upload dengan <b>klik di sini</b> atau<br />drag berkasnya ke sini
                                    </div>
                                </Else>
                            </If>
                        </td>
                    </Else>
                </If>
                <Modal isOpen={!!capturedMismatchFile} backdrop>
                    <ModalBody>
                        <p>Anda mengupload file dengan format penamaan yang berbeda.</p>
                        <p>Anda mengupload <code>{(capturedMismatchFile || {}).name}</code>, sedangkan file yang diinginkan adalah <code>{answer_slot.format}</code></p>
                        <p>
                            Apakah anda ingin tetap menguploadnya (namanya bakal digantiin sama kita kok), atau batalkan upload?
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => {
                            triggerUpload(capturedMismatchFile);
                            setCapturedMismatchFile(null)
                        }}>Ganti Nama dan Upload</Button>
                        <Button color="warning" onClick={() => setCapturedMismatchFile(null)}>Batalkan</Button>
                    </ModalFooter>
                </Modal>
            </tr>
        </React.Fragment >
    )
}

export default AnswerSlot