import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from "react";
import { When } from 'react-if';
import { Link, withRouter } from 'react-router-dom';
import { axios } from '~/apicall';

import { Button, ButtonGroup, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledDropdown } from "reactstrap";
import moment from 'moment';
import DateTimePicker from '~/components/date-time-picker/date-time-picker';

const Edit = (props) => {
    const { match } = props;
    const { examOnlineStore } = props;

    const returnPath = "/admin/exam-online/exam/" + match.params.id + "/detail";

    const [field, setField] = useState({});

    const [collaborators, setCollaborators] = useState([]);
    const [lecturers, setLecturers] = useState([]);
    const [lectures, setLectures] = useState([]);
    const [mataKuliah, setMataKuliah] = useState(-1);
    const [tipeUjian, setTipeUjian] = useState(undefined);
    const [timeStart, setTimeStart] = useState(moment())
    const [timeDuration, setTimeDuration] = useState(undefined);

    const [lecturePeriods, setLecturePeriods] = useState([]);
    const [periodeUjian, setPeriodeUjian] = useState(-1);

    const [isSaving, setIsSaving] = useState(false);
    const [promptDelete, setPromptDelete] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [dataToSave, setDataToSave] = useState({})



    //Fetching exam data by id in url paramater
    useEffect(() => {
        examOnlineStore.toggleLoadingScreen(true);
        async function getExamDetail() {
            await examOnlineStore.examFetch(match.params.id).then((resp) => {
                setField({ ...examOnlineStore.exam });
                setCollaborators([...(examOnlineStore.exam.lecturers || [])])
                setMataKuliah(examOnlineStore.exam.lecture._id)
                setPeriodeUjian(examOnlineStore.exam.lecture_period._id)
                examOnlineStore.toggleLoadingScreen();
                setTipeUjian(examOnlineStore.exam.uts);
                setTimeStart(moment(examOnlineStore.exam.time_start));
                setTimeDuration(examOnlineStore.exam.time_duration)
                console.log("Exam duration :", examOnlineStore.exam);
            });
        }

        getExamDetail();

    }, [examOnlineStore, match.params.id]);

    //Get lecturers info
    useEffect(() => {
        axios.get("manageonline/lecturer").then(result => {
            let lecturerList = [...result.data.data]
            setLecturers([...lecturerList]);
        })
        return () => { };
    }, [])


    //Get lectures info
    useEffect(() => {
        axios.get("manage/lecture").then(data => {
            setLectures(data.data.data);
        })
        return () => { };
    }, [])

    //Get lecture period info
    useEffect(() => {
        axios.get("manage/lectureperiod").then(data => {
            // console.log(data.data)
            setLecturePeriods(data.data.data);
        })
        return () => { };
    }, [])


    // //Refresh the page if data already fetched
    // useEffect(() => {
    //     setField({ ...examOnlineStore.exam });
    //     setCollaborators([...examOnlineStore.exam.lecturers])
    //     setMataKuliah(examOnlineStore.exam.lecture._id)
    //     console.log()
    // }, [examOnlineStore.exam, setField, setCollaborators])


    function handleSelectCollaborator(e) {
        let target = e.target.value;
        let newCollaborators = []

        let isSelected = collaborators.filter((data) => {
            return data.uuid === target;
        })

        if (isSelected.length) {
            //current target is an already selected lecturer, so we removed it from collaborators list
            newCollaborators = collaborators.filter((data) => {
                return data.uuid !== target;
            });
        } else {
            //Get current target's information
            let objTarget = lecturers.filter(data => data.uuid === target)
            let tempArr = [...collaborators, ...objTarget];
            //Filter it out, so there wont be any duplicate
            newCollaborators = tempArr.filter((data, index) => {
                return tempArr.indexOf(data) === index
            });
        }
        setCollaborators(newCollaborators);
    }

    function handleUnselectCollaborator(e) {
        let target = e.target.id;
        let ids = target.split("selected-");
        let newArr = collaborators.filter(data => data.uuid !== ids[1]);
        setCollaborators([...newArr]);
    }

    function handleSaveExam(e) {
        e.preventDefault();
        let endpoint = "manageonline/examonline/" + match.params.id;
        let indexPath = "/admin/exam-online/exam/" + match.params.id + "/detail";

        let newData = {};

        if (collaborators.length) {
            newData.lecturers = collaborators.map((dosen) => {
                return dosen.uuid
            });
        }

        if (field.time_start !== timeStart.format("YYYY-MM-DD HH:mm:ss")) {
            newData.time_start = timeStart.format("YYYY-MM-DD HH:mm:ss");
            newData.time_opened = null;
            newData.time_ended = null;
        } else {
            newData.time_start = field.time_start;
        }

        newData.lecture = mataKuliah;
        newData.lecture_period = periodeUjian;
        newData.time_duration = timeDuration;
        newData.uts = tipeUjian;
        newData.online = field.online;

        console.log(newData);

        setIsSaving(true)

        axios.put(endpoint, newData).then((resp) => {
            if (resp.status === 200) {
                console.log("Exam online data updated!");
                setIsSaving(false)
                window.location = indexPath;
                return Promise.resolve(resp);
            }
        }).catch((err) => {
            console.log(err)
        })


    }

    function handleDeleteRequest(e) {
        let endpoint = "manageonline/examonline/" + match.params.id;
        let indexPath = "/admin/exam-online";
        setIsDeleting(true)
        axios.delete(endpoint).then((resp) => {
            if (resp.status === 200) {
                console.log("Exam online deleted!");
                setIsDeleting(false)
                window.location = indexPath;
            }
        }).catch((err) => {
            console.log(err)
        })

    }

    function handleOnChange(e) {
        let target = e.target;
        let id = target.id
        let fieldName = id.split("form-input-")[1];

        setDataToSave((prev) => {
            prev[fieldName] = target.value;
            return prev
        })

        if (fieldName === "lecture_period") {
            setPeriodeUjian((prev) => parseInt(target.value))
        }
        if (fieldName === "lecture") {
            setMataKuliah((prev) => parseInt(target.value))
        }
        if (fieldName === "uts") {
            setTipeUjian((prev) => parseInt(target.value))
        }
        if (fieldName === "time_duration") {
            setTimeDuration((prev) => (parseInt(target.value) * 60))
        }

        console.log("Data to Save", dataToSave);

    }

    return (
        <React.Fragment>
            <div className="my-5">
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <h2 className="text-monospace">
                                <Link to={returnPath} title="Back" className="mr-3"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                <span className="text-muted">Manage\</span>ExamOnline::<span className="text-info">edit</span>({match.params.id})
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <Form>
                                <FormGroup key={"form-id"}>
                                    <Label>id</Label>
                                    <Input
                                        id={"form-input-id"}
                                        defaultValue={field ? field._id : ""}
                                        disabled={true} />
                                </FormGroup>
                                <FormGroup key={"form-lecture"}>
                                    <Label>Lecture</Label>
                                    <Input type="select" id={"form-input-lecture"} name="mata_kuliah" disabled={!lectures} value={mataKuliah} onChange={e => setMataKuliah(e.target.value)}>
                                        <option value={-1} disabled>-- {lectures.length > 0 ? "Pilih Mata Kuliah" : "Memuat..."} --</option>
                                        {lectures.map(data => <option value={data._id} key={data._id}>{data.lecture_code} - {data.name}</option>)}
                                    </Input>
                                </FormGroup>
                                <FormGroup key={"form-lecture_period"}>
                                    <Label>Lecture Period</Label>
                                    <Input
                                        id={"form-input-lecture_period"}
                                        type="select" name="lecture_period" disabled={!lecturePeriods.length} value={periodeUjian}
                                        onChange={handleOnChange}
                                    >
                                        <option value={-1} disabled>-- {lectures.length > 0 ? "Pilih Periode Ujian" : "Memuat..."} --</option>
                                        {
                                            lecturePeriods.map((data) => {
                                                return <option value={data._id} key={data._id}> {data.period_code}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                                <FormGroup key={"form-lecturers"}>
                                    <Row className='ml-1'>
                                        <Label>Dosen Kolaborator</Label>
                                    </Row>
                                    <Row md={8} className="ml-1 mb-1">
                                        <ButtonGroup vertical >
                                            <UncontrolledDropdown md={8} >
                                                <div className='w-100 bg-white border rounded-lg'>
                                                    <DropdownToggle caret className="w-100" color={"none"}> {lecturers.length > 0 ? "Tambahkan dosen pengawas lainnya" : "                     Memuat...                  "}</DropdownToggle>
                                                </div>
                                                <DropdownMenu>
                                                    {
                                                        lecturers.map((data) =>
                                                            <DropdownItem id={data.uuid + ""} key={data.uuid + ""} value={data.uuid + ""} color={data.is_selected ? "primary" : "none"} onClick={(e) => handleSelectCollaborator(e)} >{data.display_name}</DropdownItem>
                                                        )
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>

                                        </ButtonGroup>
                                        <div className='w-auto'>
                                            {collaborators.length > 0 ? collaborators.map(data => {
                                                return (
                                                    <Button id={"selected-" + data.uuid}
                                                        key={"selected-" + data.uuid}
                                                        className="ml-1 mt-1"
                                                        color="primary"
                                                        onClick={(e) => handleUnselectCollaborator(e)}>
                                                        {data.display_name}
                                                    </Button>
                                                )
                                            }) : ""}
                                        </div>
                                    </Row>
                                </FormGroup>
                                <FormGroup key={"form-time_start"}>
                                    <Label>Exam Start</Label>
                                    <DateTimePicker time={timeStart} onChange={setTimeStart} />
                                </FormGroup>
                                <FormGroup key={"form-time_duration"}>
                                    <Label>Duration (Minutes)</Label>
                                    <Input
                                        id={"form-input-time_duration"}
                                        defaultValue={timeDuration ? (Math.floor(timeDuration / 60)) : ""}
                                        onChange={handleOnChange}
                                    />
                                </FormGroup>
                                {/* <FormGroup key={"form-time_opened"}>
                                    <Label>Timer Open</Label>
                                    <Input
                                        id={"form-input-time_opened"}
                                        defaultValue={field ? field.time_opened : ""}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup key={"form-time_ended"}>
                                    <Label>Timer Ended</Label>
                                    <Input
                                        id={"form-input-time_ended"}
                                        defaultValue={field ? field.time_ended : ""}
                                        disabled={true}
                                    />
                                </FormGroup> */}
                                <FormGroup key={"form-online"}>
                                    <Label>Online</Label>
                                    <Input
                                        id={"form-input-online"}
                                        defaultValue={field ? field.online : ""}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup key={"form-uts"}>
                                    <Label>UTS/UAS/QUIZ</Label>
                                    <Input
                                        id={"form-input-uts"}
                                        defaultValue={field ? field.uts : ""}
                                        onChange={handleOnChange}
                                        type="select" name="tipe_ujian" disabled={field.uts === undefined} value={tipeUjian}
                                    >
                                        <option value={-1} disabled>-- Pilih Tipe Ujian --</option>
                                        {
                                            [0, 1, 2].map((val) => {
                                                return <option key={"tipe-ujian-" + val} value={val} >{val === 0 ? "UAS" : (val === 1 ? "UTS" : "QUIZ")}</option>
                                            })
                                        }
                                    </Input>

                                </FormGroup>


                                <FormGroup className="pt-4">
                                    <Button type="submit" color="primary" size="lg" onClick={(e) => handleSaveExam(e)}>Save {isSaving ? <Spinner as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : ""

                                    }</Button>
                                    <When condition={match.params.id !== 0}>
                                        <Button type="button" color="danger" onClick={(e) => setPromptDelete(true)} size="lg" className="ml-4">Delete </Button>
                                    </When>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Modal isOpen={promptDelete && field && field._id !== undefined}>
                        <ModalHeader>
                            Delete Exam {lecturePeriods[field.lecture?.name]}
                        </ModalHeader>
                        <ModalBody>
                            Are you sure you are going to delete Exam <code>{field.lecture?.name}</code> (<code>{field.lecture?.lecture_code}</code>)?
                            {/* <Table>

                                <tbody>
                                    <tr>
                                        <th>time_start</th>
                                        <td>{field.time_start}</td>
                                    </tr>

                                    <tr>
                                        <th>time_duration</th>
                                        <td>{field.time_duration}</td>
                                    </tr>
                                </tbody>
                            </Table> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button type="secondary" onClick={(e) => setPromptDelete(false)}>Cancel</Button>
                            <Button onClick={(e) => handleDeleteRequest(e)} color="danger" >Delete {isDeleting ? <Spinner as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : ""}</Button>
                        </ModalFooter>
                    </Modal>

                </Container>

            </div>
        </React.Fragment>
    )
}

export default inject("examOnlineStore")(
    withRouter(observer(Edit))
);