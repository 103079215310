import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ExamOnlineCreate from "./exam/create/Create";
import Detail from './exam/detail/Detail';
import Screen from './exam/detail/screen/Screen';
import Edit from './exam/edit/Edit';
import Lecturer from './Lecturer';
import LecturerNavbar from './LecturerNavbar';

const LecturerIndex = ({ match }) => {
    return (
        <React.Fragment>
            <LecturerNavbar />
            <Switch>
                <Route exact path={match.path} component={Lecturer} />
                <Route exact path={match.path + "/exam"} component={Lecturer} />
                <Route path={match.path + "/exam/new"} component={ExamOnlineCreate} />
                <Route path={match.path + "/exam/:id/detail"} component={Detail} />
                <Route path={match.path + "/exam/:id/edit"} component={Edit} />
                <Route path={match.path + "/exam/:id/screen"} component={Screen} />
            </Switch>
        </React.Fragment>
    )
}

export default LecturerIndex; 