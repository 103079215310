import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CardBody, Col, Container, Row } from "reactstrap";


const displayFaces = [
  'meh-rolling-eyes',
  'surprise',
  'meh',
  'grin-tears',
  'dizzy',
  'lemon'
]

let emotnya = Math.floor(Math.random() * displayFaces.length);
const NoExam = () => {
  return (
    <React.Fragment>
      <Container className="text-center">
        <Row className=" h-100vh  align-items-center">
          <Col className="">
            <CardBody>
              <div className="p-4">
                <FontAwesomeIcon icon={['far', displayFaces[emotnya]]} size="3x" className="mb-5 text-muted" />
                <h3>Tidak ada ujian yang sedang berjalan.</h3>
                <p>Mohon kontak administrator, jika anda yakin ini sebuah kesalahan.</p>
              </div>
            </CardBody>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  )
}
NoExam.propTypes = {
}

export default NoExam;