import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';

import { Component } from 'react';
import { If, Then, When } from 'react-if';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';

class LocationEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            field: [],
            message: [],
            promptDelete: false,
            isSaving: false,
            isDeleting: false,
            shouldRedirect: false,
            returnPath: '/admin/location'
        }
    }

    componentDidMount() {
        const { locationStore } = this.props;
        let { match } = this.props;

        if (match.params?.id) {
            locationStore.locationFetch(match.params.id).then(() => {
                let finalizedFields = { ...locationStore.getSelectedLocation[match.params.id] };
                this.setState({ field: finalizedFields })
            }).catch((err) => {
                this.setState({ message: err })
            });
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;
        if (prevProps.match.params.id !== match.params.id) {
            this.setState({ field: {} });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { match, locationStore } = this.props;
        const { id } = match.params;
        let { field } = this.state;
        let url = "/manage/location";
        if (field) {
            this.setState({ isSaving: true });
            delete field["computers"];
            delete field["iplogins"];
            delete field["deleted_on"];
            delete field["created_on"];
            delete field["updated_on"];
            delete field["_id"];
            locationStore.locationUpdate(url, id, { ...field }).then(() => {
                let finalizedFields = { ...locationStore.getSelectedLocation[match.params.id] };
                this.setState({ field: finalizedFields, isSaving: true, shouldRedirect: true })
            });
            return Promise.resolve();
        }

    }

    handleOnChange(e, fieldname) {
        let target = e.target;
        let { field } = this.state;
        this.setState({
            field: { ...field, [fieldname]: target.value }
        });

    }

    handleDeleteRequest(e) {
        const { match, locationStore } = this.props;
        const { id } = match.params;
        let { field } = this.state;
        if (field) {
            this.setState({ isDeleting: true });

            locationStore.locationDelete(id).then(() => {
                let finalizedFields = { ...locationStore.getSelectedLocation[match.params.id] };
                this.setState({ field: finalizedFields, isDeleting: false, shouldRedirect: true, promptDelete: false })
            }).catch((err) => {
                alert(err);
                this.setState({ isDeleting: false, shouldRedirect: false, promptDelete: false })
            });
            return Promise.resolve();
        }
    }

    render() {

        const { field, message, isSaving, isDeleting, returnPath, shouldRedirect, promptDelete } = this.state;
        const { match, locationStore } = this.props;
        console.log(locationStore.locations);

        return <>
            <If condition={shouldRedirect}>
                <Then>
                    <Redirect to={returnPath} />
                </Then>
            </If>
            <div className="my-5">
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <h2 className="text-monospace">
                                <Link to={returnPath} title="Back" className="mr-3"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                <span className="text-muted">Manage\</span>Location::<span className="text-info">edit</span>({match.params.id})
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            {message.map((msg, id) => <Alert key={"msg-" + id} color={msg.color || "primary"} children={msg.data} />)}
                            <Form onSubmit={(e) => this.handleSubmit(e)}>

                                <FormGroup key={"form-id"}>
                                    <Label>id</Label>
                                    <Input
                                        defaultValue={field ? field._id : ""}
                                        disabled={true} />
                                </FormGroup>

                                <FormGroup key={"form-room_name"}>
                                    <Label>room_name</Label>
                                    <Input
                                        defaultValue={(field || {}).room_name}
                                        onChange={e => this.handleOnChange(e, "room_name")} />
                                </FormGroup>

                                <FormGroup key={"form-name_alias"}>
                                    <Label>name_alias</Label>
                                    <Input
                                        defaultValue={(field || {}).name_alias}
                                        onChange={e => this.handleOnChange(e, "name_alias")} />
                                </FormGroup>

                                <FormGroup key={"form-subnet_start"}>
                                    <Label>subnet_start</Label>
                                    <Input
                                        defaultValue={(field || {}).subnet_start}
                                        onChange={e => this.handleOnChange(e, "subnet_start")} />
                                </FormGroup>

                                <FormGroup className="pt-4">
                                    <Button type="submit" color="primary" size="lg">Save {isSaving ? <Spinner as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" /> : ""}</Button>
                                    <When condition={match.params.id !== 0}>
                                        <Button type="button" color="danger" onClick={(e) => this.setState((prev) => { return { ...prev, promptDelete: true } })} size="lg" className="ml-4">Delete </Button>
                                    </When>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Modal isOpen={promptDelete && field && field._id !== undefined}>
                        <ModalHeader>
                            Delete Location {field.room_name}
                        </ModalHeader>
                        <ModalBody>
                            Are you sure you are going to delete location <code>{field.room_name}</code> (<code>{field.name_alias}</code>)?
                        </ModalBody>
                        <ModalFooter>
                            <Button type="secondary" onClick={(e) => this.setState({ promptDelete: false })}>Cancel</Button>
                            <Button onClick={(e) => this.handleDeleteRequest(e)}>Delete {isDeleting ? <Spinner as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true" /> : ""}</Button>
                        </ModalFooter>
                    </Modal>
                </Container></div>
        </>
    }
}

export default withRouter(inject("locationStore")(observer(LocationEdit)));